import React from 'react';
import { ReactComponent as ResourceBlackLogo } from '../assets/images/resource-black-logo.svg';

const SeeHowWeCompare = (props:any) => {
  const {
    handleRedirectPage, mainHeading, treaditionHeading, logoHeading, additionalClasses, additionalLogoClasses,
  } = props;
  return (
    <div className="new-container py-12 grid grid-cols-12 gap-1">
      <div className=" col-span-12
     lg:pl-16
     lg:col-span-4
    "
      >
        <h1 className="font-FahkwangRegular text-l tracking-tight leading-8
      md:text-l md:py-10
      lg:text-xl   "
        >
          {mainHeading}
        </h1>
        <div className="hidden md:block">
          <button
            onClick={() => handleRedirectPage('/production/how-it-works')}
            className="text-center py-3 rounded-full bg-orangeShade
              border-2 border-black px-12 lg:px-20
              text-xl font-FahkwangRegular pt-2 pb-3 shadow-solid-black whitespace-nowrap
              hover:bg-seaGreen"
          >
            Learn More
          </button>
        </div>
      </div>

      <div className="pt-14 md:pt-0 flex flex-row justify-around col-span-12
    md:px-20
    lg:col-span-8
    "
      >
        <div className="flex items-center flex-col justify-center border border-black rounded-full  h-36 w-36 md:h-60 md:w-60 py-10 text-center">
          <p className="font-sans md:text-lg text-sm flex-grow">TRADITIONAL</p>
          <p className={`mt-2 font-FahkwangRegular flex-grow ${additionalClasses}`}>
            {treaditionHeading}
          </p>
        </div>
        <div className="bg-gradient-to-b from-purple-shade to-seaGreen flex flex-col items-center justify-center border rounded-full md:h-60 h-36 w-36 md:w-60 pt-10 text-center">
          <ResourceBlackLogo className="flex-grow w-3/4" />
          <p className={`mt-2 font-FahkwangRegular flex-grow ${additionalLogoClasses}`}>{logoHeading}</p>
        </div>
      </div>

      <div className="col-span-12 md:hidden pt-14 text-center">
        <button
          onClick={() => handleRedirectPage('/production/how-it-works')}
          className="text-center py-3 rounded-full bg-orangeShade
              border-2 border-black px-12 lg:px-20
              text-xl font-FahkwangRegular pt-4 pb-3 shadow-solid-black whitespace-nowrap
              hover:bg-seaGreen"
        >
          Learn More
        </button>
      </div>
    </div>
  );
};

export default SeeHowWeCompare;
