import { ChevronRightIcon } from '@heroicons/react/solid';
import { Link, useHistory } from 'react-router-dom';

type breadcrumbsInput = {
  pages: page[];
};

const Breadcrumbs: React.FC<breadcrumbsInput> = (props) => {
  const history = useHistory();
  const { pages } = props;

  const handlePageRedirect = () => {
    history.go(-1);
  };

  return (
    <nav className="flex  py-5" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-1">
        <li>
          <div>
            <Link to="/home" className="hover:text-gray-500">
              Home
            </Link>
          </div>
        </li>
        {pages.map((page, index) => {
          const { name, href } = page;
          return (
            <li key={name}>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                {pages.length > 1 && index === 0 ? (
                  <button
                    type="button"
                    onClick={handlePageRedirect}
                    className="ml-1 text-sm hover:text-gray-700 truncate max-w-150px"
                  >
                    {name}
                  </button>
                )
                  : (
                    <Link
                      to={href}
                      className="ml-1 text-sm hover:text-gray-700 truncate max-w-150px"
                    >
                      {name}
                    </Link>
                  )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
