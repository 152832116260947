import React from 'react';
import { useHistory } from 'react-router';

const Thankyou: React.FC = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push('/home');
  };

  return (
    <div className='-mt-8 h-screen flex justify-center items-center py-5'>
      <div>
        <h2 className="text-4xl text-center font-serif font-semibold">
          Your order has been successfully placed
        </h2>
        <div className='text-center'>
          <button
            onClick={handleRedirect}
            type="button"
            className="mt-5 border border-black rounded-full
                           text-xl bg-lemon hover:opacity-80 py-3 px-5 font-serif font-semibold whitespace-nowrap"
          >
            Go To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
