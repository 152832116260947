/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { withRouter } from 'react-router';

import Accordion from 'Components/Accordian';

import HeroImage from 'assets/images/placeholderImages/production-howItWork-crop-top.png';
import FirstTabImage from 'assets/images/placeholderImages/first_tab_howitwork.png';
import SecondTabImage from 'assets/images/placeholderImages/production-secondTab-howItWork.png';
import PrimeTag from 'Components/atoms/PrimeTag';

const ProductionHowItWorks: React.FC = () => {
  const [accordian, setAccordian] = useState<number>(1);

  return (
    <div>

      <section className="flex relative justify-between items-center">
        <img
          src={HeroImage}
          alt="how it work resource fashion"
          className="h-96 lg:h-screen w-screen object-cover "
        />
        <div className="absolute lg:w-3/4 pl-5 lg:pl-20">
          <h1 className="text-white text-4xl lg:text-6xl font-serif">
            Fashion production supply chain service
          </h1>
          <span className="bg-red-400 text-2xl px-1 mt-4">
            How it works
          </span>
        </div>
      </section>

      <section>

        <div onClick={() => setAccordian(1)}>
          <Accordion
            headingClass="py-5 px-5 font-semibold border-2 border-black"
            heading="Let's connect"
            h1Class="text-2xl text-center mx-auto"
            preOpen={accordian === 1}
          >
            <div className="grid grid-cols-12 bg-seaGreen border-black border-b-2 border-l-2 border-r-2">
              <div className="col-span-12 md:col-span-6 lg:col-span-6">
                <img src={FirstTabImage} alt="Resourece Manufacturers 101" />
              </div>
              <div className="p-10 md:text-2xl font-medium font-serif col-span-12 md:col-span-6 lg:col-span-6">
                <p>
                  The best way to get to know each other is through a conversation. We want to hear
                  about not only your business needs, but also your hopes, dreams, and vision.
                  The founders and team behind RE(SOURCE) are inspired by our customers’ entrepreneurship
                  every day. We’re going to partner in the next few years to launch your products and
                  improve your productivity. Let’s talk and brainstorm, so you can give us a clear picture
                  of how we can help you.
                </p>
              </div>
            </div>
          </Accordion>
        </div>

        <div onClick={() => setAccordian(2)}>
          <Accordion
            headingClass="py-5 px-5 font-semibold border-l-2 border-r-2 border-black"
            heading="Get all the tools"
            h1Class="text-2xl text-center mx-auto"
            preOpen={accordian === 2}
          >
            <div className="grid grid-cols-12 bg-seaGreen border-black border-t-2 border-l-2 border-r-2">
              <div className="col-span-12 md:col-span-6 lg:col-span-6 lg:order-2">
                <img src={SecondTabImage} alt="ORDER REVIEWS" />
              </div>
              <div className="p-10 md:text-2xl font-large font-serif col-span-12 md:col-span-6 lg:col-span-6 lg:order-1">
                <p>
                  We are embracing technology and innovation every step of the way, and it all starts
                  with access to your personal dashboard. From here, you can request new samples, track
                  progress, get status updates and schedule calls with us anytime. You will have access
                  to all files and conversations related to any product we worked together. We will work
                  with you on your first sample requests and onboard you to the system personally.
                </p>
              </div>
            </div>
          </Accordion>
        </div>

        <div onClick={() => setAccordian(3)}>
          <Accordion
            headingClass="bg-sand py-5 px-5 font-semibold border-2 border-black"
            heading="Start from Sampling"
            h1Class="text-2xl text-center mx-auto"
            preOpen={accordian === 3}
          >

            <div className="grid grid-cols-12 bg-seaGreen border-black border-b-2 border-l-2 border-r-2">
              <div className="col-span-12 md:col-span-6 lg:col-span-6">
                <img src={FirstTabImage} alt="Resourece Manufacturers 101" />
              </div>
              <div className="p-10 md:text-2xl font-medium font-serif col-span-12 md:col-span-6 lg:col-span-6">
                <p>
                  We will work with three styles for the first round. This gives you time to discover
                  more about how we work, understand the process, and learn about all the tools you have
                  available. Oftentimes users receive the samples and make comments to adjust the fabric
                  or design to get to the final prototype. You will receive the bulk order quotes after
                  samples are completed. Once our first sampling project is completed, you’ll have complete
                  access to create as many styles as you like.
                </p>
              </div>
            </div>

          </Accordion>
        </div>

        <div onClick={() => setAccordian(4)}>
          <Accordion
            headingClass="py-5 px-5 font-semibold border-l-2 border-r-2 border-black"
            heading="Bulk Production"
            h1Class="text-2xl text-center mx-auto"
            preOpen={accordian === 4}
          >
            <div className="grid grid-cols-12 bg-seaGreen border-black border-t-2 border-l-2 border-r-2">
              <div className="col-span-12 md:col-span-6 lg:col-span-6 lg:order-2">
                <img src={SecondTabImage} alt="ORDER REVIEWS" />
              </div>
              <div className="p-10 md:text-2xl font-medium font-serif col-span-12 md:col-span-6 lg:col-span-6 lg:order-1">
                <p>
                  Once you have tested your ideas and are ready to go big, we’re ready to take your product to
                  full production. Place bulk orders with your ideal production quantity through your dashboard
                  and get an accurate completion date. Our team takes deadlines very seriously. We work hard to
                  ensure that your orders always get the priority attention they deserve, so you can be sure your
                  order will be ready when you are.
                </p>
              </div>
            </div>
          </Accordion>
        </div>

      </section>
    </div>
  );
};

export default withRouter(ProductionHowItWorks);
