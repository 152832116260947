type h2Props = {
    text: string,
    additionalClasses?: string,
  };

const H2Heading:React.FC<h2Props> = ({ text, additionalClasses }) => (
  <h2 className={`circularLongButbg-black text-lemon text-4xl pt-5 pb-4 text-center font-serif ${additionalClasses}`}>
    {text}
  </h2>
);

export default H2Heading;
