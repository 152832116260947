import { manufacturerDefaultImg } from 'Constants';
import { useEffect, useState } from 'react';
import ProductQuantity from 'Components/ProductQuantity';
import { calculateProductPrice } from 'Util/Utility';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import ProductSizePrice from './ProductSizePrice';

type ProductSizePriceProps = {
  productName: string,
  manufacturerName: string,
  color: string,
  image?: string
  orderItems?: sku[]
  moq?: number;
  id: string;
  prices?: bulkPrice[];
  individualProductWeight?: number;
  handleUpdateCart?: (value: sku[], productId: string, amount: number, totalProductWeight: number) => void;
  handleDeleteCart?: (index: number) => void;
  cartItemIndex: number;
  subtotal?:number
  bigImage?:boolean;
}

const CheckoutProductCard: React.FC<ProductSizePriceProps> = (props) => {
  const {
    productName, manufacturerName, color, image,
    subtotal,
    orderItems,
    moq,
    id,
    prices,
    individualProductWeight,
    handleUpdateCart,
    handleDeleteCart,
    cartItemIndex,
    bigImage,
  } = props;
  const [edit, setEdit] = useState(false);
  const [productSku, setProductSku] = useState<sku[] | undefined>(orderItems);
  const [totalProductQuantity, setTotalProductQuantity] = useState<number>(0);

  const onChangeProductQuantity = (selectedSku: sku, quantity: number) => {
    const updatedSku = productSku?.map(
      (sku: sku) => (sku.id === selectedSku.id ? { ...sku, quantity } : sku),
    );
    setProductSku(updatedSku);
  };

  const addQuantity = (selectedSku: sku, quantity: number) => {
    const updatedSku = productSku?.map(
      (sku: sku) => (sku.id === selectedSku.id ? { ...sku, quantity: (quantity + 1) } : sku),
    );
    setProductSku(updatedSku);
  };

  const removeQuantity = (selectedSku: sku) => {
    const updatedSku = productSku?.map(
      (sku: sku) => ((sku.id === selectedSku.id && sku.quantity > 0) ? { ...sku, quantity: sku.quantity - 1 } : sku),
    );
    setProductSku(updatedSku);
  };

  useEffect(() => {
    if (productSku?.length) {
      const totalQuantity = productSku?.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
      setTotalProductQuantity(totalQuantity);
    }
  }, [productSku]);

  const handleUpdate = () => {
    const price = prices && calculateProductPrice(prices, totalProductQuantity);
    const amount = price && price * totalProductQuantity;
    const totalProductWeight = individualProductWeight && (individualProductWeight * totalProductQuantity);
    const productSkuWithPrice = productSku?.map((sku: sku) => (sku.quantity ? ({ ...sku, price }) : sku));
    handleEditProduct();
    amount && totalProductWeight && productSkuWithPrice && handleUpdateCart && handleUpdateCart(productSkuWithPrice, id, amount, totalProductWeight);
  };

  const handleDelete = () => {
    handleDeleteCart && cartItemIndex >= 0 && handleDeleteCart(cartItemIndex);
  };

  const handleEditProduct = () => {
    setEdit((prevEdit) => !prevEdit);
  };

  return (
    <div className="lg:flex">
      <div className="flex">
        <img
          src={image || manufacturerDefaultImg}
          className={`w-24 h-24 border-2 object-contain border-black ${bigImage ? 'lg:min-h-300px lg:min-w-300px' : ''}`}
          alt="Cart Product Item"
        />
        {/* Start -- Product Details */}
        <div className="ml-4 my-auto">
          {/* Start -- Product Name */}
          <h4 className={`font-serif font-medium truncate max-w-200 max-w text-${bigImage ? '2xl' : 'base'}`}>
            {productName && productName}
          </h4>

          {/* Start -- Manufacture Name */}
          <p className={`text-${bigImage ? 'base' : 'xs'}`}>
            {manufacturerName && manufacturerName}
          </p>

          {/* Start -- Clothes Color */}
          {
          color
          && (
            <p className={`truncate max-w-200 text-${bigImage ? 'base' : 'xs'}`}>
              Color:
              <span className="font-semibold">
                {color}
              </span>
            </p>
          )
        }

          {/* Start -- Quantity Of Product */}
          <div className={`flex text-${bigImage ? 'base' : 'xs'}`}>
            Qty:
            <div>
              {orderItems?.map((
                item: any,
              ) => (
                item.quantity
                  ? (
                    <div key={item.id}>
                      <ProductSizePrice
                        size={item.size_name ?? 'S'}
                        quantity={item.quantity}
                        price={item.price}
                      />
                    </div>
                  )
                  : null
              ))}

            </div>
          </div>
          {/* Start -- Subtotal */}
          {subtotal
            && (
              <div className="flex justify-between font-serif text-sm mt-4">
                <h4>
                  Subtotal:
                </h4>
                <h4 className="base font-semibold">
                  {`$${subtotal}`}
                </h4>
              </div>
            )}
          {/* Only price coming from the cart page thus it will show only on cart page */}
          {prices && (
            <div className="flex flex-row justify-between">
              <DeleteIcon className="mt-2" onClick={handleDelete} />
              <button
                className="mt-2 mr-10 border-b black"
                type="button"
                onClick={handleEditProduct}
              >
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
      {/* End -- Product Details */}

      {/* Edit Quantity Of products */}
      {edit && (
        <div className="pt-4 lg:pt-0 pb-3 ml-auto">
          {
            productSku?.map((sku: sku, index: number) => (
              <div key={index}>
                <ProductQuantity
                  removeQuantity={removeQuantity}
                  addQuantity={addQuantity}
                  sku={sku}
                  onChangeProductQuantity={onChangeProductQuantity}
                />
              </div>
            ))
          }
          <div className="flex items-center justify-center lg:block pt-5 lg:ml-20">
            <button
              disabled={!(moq && totalProductQuantity >= moq)}
              onClick={handleUpdate}
              type="button"
              className={`${!(moq && totalProductQuantity >= moq) ? 'bg-gray-100' : 'bg-lemon'} text-sm border whitespace-nowrap border-black rounded-full px-5 py-3 font-serif`}
            >
              Update
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default CheckoutProductCard;
