type ProductSizePriceProps = {
  size: string | undefined,
  quantity: number,
  price: number | undefined
}
const ProductSizePrice: React.FC<ProductSizePriceProps> = (props) => {
  const { size, quantity, price } = props;
  return (
    <>
      {` ${size} `}
      <span className="font-semibold">{quantity}</span>
      {' '}
      x
      {' '}
      {price && <span className="font-semibold">{`$${price / 100}`}</span>}
    </>
  );
};

export default ProductSizePrice;
