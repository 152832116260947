import { useEffect, useState } from "react";
import legsimages from "../assets/images/feet.png";
import { caseStudy } from "Constants";
import { useLocation } from "react-router";

const Review = () => {
  const location = useLocation();
  const [reviewId, setReviewId] = useState<string>("");
  useEffect(() => {
    const query = location.search; // result: '?query=abc'
    const getReviewId = query?.split("=")[1];
    window.scrollTo(0, 0);
    return setReviewId(getReviewId);
  }, [location]);
  const i = Number(reviewId);
  return (
    <>
      <div className=" bg-gradient-to-br from-orangeShade via-white to-seaGreen h-40 md:h-96"></div>
      <div className="relative">
        <div className="absolute bg-black h-44  sm:h-52 rounded-tr-large sm:rounded-tr-full w-full sm:w-5/6 -mt-16 text-right">
          {" "}
        </div>
        <div className="absolute w-full text-center top-30percent sm:top-1/4 lg:top-10percent">
          <div className="text-white text-2xl sm:text-2xl md:text-3xl lg:text-5xl font-LuthonSouthard">
            How we leveled up
          </div>
          <div className="text-white text-2xl sm:text-2xl md:text-3xl lg:text-5xl font-LuthonSouthard">
            {`${caseStudy[i].brandName}'s clothing supply chain`}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-10 bg-lightpink pt-48">
        <div className="col-span-10 lg:col-span-4 h-full">
          <div className=" bg-coralgreen w-17/20 m-auto rounded-t-full shadow-3xlblack border-2 border-black">
            <div className="py-3percent">
              <img src={legsimages} className=" mx-auto w-40 rounded-full mt-5 "></img>
              <div className="text-center mt-4 font-FahkwangRegular text-4xl font-semibold">
                {caseStudy[i].brandName}
              </div>
              <div className="text-center ">{caseStudy[i].brandLocation}</div>
              <section className="px-10percent py-3percent ">
                {caseStudy[i].brandDetails[0]}
              </section>
              <section className="px-10percent py-3percent ">
                {caseStudy[i].brandDetails[1]}
              </section>
              <section className="px-10percent py-3percent ">
                {caseStudy[i].brandDetails[2]}
              </section>
            </div>
          </div>
        </div>
        <div className="col-span-10  lg:col-span-6 mx-3percent">
          <div className=" text-4xl font-FahkwangRegular font-semibold mt-12 lg:mt-0">
            The Challenge
          </div>
          {}
          <div className="m-auto justify-start pt-10 ">{caseStudy[i].theChallenges[0]}</div>
          <div className="m-auto justify-start       ">{caseStudy[i].theChallenges[1]}</div>
          <div className="m-auto justify-start pb-10 ">{caseStudy[i].theChallenges[2]}</div>
          <div className="flex flex-col lg:flex-row text-center ">
            <div className="flex-1 bg-lightgreen m-2 p-10percent md:p-3percent  border-2 border-black">
              <div className=" text-lg font-FahkwangRegular h-14 md:h-4 lg:text-xl  lg:h-16">
                {"😰"}
                {caseStudy[i].limitation1[0]}
              </div>
              <div className="text-left pt-3percent lg:pt-10percent text-sm ">
                {caseStudy[i].limitation1[1]}
              </div>
            </div>
            <div className="flex-1 bg-lightgreen m-2 p-10percent md:p-3percent border-2 border-black">
              <div className=" text-lg font-FahkwangRegular h-14 md:h-4 lg:text-xl  lg:h-16">
                {"👀"}
                {caseStudy[i].limitation2[0]}
              </div>
              <div className="text-left pt-3percent lg:pt-10percent text-sm ">
                {caseStudy[i].limitation2[1]}
              </div>
            </div>
            <div className="flex-1 bg-lightgreen m-2 p-10percent md:p-3percent border-2 border-black">
              <div className=" text-lg font-FahkwangRegular h-14 md:h-4 lg:text-xl  lg:h-16">
                {"❌"}
                {caseStudy[i].limitation3[0]}
              </div>
              <div className="text-left pt-3percent lg:pt-10percent text-sm ">
                {caseStudy[i].limitation3[1]}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-10 bg-lightYellow mt-4">
        <div className="col-span-10 lg:col-span-6 m-auto flex justify-end">
          <div className=" w-11/12 text-lg">
            <div className="text-4xl font-FahkwangRegular font-semibold my-5 mx-4">
              The Solution
            </div>
            <section className="my-5 mx-4">{caseStudy[i].theSolution1}</section>
            <section className="my-5 mx-4">{caseStudy[i].theSolution2}</section>
            <section className="my-5 mx-4">{caseStudy[i].theSolution3}</section>
            <section className="my-5 mx-4">{caseStudy[i].theSolution4}</section>
          </div>
        </div>
        <div className="col-span-10 lg:col-span-4">
          <div className="w-3/4 bg-lightred rounded-tl-large font-FahkwangRegular font-semibold rounded-br-large mx-auto border-2 border-black my-10percent shadow-3xlblack">
            <div className="p-10percent">
              <section className="text-xl p-3percent font-FahkwangRegular md:text-2xl">
                {caseStudy[i].quote}
              </section>
              <div className="font-bold md:text-xl font-sans">{caseStudy[i].quoteBy}</div>
              <div className="font-sans">{caseStudy[i].position}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-col md:flex-row text-center bg-lightYellow">
        <div className=" bg-gradient-to-b  from-lightpurple to-lightseagreen m-6 rounded-xl border-2 border-black shadow-3xlblack">
          <div className="my-6 mx-16">
            <div className="text-5xl  lg:text-7xl ">{caseStudy[i].stat1.value}</div>
            <div>{caseStudy[i].stat1.subheading}</div>
          </div>
        </div>
        <div className=" bg-gradient-to-b  from-lightpurple to-lightseagreen m-6 rounded-xl border-2 border-black shadow-3xlblack">
          <div className="my-6 mx-16">
            <div className="text-5xl lg:text-7xl ">{caseStudy[i].stat2.value}</div>
            <div>{caseStudy[i].stat2.subheading}</div>
          </div>
        </div>
        <div className=" bg-gradient-to-b from-lightpurple to-lightseagreen m-6 rounded-xl border-2 border-black shadow-3xlblack">
          <div className="my-6 mx-16">
            <div className="text-5xl lg:text-7xl ">{caseStudy[i].stat3.value}</div>
            <div>{caseStudy[i].stat3.subheading}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-lightYellow py-3percent">
        <a
          href="https://ogjfc9zw5dx.typeform.com/resourcefashion"
          className="bg-lightgreen px-3percent w-64 h-16 rounded-full border-2 border-black shadow-3xlblack-inverted  "
        >
          <div className="flex justify-center items-center h-full text-2xl">Get started</div>
        </a>
      </div>
    </>
  );
};

export default Review;
