/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { getAPIUrl, Loader } from 'API/APIHelpers';
import useGetFetch from 'hooks/useGetFetch';
import { apiRoutes } from 'API/APIRoutes';

import OrderShippingStatus from 'Components/atoms/OrderShippingStatus';
import CheckoutProductCard from 'Components/atoms/CheckoutProductCard';

import Tab from 'Components/Tab';
import Accordion from 'Components/Accordian';
import ReviewRealProductPhotosModal from 'Components/ReviewRealProductPhotosModal';

const OrderHistory: React.FC = () => {
  // Order History Fetch API
  const [OrderData, loading] = useGetFetch(getAPIUrl(apiRoutes.ORDER), undefined, true);

  // To reverse the list of array
  const [reverseList, setReverseList] = useState<any>([]);
  useEffect(() => {
    setReverseList(OrderData.reverse());
  }, [OrderData]);

  // Use State
  const [activeTab, setActiveTab] = useState<number>(0);
  const [filteredData, setFilteredData] = useState<any>(reverseList);

  const [showReviewPhotosModal, setShowReviewPhotosModal] = useState(false);
  const [selectedProductImages, setSelectedProductImages] = useState();

  // To show the count on the tabs
  const orderDataCount = OrderData.filter((order: any) => order.status);
  const orderSubmitted = OrderData.filter((order: any) => order.status === 'Submitted');
  const photoReceived = OrderData.filter((order: any) => order.status === 'Product Photo Received');
  const shipped = OrderData.filter((order: any) => order.status === 'Shipped');
  const transit = OrderData.filter((order: any) => order.status === 'In Transit');
  const delivered = OrderData.filter((order: any) => order.status === 'Delivered');

  // Highlight the selected Tab Function
  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  };
  const selectedTabsFn = (condition: boolean) => (condition ? 'border-b-4 border-black' : ' ');

  // To set the data in seprate tabs on page render
  useEffect(() => {
    switch (activeTab) {
      case 1:
        return setFilteredData(reverseList.filter((order: any) => order.status === 'Submitted'));
      case 2:
        return setFilteredData(reverseList.filter((order: any) => order.status === 'Product Photo Received'));
      case 3:
        return setFilteredData(reverseList.filter((order: any) => order.status === 'Shipped'));
      case 4:
        return setFilteredData(reverseList.filter((order: any) => order.status === 'In Transit'));
      case 5:
        return setFilteredData(reverseList.filter((order: any) => order.status === 'Delivered'));
      default:
        return setFilteredData(reverseList.filter((order: any) => order.status));
    }
  }, [activeTab, reverseList]);

  // Tabs and their count numbe variable
  const showTabs = [
    {
      tabName: 'All',
      numberShow: orderDataCount,
    },
    {
      tabName: 'Order Submitted',
      numberShow: orderSubmitted,
    },
    {
      tabName: 'Photo Received',
      numberShow: photoReceived,
    },
    {
      tabName: 'Shipped',
      numberShow: shipped,
    },
    {
      tabName: 'In Transit',
      numberShow: transit,
    },
    {
      tabName: 'Delivered',
      numberShow: delivered,
    },
  ];

  const handleReviewRealProductModal = (realProductImages: any) => {
    setSelectedProductImages(realProductImages);
    setShowReviewPhotosModal((prevState) => !prevState);
    window.scroll(0, 0);
  };

  return (
    <>
      {loading
        ? <Loader />
        : (
          <div className="md:new-container">
            {showReviewPhotosModal
              && (
                <div className="absolute top-0 left-0 z-50 bg-black bg-opacity-40 pt-40 sm:pt-20 min-h-full min-w-screen        ">
                  <div className="max-w-md mx-auto">
                    <ReviewRealProductPhotosModal
                      handleReviewRealProductModal={handleReviewRealProductModal}
                      showReviewPhotosModal={showReviewPhotosModal}
                      realProductImages={selectedProductImages}
                    />
                  </div>
                </div>
              )}
            <h1 className="text-4xl px-6 lg:px-40 pt-6 font-serif font-semibold lg:pt-10 lg:pb-5">Order History</h1>

            <div className=" lg:px-32">
              <ul className="flex flex-row pl-6 pr-1 pt-3 border-b border-black overflow-scroll lg:justify-around">
                {showTabs.map((tab: any, index: number) => (
                  <div key={index}>
                    <Tab
                      style={selectedTabsFn(activeTab === index)}
                      onTabClick={onTabClick}
                      currentTab={index}
                    >
                      <div className="flex flex-row cursor-pointer whitespace-nowrap">
                        <div className="text-sm font-serif font-semibold ">
                          {tab.tabName}
                        </div>
                        {tab.numberShow.length > 0
                        && (
                          <span className="bg-lemon rounded-full border border-black px-1.5 ml-2 text-sm font-serif font-semibold">
                            {tab.numberShow.length}
                          </span>
                        )}
                      </div>
                    </Tab>
                  </div>
                ))}

              </ul>

              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className=" overflow-hidden border-b border-gray-300 sm:rounded-lg">

                      <div className=" flex justify-around border-b">
                        <div
                          className=" py-3 pr-6 text-right text-xs font-medium text-gray-500 font-serif tracking-wider"
                        >
                          Date
                        </div>
                        <div
                          className=" py-3 pr-18 text-left text-xs font-medium text-gray-500 font-serif tracking-wider"
                        >
                          Total
                        </div>
                        <div
                          className=" py-3 pr-24 text-left text-xs font-medium text-gray-500 font-serif tracking-wider"
                        >
                          Status
                        </div>

                      </div>

                      {filteredData.length > 0 ? (
                        <div className="w-full">
                          <div className="divide-y divide-gray-200">
                            {filteredData.map((history: any, index: number) => (
                              <div key={index}>
                                <Accordion
                                  h1Class="w-full"
                                  heading={(
                                    <div className="flex justify-around w-full">
                                      <div className=" py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {new Date(history.date_time).toLocaleString('en-in', {
                                          month: 'short',
                                          day: 'numeric',
                                          year: 'numeric',
                                        })}
                                      </div>
                                      <div className="py-4 whitespace-nowrap text-sm ">
                                        $
                                        {(history.amount + history.shipping_amount + history.taxes) / 100}
                                      </div>
                                      <div className="px-6 py-4 whitespace-nowrap text-sm">
                                        <span
                                          className={`mt-1 text-3xs border whitespace-nowrap border-black rounded-2xl 
                                      ${(history.status !== 'Delivered') ? 'bg-yellow-300' : 'bg-parrot'} 
                                      px-1.5 py-1`}
                                        >
                                          {history.status ? (history.status === 'Product Photo Received' ? 'Photo Received' : history.status) : 'Submitted'}
                                        </span>

                                      </div>
                                    </div>
                                )}
                                >
                                  <div className="px-6 pt-3 pb-5">
                                    {/* TODO -  */}
                                    <div className="hidden">
                                      <OrderShippingStatus />
                                    </div>
                                    {history.products_data.map((product: any) => (
                                      <div key={product.id} className="py-4 border-b border-black flex flex-row justify-center items-center">
                                        <div className="flex-1">
                                          <div className="flex">
                                            <CheckoutProductCard
                                              id={product.id}
                                              cartItemIndex={index}
                                              image={product?.primary_picture_url}
                                              productName={product.name}
                                              manufacturerName={product.business_name}
                                              orderItems={product.order_items}
                                              color={product?.colour?.name}
                                              bigImage
                                            />
                                            {history.discount_amount ? (
                                              <div className="my-auto mx-auto">
                                                <h5 className="font-serif font-semibold">
                                                  Coupon applied
                                                </h5>

                                                {history.promocode
                                                  ? (
                                                    <h6>
                                                      Promocode :
                                                      {' '}
                                                      <span className="font-semibold">{history.promocode}</span>
                                                    </h6>
                                                  )
                                                  : null}
                                                {history.discount_amount
                                                  ? (
                                                    <h6>
                                                      Discount Amount :
                                                      {' '}
                                                      <span className="font-semibold">{history.discount_amount}</span>
                                                    </h6>
                                                  )
                                                  : null}

                                              </div>
                                            )
                                              : null}
                                          </div>

                                        </div>

                                        <div>
                                          {product.real_product_media?.length ? (
                                            <button
                                              onClick={() => handleReviewRealProductModal(product.real_product_media)}
                                              type="button"
                                              className="flex items-center border border-black rounded-full
                                              text-xs bg-lemon hover:opacity-80 pt-3 pb-2 px-4 font-serif font-medium
                                              lg:whitespace-nowrap ml-auto
                                              lg:text-xl lg:px-5 lg:pt-3 lg:pb-2 w-3/4 lg:w-full "
                                            >
                                              Review Real Product Photos
                                            </button>

                                          ) : null}

                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Accordion>

                              </div>
                            ))}
                          </div>
                        </div>
                      )
                        : (
                          <div>
                            <h2 className="text-3xl text-center font-serif font-semibold py-10">
                              You haven't made any order.
                            </h2>
                          </div>
                        )}

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
    </>

  );
};

export default OrderHistory;
