import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const onButtonEmptyFn = () => {
  alert('Button Function Pending');
};

export function isEmpty(value: string | null) {
  if (value && value !== 'undefined' && value !== 'null' && value !== '' && typeof value !== undefined) {
    return false;
  }
  return true;
}

export const successToast = (message: string) => {
  toast.configure();
  toast.success(message, { theme: 'dark', position: 'top-center', hideProgressBar: true });
};
export const errorToast = (message: string) => {
  toast.configure();
  toast.error(message, { theme: 'dark', position: 'top-center', hideProgressBar: true });
};

export const calculateProductPrice = (prices: bulkPrice[], totalProductQuantity:number) => {
  let price;
  let minQuantity;
  let maxQuantity;
  // eslint-disable-next-line no-loops/no-loops
  for (let i = 0; i < prices.length; i++) {
    const range = prices[i].range.split(/>|-/);
    if (range[0].length) {
      [minQuantity, maxQuantity] = range;
      if (totalProductQuantity >= Number(minQuantity) && totalProductQuantity <= Number(maxQuantity)) price = prices[i]._price;
    } else {
      [, minQuantity] = range;
      if (totalProductQuantity >= Number(minQuantity)) price = prices[i]._price;
    }
    if (price) break;
  }
  return price;
};
