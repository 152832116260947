/* eslint-disable import/no-unresolved */
import { withRouter } from 'react-router';

import AboutUsHero from 'assets/images/placeholderImages/about_us_hero.jpg';
import AboutUsRetail from 'assets/images/placeholderImages/about_us_retail.png';
import { ReactComponent as Chill } from 'assets/images/placeholderImages/chill.svg';

import { aboutUs } from 'Constants';

const About: React.FC = () => (
  <div>
    <section className="new-container px-6 py-8">
      <div className="border-4 border-black grid grid-cols-12">

        <div className="p-10 flex justify-center items-center col-span-12  lg:col-span-6 bg-purple-400">
          <div className="text-center">
            <div className="lg:ml-24">
              <Chill />
            </div>
            {/* <h1 className="text-7xl font-bold text-lemon font-mono drop-shadow-lg ">CHILL,</h1> */}
            <h6 className="font-semibold pt-4 text-2xl leading-tight w-3/4 mx-auto">
              we’re here to help stock your store with quality products your customers will love.
            </h6>
          </div>
        </div>

        <div className=" col-span-12 lg:col-span-6">
          <img
            src={AboutUsHero}
            className="object-cover"
            alt="About page for resourece website"
          />
        </div>
      </div>
    </section>

    <section className="new-container px-6 py-8">
      <div className=" grid grid-cols-12">
        <div className="col-span-12 lg:col-span-6 hidden lg:block">
          <img src={AboutUsRetail} alt="About page for resourece website" />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <h1 className="text-3xl lg:mt-16 lg:text-5xl font-medium font-serif">
            We understand
            <span className="font-bold  border-b-8 border-lemon"> retail.</span>
          </h1>
          <p
            className="tracking-tighter mt-4"
            dangerouslySetInnerHTML={{ __html: aboutUs.hero_paragraph }}
          />
        </div>
      </div>
    </section>

    <section className=" bg-lemon">
      <div className="new-container mt-5 py-16">

        <span className="font-serif font-semibold text-3xl lg:text-5xl ">
          {aboutUs.our_team.heading}
          <hr className="border-b-8 -mt-5 border-purple-300 w-40 lg:w-64" />
        </span>

        <div className="grid grid-cols-12">
          {aboutUs.our_team.team.map((person:aboutUsTeamTypes) => (
            <div className="col-span-6 lg:col-span-3 p-4" key={person.name}>
              <div className="lg:text-center">
                <img
                  className="rounded-full mb-4 "
                  src={person.image}
                  alt={person.name}
                />
                <h2 className="font-serif font-semibold">{person.name}</h2>
                <h5 className="tracking-tighter">{person.position}</h5>
              </div>
            </div>
          ))}

        </div>
      </div>
    </section>

  </div>
);

export default withRouter(About);
