import React from 'react';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../assets/icons/minus.svg';

type SizeInput = {
  sku: sku;
  addQuantity: (value: sku, quantity: number) => void;
  removeQuantity: (value: sku) => void;
  onChangeProductQuantity: (sizeType: sku, productQuantity: number) => void;
};

const ProductQuantity: React.FC<SizeInput> = (props) => {
  const {
    sku, addQuantity, removeQuantity, onChangeProductQuantity,
  } = props;
  return (
    <div className="flex flex-row pl-5 pb-3">
      <div className="flex flex-row items-center text-xl w-20 font-serif">{sku.size_name}</div>
      <div className="flex flex-row justify-evenly w-60 items-center">
        <Minus
          className={`${(sku.quantity > 0) ? 'opacity-100' : 'opacity-40'} cursor-pointer`}
          onClick={() => removeQuantity(sku)}
        />
        <input
          className="border-black border-1 rounded-full bg-gray-200 w-16 text-center"
          type="number"
          id={sku.size_name}
          value={sku.quantity}
          onChange={(e) => onChangeProductQuantity(sku, Number(e.target.value))}
        />
        <Plus
          className="cursor-pointer"
          onClick={() => addQuantity(sku, sku.quantity)}
        />
      </div>
    </div>
  );
};

export default ProductQuantity;
