import useGetFetch from 'hooks/useGetFetch';
import { getAPIUrl, getStripeApiUrl, Loader } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';
import { manageProductionUrl, scheduleCallUrl } from 'Constants';
import { useEffect, useRef, useState } from 'react';
import { successToast } from 'Util/Utility';
import { useHistory, useLocation } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { ReactComponent as User } from '../assets/icons/person.svg';
import { ReactComponent as Calendar } from '../assets/icons/calendar_month.svg';

const PilotProgram: React.FC = () => {
  const location = useLocation();
  const query = location.search;
  const queryParams = new URLSearchParams(location.search);
  const spanRef = useRef<any>(null);
  const [manageSubscription, setManageSubscription] = useState<boolean>(false);
  const [congrates, setCongrates] = useState<boolean>(false);
  const [onboardedStatus, setOnboardedStatus] = useState<string>('');
  let apiUrl = getAPIUrl(apiRoutes.PROFILE);
  if (query.includes('session_id')) {
    apiUrl += '?stripe_session_id=' + queryParams.get('session_id');
  }
  const [profile, loading] = useGetFetch(apiUrl, undefined, true);
  const [stripeSubscriptionData] = useGetFetch(
    manageSubscription ? getAPIUrl(apiRoutes.MANAGE_STRIPE_SUBSCRIPTION) : null,
    undefined,
    true,
  );
  const { update } = useIntercom();
  const history = useHistory();
  const {
    first_name: firstName, last_name: lastName, user_sign_up_status: signupStatus,
    email: profileEmail,
  } = profile;

  useEffect(() => {
    const query = location.search;
    const queryParams = new URLSearchParams(location.search);
    const userOnboardedStatus: string = signupStatus || '';
    setOnboardedStatus(userOnboardedStatus);
    window.scroll(0, 0);
    // Intercom live chat set profile update
    localStorage.setItem('name', firstName);
    localStorage.setItem('email', profileEmail);
    update({ name: firstName, email: profileEmail });

    if (stripeSubscriptionData && stripeSubscriptionData.session_url) {
      window.open(stripeSubscriptionData.session_url);
      setManageSubscription(false);
    }
    if (query.includes('stripe')) {
      setOnboardedStatus('p');
    }
    if (query.includes('calendly')) {
      setCongrates(true);
      setOnboardedStatus('on');
    }
    if (window.performance) {
      if (performance.navigation.type === 1 && queryParams.has('calendly')) {
        queryParams.delete('calendly');
        setCongrates(false);
        history.replace({
          pathname: '/production/pilotProgram',
          search: queryParams.toString(),
        });
      }
    }
  }, [location, stripeSubscriptionData, signupStatus, loading,
    firstName, profileEmail, update, history]);

  const handleSubscription = () => {
    setManageSubscription(true);
    successToast('Please wait...');
  };

  const handleStripe = () => {
    // todo needs to be implemented according to requirement
    window.open(getStripeApiUrl(profileEmail));
  };

  const handleScheduleCall = async () => {
    const queryParams = new URLSearchParams(location.search);
    const currentDate = new Date().toISOString();
    const splitCurrentDate = currentDate.split('-');
    scheduleCallUrl(splitCurrentDate[0], splitCurrentDate[1], firstName, profileEmail, lastName);
    if (queryParams.has('stripe')) {
      queryParams.delete('stripe');
      history.replace({
        pathname: '/production/pilotProgram',
        search: queryParams.toString(),
      });
      window.location.reload();
    }
  };

  const handleManageProduction = (url: string) => {
    window.open(url);
  };
  const isProduction = history.location.pathname.includes('/production');

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className={`flex justify-center items-center bg-center object-contain ${isProduction
              ? 'bg-gradientSea'
              : 'md:bg-pilotProgramBackgroundDesktop bg-pilotProgramBackgroundMobile'}`}
          >
            <div
              className={`h-full w-full sm:w-full md:w-11/12 lg:w-11/12 xl:w-10/12 ${(onboardedStatus?.toLowerCase() === 'on')
                ? 'flex justify-center items-center flex-col '
                : ''}`}
            >
              <div
                className={`pt-0 md:pt-32 hidden md:flex ${(onboardedStatus?.toLowerCase() === 'on') && 'flex items-center justify-center'} ${onboardedStatus?.toLowerCase() === '' && 'flex items-center justify-center'} sx:pt-20`}
              >
                {firstName && (
                  <h2 className="font-serif font-semibold lg:font-medium text-3xl lg:text-5xl -mt-20">
                    {`Hi, ${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`}
                  </h2>
                )}
              </div>
              {congrates ? (
                <div className="bg-gradient-to-t from-purple-shade to-seaGreen  md:from-white md:to-white   border-2 rounded-b-lg border-black py-4 md:py-11 md:rounded-lg">
                  <div className="text-center">
                    <span className="font-Inter font-semibold text-lg sm:text-3xl ">
                      Congratulations! 🎉
                    </span>
                    <div className="mt-4 mx-4">
                      <span className="font-Inter font-medium">
                        We are activating your account at the moment! Sit back for a little
                        and you will be able to log in via the same email after 30 mins by
                        clicking ‘Manage Production’.
                        {' '}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {onboardedStatus?.toLowerCase() !== 'on' && onboardedStatus?.toLowerCase() !== '' && (
                <div className="bg-gradient-to-t from-purple-shade to-seaGreen  md:from-white md:to-white    border-2 rounded-b-lg border-black md:py-11 md:rounded-lg">
                  <div className="w-full flex items-center justify-center">
                    <nav aria-label="Progress">
                      <ol role="list" className="flex items-center mt-6 md:mt-0">
                        <li className="relative pr-16 sm:pr-32 md:pr-40 lg:pr-48 xl:pr-64">
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-black">
                              <div className="text-white step-check">
                                <svg
                                  className="w-5 h-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="relative w-8 h-8 flex items-center justify-center bg-black rounded-full hover:bg-black">
                            <div className="text-center m-t-6 sm:mt-20 md:whitespace-nowrap sm:whitespace-normal mt-20">
                              <span className="text-sm font-FahkwangRegular font-semibold sm:text-sm md:text-base lg:text-xl hidden md:inline-block">
                                Sign up
                              </span>
                            </div>
                          </div>
                        </li>

                        <li className="relative pr-16 sm:pr-28 md:pr-40 lg:pr-48 xl:pr-64">
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-black">
                              {onboardedStatus?.toLowerCase() === 'p' ? (
                                <div className="text-white step-check">
                                  <svg
                                    className="w-5 h-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              ) : (
                                <div className="text-white step-index">2</div>
                              )}
                            </div>
                          </div>
                          <span
                            className={`relative w-8 h-8 flex items-center justify-center ${onboardedStatus?.toLowerCase() === 'p' ? 'bg-black' : 'bg-orangeShade'} rounded-full`}
                          >
                            <div className="text-center xs-m-t-9 md:whitespace-nowrap sm:whitespace-normal mt-20">
                              <span className="text-sm font-FahkwangRegular font-semibold sm:text-sm md:text-base lg:text-xl hidden md:inline-block">
                                Enter Payment
                              </span>
                            </div>
                          </span>
                        </li>

                        <li className="relative pr-16 sm:pr-28 md:pr-40 lg:pr-48 xl:pr-64">
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-black">
                              <div
                                className={`${onboardedStatus?.toLowerCase() === 'p'
                                  ? 'text-white'
                                  : 'text-black'} step-index`}
                              >
                                3
                              </div>
                            </div>
                          </div>
                          <span
                            ref={spanRef}
                            className={`relative w-8 h-8 flex items-center justify-center ${onboardedStatus?.toLowerCase() === 'p'
                              ? 'bg-orangeShade'
                              : 'border-2 border-black bg-white'}  rounded-full`}
                          >
                            <div className="text-center m-t-6 md:whitespace-nowrap sm:whitespace-normal mt-20">
                              <span className="text-sm font-FahkwangRegular font-semibold sm:text-sm md:text-base lg:text-xl hidden md:inline-block">
                                Onboarding Call
                              </span>
                            </div>
                          </span>
                        </li>

                        <li className="relative">
                          <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="h-0.5 w-full bg-black">
                              <div
                                className="text-black step-index"
                              >
                                4
                              </div>
                            </div>
                          </div>
                          <span
                            className="relative w-8 h-8 flex items-center justify-center border-2 border-black bg-white rounded-full"
                          >
                            <div className="text-center m-t-6 md:whitespace-nowrap sm:whitespace-normal mt-20">
                              <span className="text-sm font-FahkwangRegular font-semibold sm:text-sm md:text-base lg:text-xl hidden md:inline-block">
                                Start Production!
                              </span>
                            </div>
                          </span>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {onboardedStatus?.toLowerCase() === 'n' ? (
                    <div className="m-10percent md:hidden">
                      <h1 className="text-xl font-bold">Complete payment info</h1>
                      <h1>You won’t be charged until a week from now </h1>
                    </div>
                  ) : (
                    <div className="m-10percent md:hidden">
                      <h1 className="text-xl font-bold">Onboarding call</h1>
                      <h1>Schedule a call with us to get started!</h1>
                    </div>
                  )}
                  <div className="flex justify-center items-center mt-4 md:mt-24 pt-10 ">
                    {onboardedStatus?.toLowerCase() === 'n' ? (
                      <div className="flex flex-col justify-center">
                        <div className="pl-20">
                          <button
                            type="button"
                            onClick={() => handleStripe()}
                            className="urlButtonsOnPilotProgram w-80 items-center justify-center bg-orangeShade hidden md:flex"
                          >
                            <div className="font-medium font-FahkwangRegular text-xl text-black text-center">
                              Complete Payment
                            </div>
                          </button>
                        </div>
                        <p className="text-center">
                          <span className="font-semibold pricing-text text-sm">Please make sure you use the same email address at payment checkout!</span>
                        </p>
                      </div>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            handleScheduleCall();
                          }}
                          className="urlButtonsOnPilotProgram w-80 items-center justify-center bg-orangeShade  hidden md:flex"
                        >
                          <div className="font-medium font-FahkwangRegular text-xl text-black text-center">
                            Schedule Onboarding Call
                          </div>
                        </button>
                      </>
                    )}
                  </div>
                  <div>
                    {onboardedStatus?.toLowerCase() === 'p'
                      && (
                        <p className="text-center">
                          <span className="font-semibold pricing-text text-sm">Please use the same email address to schedule onboarding call. Thank You!</span>
                        </p>
                      )}
                  </div>
                </div>
              )}
              {firstName && (
                <div
                  className="font-serif font-semibold lg:font-medium text-3xl  lg:text-5xl md:hidden px-10percent mt-8"
                >
                  {`Hi, ${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`}
                </div>
              )}

              <div className="py-7 button-box text-center flex flex-col justify-center items-center ">
                {(onboardedStatus?.toLowerCase() !== 'on')
                  && (onboardedStatus?.toLowerCase() === 'n' ? (
                    <button
                      type="button"
                      onClick={() => handleStripe()}
                      className="urlButtonsOnPilotProgram w-80 items-center justify-center bg-orangeShade md:hidden flex"
                    >
                      <div className="font-medium font-FahkwangRegular text-xl text-black text-center">
                        Complete Payment
                      </div>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        handleScheduleCall();
                      }}
                      className="urlButtonsOnPilotProgram w-80 items-center justify-center bg-orangeShade  md:hidden flex"
                    >
                      <div className="font-medium font-FahkwangRegular text-xl text-black text-center">
                        Schedule Onboarding Call
                      </div>
                    </button>
                  ))}
                <button
                  type="button"
                  disabled={onboardedStatus?.toLowerCase() !== 'on'}
                  onClick={() => handleManageProduction(manageProductionUrl)}
                  className={`urlButtonsOnPilotProgram ${isProduction ? 'bg-seaGreen' : ''} ${(onboardedStatus?.toLowerCase() !== 'on') && 'bg-grey cursor-not-allowed'}`}
                >
                  <Calendar className="h-7 mr-2 -mt-1 md:-mt-1 " />
                  <div className="font-semibold font-FahkwangRegular text-xl text-black">
                    Manage Production
                  </div>
                </button>
                <button
                  type="button"
                  disabled={onboardedStatus?.toLowerCase() !== 'on'}
                  onClick={() => handleSubscription()}
                  className={`urlButtonsOnPilotProgram ${isProduction && (onboardedStatus?.toLowerCase() !== 'on')
                    ? 'bg-grey cursor-not-allowed'
                    : 'bg-pink'}`}
                >
                  <div className="flex flex-row justify-center items-center">
                    <User className="h-7 mr-2 -mt-1 md:-mt-1" />
                    <div className="font-semibold font-FahkwangRegular text-xl text-black">
                      Manage Subscription
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PilotProgram;
