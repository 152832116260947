import { getAPIUrl, getAuthHeaders } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';

export default async function CreateOrder(payload: any) {
  try {
    const headers = getAuthHeaders();
    const res = await fetch(getAPIUrl(apiRoutes.ORDER), {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}
