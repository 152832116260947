/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import { getAPIUrl, Loader } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';
import Tab from 'Components/Tab';
import { requestTabMap } from 'Constants';
import useGetFetch from 'hooks/useGetFetch';
import React, { useEffect, useState } from 'react';
import Card from 'Components/Card';

type listInput = {
  listData: Card[];
};
const RequestList: React.FC<listInput> = () => {
  const [RequestList, loading] = useGetFetch(getAPIUrl(apiRoutes.REQUEST), undefined, true);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [filteredData, setFilteredData] = useState<RequestList[]>(RequestList);

  // Set the Product Active tab
  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  // Highlight the selected Tab Function
  const selectedTabsFn = (condition: boolean) => (condition ? 'border-b-4 border-black' : '');

  const pendingData = RequestList.filter((request: RequestList) => request.request_status === 'pending');

  const completedData = RequestList.filter((request: RequestList) => request.request_status === 'complete');

  const sizeChartData = RequestList.filter((request: RequestList) => request.size_request);

  useEffect(() => {
    switch (activeTab) {
      case 1:
        setFilteredData(RequestList.filter((request: RequestList) => request.request_status === requestTabMap[activeTab]));
        break;
      case 2:
        setFilteredData(RequestList.filter((request: RequestList) => request.request_status === requestTabMap[activeTab]));
        break;
      case 3:
        setFilteredData(RequestList.filter((request: RequestList) => request.size_request));
        break;
      default:
        setFilteredData(RequestList);
        break;
    }
  }, [activeTab, RequestList]);

  const showTabs = [
    {
      tabName: 'All',
      numberOfItems: RequestList,
    },
    {
      tabName: 'Pending',
      numberOfItems: pendingData,
    },
    {
      tabName: 'Completed',
      numberOfItems: completedData,
    },
    {
      tabName: 'Size Chart',
      numberOfItems: sizeChartData,
    },
  ];

  return (
    <>
      {loading ? <Loader />
        : (
          // <div className="bg-oval bg-opacity-10 bg-no-repeat  md:bg-repeat-space bg-left-hero-homepage">
          <div>
            <h1 className="text-4xl px-6 lg:px-40 pt-6 font-serif font-semibold lg:pt-10 lg:pb-5">My Requests</h1>
            <div className="flex flex-wrap lg:px-32">
              <div className="w-full">
                <ul className="flex flex-row list-none pl-6 pr-1 pt-3 border-b border-black overflow-scroll">
                  {showTabs.map((tab: any, index: number) => (
                    <div key={index}>
                      <Tab
                        style={selectedTabsFn(activeTab === index)}
                        onTabClick={onTabClick}
                        currentTab={index}
                      >
                        <div className="flex flex-row cursor-pointer whitespace-nowrap">
                          <div className="text-sm font-serif font-semibold ">
                            {tab.tabName}
                          </div>
                          {tab.numberOfItems.length > 0
                            && (
                              <span className="bg-lemon rounded-full border border-black px-1.5 ml-2 text-sm font-serif font-semibold">
                                {tab.numberOfItems.length}
                              </span>
                            )}
                        </div>
                      </Tab>
                    </div>
                  ))}
                </ul>
                {filteredData.length > 0 ? (
                  <div className="grid grid-cols-12 gap-4 px-6 sm:px-6 lg:px-48 lg:py-10 mt-5">
                    {filteredData.map((item: RequestList) => (
                      <div
                        key={item.id}
                        className={`col-span-6 sm:col-span-4 md:col-span-4 ${item.product ? '' : 'opacity-80'}`}
                      >
                        <div className='relative'>
                          <Card
                            image={item.image_url}
                            productName={item.name}
                            location={item.address === '-' ? '' : item.address}
                            status={item.request_status}
                            minQuantity={item.moq}
                            type="requestlist"
                            productId={item.product && item.product}
                          />
                          <div className="absolute top-28 bg-lemon left-0.5 px-2 py-1 text-xxs md:top-60">
                            {item.size_request ? 'Size Request' : 'Product Request'}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
                  : (activeTab === 3 ? (
                    <div>
                      <h2 className="text-4xl text-center font-serif font-semibold py-10">
                        No Size Chart Request
                      </h2>
                    </div>
                  )
                    : (
                      <div>
                        <h2 className="text-4xl text-center font-serif font-semibold py-10">
                          No Products Request
                        </h2>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default RequestList;
