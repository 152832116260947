import React, { useEffect, useState } from 'react';

import { useCartState } from 'Context/Cart';

import Accordion from 'Components/Accordian';

import { useHistory } from 'react-router-dom';
import CreateOrder from 'API/CreateOrder';
import { errorToast, successToast } from 'Util/Utility';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
} from 'formik';
import * as yup from 'yup';
import CheckoutProductCard from 'Components/atoms/CheckoutProductCard';
import { ReactComponent as ChevronLeft } from '../assets/icons/chevron-left.svg';

const phoneRegExp = /^[+]\d{1,2}\d{6,10}$/;

const shippingFormScheme = yup.object().shape({
  customer_fname: yup.string().required('*Required'),
  customer_lname: yup.string().required('*Required'),
  customer_email: yup.string().email('Please enter valid email').required('*Required'),
  customer_phone: yup.string().matches(phoneRegExp, 'Enter a valid phone number (e.g. +12125552368).').required('Required'),
  shipping_fname: yup.string().required('*Required'),
  shipping_lname: yup.string().required('*Required'),
  shipping_address: yup.string().required('*Required'),
  shipping_city: yup.string().required('*Required'),
  shipping_state: yup.string().required('*Required'),
  shipping_zipcode: yup.string().required('*Required'),
  // Billing Address
  billing_fname: yup.string().required('*Required'),
  billing_lname: yup.string().required('*Required'),
  billing_address: yup.string().required('*Required'),
  billing_city: yup.string().required('*Required'),
  billing_state: yup.string().required('*Required'),
  billing_zipcode: yup.string().required('*Required'),
});

const Checkout: React.FC = () => {
  const history = useHistory();

  const [isBillingAddresSame, setIsBillingAddresSame] = useState(false);

  const [customerDetails, setCustomerDetails] = useState({
    customer_fname: '',
    customer_lname: '',
    customer_email: '',
    customer_phone: '',
    shipping_fname: '',
    shipping_lname: '',
    shipping_address: '',
    shipping_city: '',
    shipping_state: '',
    shipping_zipcode: '',
    // Billing Address
    billing_fname: '',
    billing_lname: '',
    billing_address: '',
    billing_city: '',
    billing_state: '',
    billing_zipcode: '',
  });

  const amount = JSON.parse(localStorage.getItem('amount') || 'null');
  const shippingCharges = JSON.parse(localStorage.getItem('shippingCharges') || 'null');
  const totalAmount = JSON.parse(localStorage.getItem('totalAmount') || 'null');
  const promoCode = localStorage.getItem('promoCode');
  const promoUserName = localStorage.getItem('promoUserName');
  const paymentApp = localStorage.getItem('paymentApp');
  const cartState = JSON.parse(localStorage.getItem('cartItem') || '[]');
  const discount_uuid = JSON.parse(localStorage.getItem('discount_uuid') || 'null');

  // const handlePageRedirect = (pathname: string, amount?: number, order_id?: string) => {
  //   history.push(pathname, { amount, order_id });
  //   window.scrollTo(0, 0);
  // };

  // billing_phone
  // shipping_phone

  const handleCreateOrder = async (values: any) => {
    try {
      const orderItems: any = [];
      cartState.forEach((cartItem: any) => {
        cartItem.order_items.filter((skuItem: any) => skuItem.quantity)
          .forEach((skuItem: any) => {
            const updatedSku = {
              ...skuItem,
              sku: skuItem.id,
            };
            delete updatedSku.id;
            delete updatedSku.size_name;
            delete updatedSku.colour;
            delete updatedSku.size;
            orderItems.push(updatedSku);
          });
      });
      const order = {
        ...values,
        promo_username: promoUserName,
        promocode: promoCode,
        payment_app: paymentApp,
        order_items: orderItems,
        amount,
        shipping_amount: shippingCharges,
        discount_coupon: discount_uuid,
      };
      const response = await CreateOrder(order);
      if (response.id) {
        // successToast('your order has been created successfully');
        localStorage.setItem('order_id', JSON.stringify(response.id));
        history.push('/stripe');
        window.scrollTo(0, 0);
      }
    } catch (e: any) {
      // errorToast(e);
      errorToast(e.customer_fname && e.customer_fname[0]);
      errorToast(e.customer_lname && e.customer_lname[0]);
      errorToast(e.customer_email && e.customer_email[0]);
      errorToast(e.customer_phone && e.customer_phone[0]);
      errorToast(e.shipping_fname && e.shipping_fname[0]);
      errorToast(e.shipping_lname && e.shipping_lname[0]);
      errorToast(e.shipping_address && e.shipping_address[0]);
      errorToast(e.shipping_city && e.shipping_city[0]);
      errorToast(e.shipping_state && e.shipping_state[0]);
      errorToast(e.shipping_zipcode && e.shipping_zipcode[0]);
      // Billing Address
      errorToast(e.billing_fname && e.billing_fname[0]);
      errorToast(e.billing_lname && e.billing_lname[0]);
      errorToast(e.billing_address && e.billing_address[0]);
      errorToast(e.billing_city && e.billing_city[0]);
      errorToast(e.billing_state && e.billing_state[0]);
      errorToast(e.billing_zipcode && e.billing_zipcode[0]);
      // promo code
      errorToast(e.promo_username && e.promo_username[0]);
      errorToast(e.promocode && e.promocode[0]);
      errorToast(e.payment_app && e.payment_app[0]);
    }
  };

  const handleBillingAddresCheckbox = (e: any, values: any) => {
    let updatedCustomerDetails;
    if (!isBillingAddresSame) {
      updatedCustomerDetails = {
        ...values,
        // Billing Address
        billing_fname: values.shipping_fname,
        billing_lname: values.shipping_lname,
        billing_address: values.shipping_address,
        billing_city: values.shipping_city,
        billing_state: values.shipping_state,
        billing_zipcode: values.shipping_zipcode,
      };
    } else {
      updatedCustomerDetails = {
        ...values,
        // Billing Address
        billing_fname: '',
        billing_lname: '',
        billing_address: '',
        billing_city: '',
        billing_state: '',
        billing_zipcode: '',
      };
    }
    setCustomerDetails(updatedCustomerDetails);
    e.stopPropagation();
    setIsBillingAddresSame((prevIsBillingAddresSame) => !prevIsBillingAddresSame);
  };

  return (
    <div className="py-5 grid grid-cols-12 lg:new-container">
      <div className="mx-4 py-3 col-span-12 md:order-1 md:col-span-5 md:col-start-2 md:mb-4">
        <button
          onClick={() => history.push('/product-list')}
          type="button"
          className="font-sans text-sm font-semibold  flex"
        >
          <ChevronLeft className="mr-2  " />
          back to marketplace
        </button>
      </div>

      <div className="col-span-12 order-1 md:order-2 md:col-span-3 md:col-start-9 ">
        <Accordion
          heading="Order Summary"
          headingClass="bg-black text-lemon font-medium text-lg py-4 px-10 font-serif"
          preOpen
        >
          <div className="lg:bg-gray-100 lg:border lg:border-black">
            {cartState.map((item: any, index: number) => (
              <div key={index} className="pt-3 pb-6 px-4">
                <CheckoutProductCard
                  productName={item.name}
                  manufacturerName={item.manufacturer}
                  color={item.colour}
                  image={item.image.image}
                  orderItems={item.order_items}
                  id={item.id}
                  cartItemIndex={index}
                />
              </div>
            ))}
            {cartState.length && totalAmount
              && (
                <div className="flex justify-around font-serif lg:pt-4 md:py-4
              lg:bg-black lg:text-lemon lg:border-b lg:border-gray-500"
                >
                  <h4>
                    Subtotal:
                  </h4>
                  <h4 className="base font-semibold">
                    {`$${totalAmount / 100}`}
                  </h4>
                </div>
              )}
          </div>

        </Accordion>
      </div>

      <div className="col-span-12 order-2 md:order-1 md:col-span-5 md:col-start-3">
        <div className="">
          <Accordion
            heading="Your Details"
            preOpen
            headingClass="bg-purple-shade border-2 border-black font-medium text-lg py-4 px-10  font-serif"
          >
            <div className="px-10 md:bg-white md:border md:border-black py-5">
              <Formik
                validationSchema={shippingFormScheme}
                onSubmit={(values) => {
                  handleCreateOrder(values);
                }}
                initialValues={customerDetails}
                enableReinitialize
              >
                {({ values }) => (
                  <div>
                    <Form>
                      <div className="grid grid-cols-12 gap-x-6 gap-y-4 pb-3">
                        {/* First Name */}
                        <div className="col-span-6">
                          <Field name="customer_fname" placeholder="first name*" type="text" className="inputTextField" />
                          <ErrorMessage component="p" name="customer_fname" className="text-red-500 text-xs ml-2" />
                        </div>
                        {/* Last Name */}
                        <div className="col-span-6">
                          <Field name="customer_lname" placeholder="last name*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="customer_lname" className="text-red-500 text-xs ml-2" />
                        </div>
                        {/* Email */}
                        <div className="col-span-12">
                          <Field name="customer_email" placeholder="email*" type="email" className="inputTextField" />
                          <ErrorMessage component="div" name="customer_email" className="text-red-500 text-xs ml-2" />
                        </div>
                        {/* Phone Number */}
                        <div className="col-span-12">
                          <Field name="customer_phone" placeholder="phone*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="customer_phone" className="text-red-500 text-xs ml-2" />
                        </div>
                      </div>

                      <div className="grid grid-cols-12 gap-x-6 gap-y-4 border-t border-gray-500 pt-5">
                        <div className="col-span-12">
                          <h2 className="text-xl font-semibold font-serif">Shipping Address</h2>
                        </div>
                        {/* First Name */}
                        <div className="col-span-6">
                          <Field name="shipping_fname" placeholder="first name*" type="text" className="inputTextField" />
                          <ErrorMessage component="p" name="shipping_fname" className="text-red-500 text-xs ml-2" />
                        </div>
                        {/* Last Name */}
                        <div className="col-span-6">
                          <Field name="shipping_lname" placeholder="last name*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="shipping_lname" className="text-red-500 text-xs ml-2" />
                        </div>

                        <div className="col-span-12">
                          <Field name="shipping_address" placeholder="address*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="shipping_address" className="text-red-500 text-xs ml-2" />
                        </div>

                        <div className="col-span-12">
                          <Field name="shipping_city" placeholder="city*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="shipping_city" className="text-red-500 text-xs ml-2" />
                        </div>

                        <div className="col-span-6">
                          <Field name="shipping_state" placeholder="country*" type="text" className="inputTextField" />
                          <ErrorMessage component="p" name="shipping_state" className="text-red-500 text-xs ml-2" />
                        </div>
                        {/* Last Name */}
                        <div className="col-span-6">
                          <Field name="shipping_zipcode" placeholder="zipcode*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="shipping_zipcode" className="text-red-500 text-xs ml-2" />
                        </div>

                        {/* Billing Address */}

                        <div className="col-span-12">
                          <h2 className="text-xl font-semibold font-serif">Billing Address</h2>
                        </div>

                        <div className="pb-3 col-span-12">
                          <label className="items-center">
                            <input
                              type="checkbox"
                              onClick={(e) => handleBillingAddresCheckbox(e, values)}
                              className="form-radio text-black focus:ring-black w-6 h-6 border-2  border-black bg-sand"
                              name="radio-colors"
                              value="Billing address is the same as shipping address"
                              checked={isBillingAddresSame}
                            />
                            <span className="ml-2 text-xs">Billing address is the same as shipping address</span>
                          </label>
                        </div>

                        <div className="col-span-6">
                          <Field name="billing_fname" placeholder="first name*" type="text" className="inputTextField" />
                          <ErrorMessage component="p" name="billing_fname" className="text-red-500 text-xs ml-2" />
                        </div>

                        <div className="col-span-6">
                          <Field name="billing_lname" placeholder="last name*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="billing_lname" className="text-red-500 text-xs ml-2" />
                        </div>

                        <div className="col-span-12">
                          <Field name="billing_address" placeholder="address*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="billing_address" className="text-red-500 text-xs ml-2" />
                        </div>

                        <div className="col-span-12">
                          <Field name="billing_city" placeholder="city*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="billing_city" className="text-red-500 text-xs ml-2" />
                        </div>

                        <div className="col-span-6">
                          <Field name="billing_state" placeholder="country*" type="text" className="inputTextField" />
                          <ErrorMessage component="p" name="billing_state" className="text-red-500 text-xs ml-2" />
                        </div>
                        {/* Last Name */}
                        <div className="col-span-6">
                          <Field name="billing_zipcode" placeholder="zipcode*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="billing_zipcode" className="text-red-500 text-xs ml-2" />
                        </div>

                      </div>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          onClick={handleCreateOrder}
                          className="smallLemonButton mt-4"
                        >
                          Continue with Stripe
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </Accordion>
        </div>

      </div>
    </div>
  );
};

export default Checkout;
