import { getAPIUrl, getAuthHeaders } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';

export default async function createNewUser(payload: signUpFormType) {
  try {
    const res = await fetch(getAPIUrl(apiRoutes.SIGNUP), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUser(payload: signUpFormType) {
  try {
    const headers = getAuthHeaders();
    const res = await fetch(getAPIUrl(apiRoutes.PROFILE), {
      method: 'PATCH',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}
