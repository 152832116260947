import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import CheckoutForm from './CheckoutForm';
// import "./styles.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);
console.log(stripePromise, process.env);

const StripeIntegration = () => (
  <div className='App'>
    <div className='product'>
      <div>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  </div>
);

export default StripeIntegration;
