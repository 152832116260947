/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { useHistory } from "react-router";

import useGetFetch from "hooks/useGetFetch";
import { getAPIUrl } from "API/APIHelpers";
import { apiRoutes } from "API/APIRoutes";

import { productionFooterUrl, socialLinks } from "Constants";
import { ReactComponent as DesktopLogo } from "../assets/icons/Mobile_Landing/Production_footer_logo.svg";
import { ReactComponent as Logo } from "../assets/icons/Mobile_Landing/Production_footer_logo_mobile.svg";
import { ReactComponent as Facebook } from "../assets/icons/Mobile_Landing/Production_facebook.svg";
import { ReactComponent as Instagram } from "../assets/icons/Mobile_Landing/Production_instagram.svg";
import { ReactComponent as Linkedin } from "../assets/icons/Mobile_Landing/Production_linkedin.svg";
import { ReactComponent as FooterStarLine } from "../assets/images/production_footer_star_line.svg";
import { ReactComponent as FooterStarLineMobile } from "../assets/images/production_footer_star_line_mobile.svg";
import { ReactComponent as ForwardArrow } from "../assets/icons/Mobile_Landing/Production_forward_arrow.svg";
import { subscribeNewsletter } from "../API/Login";
import { errorToast, successToast } from "Util/Utility";
// type FooterInput = {};
const ProductionFooter: React.FC = () => {
  const [emailError, setEmailError] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const history = useHistory();

  // APi calls
  const [categoryList, categoryLoading] = useGetFetch(
    getAPIUrl(`${apiRoutes.CATEGORY}?is_on_menu=true`),
  );
  const [styleList, styleLoading] = useGetFetch(getAPIUrl(`${apiRoutes.STYLE}?is_on_menu=true`));

  const handleRedirect = (pathname: string) => {
    history.push(pathname);
  };

  const handleRedirectExternal = (url: string) => {
    window.open(url);
  };

  const showSelectedCategoryProductList = (id: number) => {
    history.push({
      pathname: `/product-list/category/${id}/1`,
    });
    window.scrollTo(0, 0);
  };

  const showSelectedStyleProductList = (id: number) => {
    history.push({
      pathname: `/product-list/style/${id}/1`,
    });
    window.scrollTo(0, 0);
  };
  const handleSubscribeNewsLetter = async () => {
    if (!emailError && emailValue?.length > 0) {
      const response = await subscribeNewsletter(emailValue);
      if (response.status == 200) {
        successToast('You should receive a confirmation email');
        setEmailValue("");
      }
      else {
        errorToast("Something went wrong");
      }
    }
    else {
      return false;
    }
  }
  const handleInputEmailChange = (event: string) => {
    const regexEmail = /^\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
    const email = event
    setEmailValue(email);
    if (email?.length > 0 && !regexEmail.test(email)) {
      setEmailError('Enter valid email address');
    } else {
      setEmailError('');
    }
  }
  return (
    <footer className=" py-11 bg-black footer">
      <div className="new-container hidden md:block">
        <DesktopLogo className="w-90 lg:ml-36 mx-auto " />
      </div>
      <div className="new-container md:hidden">
        <Logo className="w-90 lg:ml-36 mx-auto " />
      </div>
      <FooterStarLine className=" hidden md:block w-full" />
      <FooterStarLineMobile className="md:hidden w-full my-3" />
      <div className="md:grid grid-cols-12 order-2 new-container">
        <div className="col-span-4 p-10 hidden lg:block order-1">
          <div className="px-14 pb-8 text-center">
            {productionFooterUrl.map(({ id, linkName, linkURL, additionalClasses }) => (
              <h5
                key={id}
                onKeyPress={() => handleRedirect(linkURL)}
                role="link"
                className={`${additionalClasses} text-seaGreen`}
                onClick={() => handleRedirect(linkURL)}
              >
                {linkName}
              </h5>
            ))}
            <a href="https://resourcefashion.co/blog" rel="noreferrer">
              <h5 className="text-2xl text-seaGreen font-medium font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3">
                Blog
              </h5>
            </a>
            <a target="_blank" href="https://www.blog.resourcefashion.co/" rel="noreferrer">
              <h5 className="text-2xl text-seaGreen font-medium font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3">
                Privacy Policy
              </h5>
            </a>
          </div>

          {/* Social Media Links */}
          <div className=" border-t border-black py-4">
            <div className="flex flex-row justify-center">
              <div>
                {/* links on desktop */}
                <Facebook
                  onClick={() => handleRedirectExternal(socialLinks.facebook)}
                  className="cursor-pointer w-10 h-10 mx-10"
                />
              </div>
              <div>
                <Instagram
                  onClick={() => handleRedirectExternal(socialLinks.instagram)}
                  className="cursor-pointer w-10 h-10 mx-10"
                />
              </div>
              <div>
                <Linkedin
                  onClick={() => handleRedirectExternal(socialLinks.linkedin)}
                  className="cursor-pointer w-10 h-10 mx-10"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-8 border-seaGreen text-seaGreen p-8 lg:py-20 lg:px-10">
          <h2 className="font-AgrandirRegular text-xl md:text-4xl font-semibold">
            subscribe to our newsletter
          </h2>
          {/* <p className="hidden lg:block text-xl opacity-80 mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p> */}

          <div className="relative mt-4 md:mt-8">
            <input
              type="email"
              value={emailValue}
              placeholder="e-mail"
              className="inputTextField bg-black border border-seaGreen text-seaGreen placeholder-seaGreen  py-4"
              onChange={(e) => handleInputEmailChange(String(e.target.value))}
            />
            <ForwardArrow
              className="absolute right-6 top-4 cursor-pointer"
              onClick={() => handleSubscribeNewsLetter()}
            />
          </div>
          {emailError && (
            <div className="text-red-600">{emailError}</div>)
          }
          <div className="lg:hidden py-5 text-center">
            <a href="mailto:info@resourcefashion.co">
              <h5 className="font-serif font-medium text-xl">Contact Us</h5>
            </a>
            <h5
              onKeyPress={() => handleRedirect("/contact-us")}
              role="link"
              className="font-serif font-medium text-xl"
              onClick={() => handleRedirect("/production/faq")}
            >
              FAQ
            </h5>
            <a target="_blank" href="https://www.blog.resourcefashion.co/" rel="noreferrer">
              <h5 className="font-serif font-medium text-xl">Terms of Service</h5>
            </a>
            {/* Social Media Links */}
            <div className=" border-t border-black py-4">
              <div className="flex flex-row justify-between">
                <div>
                  {/* links on mobile  */}
                  <a
                    target="_blank"
                    href="https://www.facebook.com/groups/resourcefashion"
                    rel="noreferrer"
                  >
                    <Facebook className="cursor-pointer w-10 h-10" />
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/resourcefashion/"
                    rel="noreferrer"
                  >
                    <Instagram className="cursor-pointer w-10 h-10" />
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/resourcefashion"
                    rel="noreferrer"
                  >
                    <Linkedin className="cursor-pointer w-10 h-10" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <h5 className="hidden md:block text-xl tracking-tighter mt-20 bottom-0">
            All Rights Reserved
          </h5>
        </div>
      </div>
    </footer>
  );
};

export default ProductionFooter;
