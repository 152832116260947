/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory } from 'react-router';

import useGetFetch from 'hooks/useGetFetch';
import { onButtonEmptyFn } from 'Util/Utility';
import { getAPIUrl } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';

import { footerCompany } from 'Constants';
import { ReactComponent as Logo } from '../assets/images/footer-logo.svg';
import { ReactComponent as Facebook } from '../assets/icons/facebook.svg';
import { ReactComponent as Instagram } from '../assets/icons/instagram.svg';
import { ReactComponent as Linkedin } from '../assets/icons/linkedin.svg';
import { ReactComponent as FacebookDesktop } from '../assets/icons/facebook_desktop.svg';
import { ReactComponent as InstagramDesktop } from '../assets/icons/instagram_desktop.svg';
import { ReactComponent as LinkedinDesktop } from '../assets/icons/linkedin_desktop.svg';
import { ReactComponent as FooterStarLine } from '../assets/images/footer-star-line.svg';
import { ReactComponent as FooterStarLineMobile } from '../assets/images/FooterStarLineMobile.svg';
import { ReactComponent as ForwardArrow } from '../assets/icons/forward-arrow.svg';

// type FooterInput = {};
const Footer: React.FC = () => {
  const history = useHistory();

  // APi calls
  const [categoryList, categoryLoading] = useGetFetch(getAPIUrl(`${apiRoutes.CATEGORY}?is_on_menu=true`));
  const [styleList, styleLoading] = useGetFetch(getAPIUrl(`${apiRoutes.STYLE}?is_on_menu=true`));

  const handleRedirect = (pathname: string) => {
    history.push(pathname);
  };

  const showSelectedCategoryProductList = (id: number) => {
    history.push({
      pathname: `/product-list/category/${id}/1`,
    });
    window.scrollTo(0, 0);
  };

  const showSelectedStyleProductList = (id: number) => {
    history.push({
      pathname: `/product-list/style/${id}/1`,
    });
    window.scrollTo(0, 0);
  };
  return (
    <footer className=" py-11 bg-black">
      <Logo className="w-90 lg:ml-36 mx-auto " />
      <FooterStarLine className=" hidden md:block w-full" />
      <FooterStarLineMobile className="md:hidden w-full my-3" />
      <div className="md:grid grid-cols-12  ">
        <div className="col-span-8 p-10 hidden lg:block">
          <div className=" bg-purple-300 ">
            <div className="grid grid-cols-12 px-14 pt-14 pb-8">
              {/* Source by Category */}
              <div className="col-span-4">
                <h2 className="font-black font-serif text-2xl pb-2 tracking-tighter">
                  Source by Category
                </h2>

                {categoryList.slice(0, 5).map((links: categoryTypes) => (
                  <div
                    key={links.id}
                    onClick={() => showSelectedCategoryProductList(links.id)}
                  >
                    <h6 className="text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer hover:opacity-80 pb-4 pt-3">{links.name}</h6>
                  </div>
                ))}

              </div>
              {/* Source by Style */}
              <div className="col-span-4">
                <h2 className="font-black font-serif text-2xl pb-2 tracking-tighter">
                  Source by Style
                </h2>

                {styleList.slice(0, 5).map((links: categoryTypes) => (
                  <div
                    key={links.id}
                    onClick={() => showSelectedStyleProductList(links.id)}
                  >
                    <h6 className="text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer hover:opacity-80 pb-4 pt-3">{links.name}</h6>
                  </div>
                ))}

              </div>
              {/* Company */}
              <div className="col-span-4">
                <h2 className="font-black font-serif text-2xl pb-2 tracking-tighter">
                  Company
                </h2>

                <a href="mailto:info@resourcefashion.co">
                  <h5 className="text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3">
                    Contact Us
                  </h5>
                </a>

                {footerCompany.map(({
                  id,
                  linkName,
                  linkURL,
                  additionalClasses,
                }) => (
                  <h5
                    key={id}
                    onKeyPress={() => handleRedirect(linkURL)}
                    role="link"
                    className={additionalClasses}
                    onClick={() => handleRedirect(linkURL)}
                  >
                    {linkName}
                  </h5>
                ))}

                <a target="_blank" href="https://www.blog.resourcefashion.co/" rel="noreferrer">
                  <h5 className="text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3">
                    Privacy Policy
                  </h5>
                </a>

              </div>
            </div>

            {/* Social Media Links */}
            <div className=" border-t border-black py-4">
              <div className="flex flex-row justify-center">
                <div>
                  {/* links on desktop */}
                  <FacebookDesktop
                    onClick={() => handleRedirect('/product-list')}
                    className="cursor-pointer w-10 h-10 mx-10"
                  />
                </div>
                <div>
                  <InstagramDesktop
                    onClick={() => handleRedirect('/product-list')}
                    className="cursor-pointer w-10 h-10 mx-10"
                  />
                </div>
                <div>
                  <LinkedinDesktop
                    onClick={() => handleRedirect('/product-list')}
                    className="cursor-pointer w-10 h-10 mx-10"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4 border-lemon text-lemon p-10 lg:p-20">
          <h2 className="font-serif text-2xl md:text-4xl font-semibold">
            newsletter sign up
          </h2>
          {/* <p className="hidden lg:block text-xl opacity-80 mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p> */}

          <div className="relative mt-8">
            <input
              type="email"
              placeholder="e-mail"
              className="inputTextField bg-black border border-lemon text-lemon placeholder-lemon  py-4"
            />
            <ForwardArrow className="absolute right-6 top-5 cursor-pointer" onClick={() => onButtonEmptyFn()} />
          </div>
          <div className="lg:hidden py-5 text-center">
            <a href="mailto:info@resourcefashion.co">
              <h5 className="font-serif font-medium text-xl">
                Contact Us
              </h5>
            </a>
            <h5
              onKeyPress={() => handleRedirect('/contact-us')}
              role="link"
              className="font-serif font-medium text-xl"
              onClick={() => handleRedirect('/faq')}
            >
              FAQ
            </h5>
            <a target="_blank" href="https://www.blog.resourcefashion.co/" rel="noreferrer">
              <h5 className="font-serif font-medium text-xl">
                Terms of Service
              </h5>
            </a>
            {/* Social Media Links */}
            <div className=" border-t border-black py-4">
              <div className="flex flex-row justify-between">
                <div>
                  {/* links on mobile  */}
                  <a target="_blank" href="https://www.facebook.com/groups/resourcefashion" rel="noreferrer">
                    <Facebook
                      className="cursor-pointer w-10 h-10"
                    />
                  </a>
                </div>
                <div>
                  <a target="_blank" href="https://www.instagram.com/resourcefashion/" rel="noreferrer">
                    <Instagram
                      className="cursor-pointer w-10 h-10"
                    />
                  </a>
                </div>
                <div>
                  <a target="_blank" href="https://www.linkedin.com/company/resourcefashion" rel="noreferrer">
                    <Linkedin
                      className="cursor-pointer w-10 h-10"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <h5 className="hidden md:block text-xl tracking-tighter text-right bottom-0">
            All Rights Reserved
          </h5>

        </div>
      </div>
    </footer>

  );
};

export default Footer;
