const OrderShippingStatus = () => (
  <div className="relative">
    {/* TODO - waiting for the API to manage this */}
    <div className="flex absolute ">
      <div className="w-6  h-6 rounded-full mr-5 bg-purple-400 border border-black" />
      <div className="w-6  h-6 rounded-full mr-5 bg-purple-400 border border-black" />
      <div className="w-6  h-6 rounded-full mr-5 bg-purple-400 border border-black" />
      <div className="w-6  h-6 rounded-full mr-5 bg-purple-400 border border-black" />
      <div className="w-6  h-6 rounded-full mr-5 bg-purple-400 border border-black" />
    </div>
    <div className="w-full bg-purple-400 py-2 border border-black  rounded-full" />

  </div>
);

export default OrderShippingStatus;
