import { updateUser } from 'API/Signup/Signup';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import { useHistory, withRouter } from 'react-router';
import { errorToast, successToast } from 'Util/Utility';
import * as yup from 'yup';

import { apiRoutes } from 'API/APIRoutes';
import { getAPIUrl, Loader } from 'API/APIHelpers';
import useGetFetch from 'hooks/useGetFetch';
import { useEffect, useState } from 'react';

type PropsSignup = {
  history: {
    push(url: string): void;
  };
}

const phoneRegExp = /^[+]\d{1,2}\d{6,10}$/;
/*
-> /^ and $/ is for starting and ending
-> The ? mark is used for conditional formatting where before question mark is available or not it will work
-> ([+]\d{2}) this indicates that the + sign with two digits '\d{2}' here you can place digit as per country
-> after the ? mark '\d{10}' this says that the digits must be 10 of length change as per your country mobile number length
*/
const signupFormScheme = yup.object().shape({
  first_name: yup.string().required('*Required'),
  last_name: yup.string().required('*Required'),
  email: yup.string().email('Please enter valid email').required('*Required'),
  phone_number: yup.string().matches(phoneRegExp, 'Enter a valid phone number (e.g. +12125552368).').required('Required'),
  business_name: yup.string().required('*Required'),
  // Used regex for filtering the valid website
  brand_website: yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Please provide a valid URL.',
  ).required('Required'),
  country: yup.string().required('*Required'),
});
function Settings(props: PropsSignup) {
  const [profile, loading] = useGetFetch(getAPIUrl(apiRoutes.PROFILE), undefined, true);

  const history = useHistory();
  const redirectToNextPage = (pathname: string) => {
    history.push(pathname);
  };

  const isProduction = history.location.pathname.includes('/production');
  const errorMsgTextColor = isProduction ? 'text-red-500 lg:text-black' : 'text-red-500';

  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    business_name: '',
    brand_website: '',
    country: '',
  });

  useEffect(() => {
    if (profile) {
      const userProfile = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        email: profile.email,
        phone_number: profile.phone_number,
        brand_website: profile.brand_website,
        business_name: profile.business_name,
        country: profile.country,
      };
      setProfileData(userProfile);
    }
  }, [profile]);

  const updateExistingUser = async (values: signUpFormType) => {
    const isProduction = history.location.pathname.includes('/production');
    try {
      const response = await updateUser(values);
      history.push(isProduction ? '/production' : '/home');
      successToast('successfully updated !!');
    } catch (e: any) {
      errorToast(e.email && e.email[0]);
      errorToast(e.username && e.username[0]);
      errorToast(e.first_name && e.first_name[0]);
      errorToast(e.last_anme && e.last_name[0]);
      errorToast(e.phone_number && e.phone_number[0]);
      errorToast(e.brand_website && e.brand_website[0]);
      errorToast(e.business_name && e.business_name[0]);
      errorToast(e.country && e.country[0]);
    }
  };

  return (
    <>
      {loading
        ? <Loader />
        : (
          <div className="grid grid-cols-12">

            <div className={`col-span-12 lg:col-span-6 lg:border-2 lg:border-black flex justify-center items-center ${isProduction ? 'lg:bg-orangeShade' : 'lg:bg-purple-shade'}`}>
              <div className="new-container lg:px-24 w-full">
                <h1 className="text-4xl font-serif font-medium pl-4 pt-10">Settings</h1>
                <Formik
                  validationSchema={signupFormScheme}
                  onSubmit={(values) => {
                    updateExistingUser(values);
                  }}
                  initialValues={profileData}
                  enableReinitialize
                >
                  {() => (

                    <Form className="px-4 py-10">
                      <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                        {/* First Name */}
                        <div className="col-span-6">
                          <Field name="first_name" placeholder="first name*" type="text" className="inputTextField" />
                          <ErrorMessage component="p" name="first_name" className={`${errorMsgTextColor} text-xs ml-2`} />
                        </div>
                        {/* Last Name */}
                        <div className="col-span-6">
                          <Field name="last_name" placeholder="last name*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="last_name" className={`${errorMsgTextColor} text-xs ml-2`} />
                        </div>
                        {/* Email */}
                        <div className="col-span-12">
                          <Field name="email" placeholder="email*" type="email" className="inputTextField" />
                          <ErrorMessage component="div" name="email" className={`${errorMsgTextColor} text-xs ml-2`} />
                        </div>
                        {/* Phone Number */}
                        <div className="col-span-12">
                          <Field name="phone_number" placeholder="phone*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="phone_number" className={`${errorMsgTextColor} text-xs ml-2`} />
                        </div>
                        {/* Brand Name */}
                        <div className="col-span-12">
                          <Field name="business_name" placeholder="brand name*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="business_name" className={`${errorMsgTextColor} text-xs ml-2`} />
                        </div>
                        {/* Brand Website */}
                        <div className="col-span-12">
                          <Field name="brand_website" placeholder="brand website*" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="brand_website" className={`${errorMsgTextColor} text-xs ml-2`} />
                        </div>
                        {/* Country */}
                        <div className="col-span-12">
                          <Field name="country" placeholder="country" type="text" className="inputTextField" />
                          <ErrorMessage component="div" name="country" className={`${errorMsgTextColor} text-xs ml-2`} />
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={` mt-4 ${isProduction ? 'smallBlackButton' : 'smallLemonButton'}`}
                        >
                          Update
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default withRouter(Settings);
