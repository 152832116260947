/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import { errorToast, successToast } from 'Util/Utility';

import { getAPIUrl, Loader } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';
import useGetFetch from 'hooks/useGetFetch';

import uploadPhoto from 'API/SearchProduct';
import Compressor from 'compressorjs';

import { ReactComponent as CameraIcon } from 'assets/icons/camera-icon.svg';
import { ReactComponent as StarCamera } from 'assets/icons/star-camera.svg';

import NetworkImage from 'assets/images/network_image.png';
import NetworkImageDesktop from 'assets/images/network_image_desktop.png';
import HomepageHeroImage from 'assets/images/placeholderImages/homepageImage.png';
import ourStoryImage from 'assets/images/placeholderImages/aboutMobile.png';
import ourStoryDesktopImage from 'assets/images/placeholderImages/aboutDesktop.png';

import Carousel from 'Components/atoms/Carousel';
import CircularBtn from 'Components/atoms/CircularBtn';
import H2Heading from 'Components/atoms/H2Heading';
import Tab from 'Components/Tab';

import { Slide } from 'pure-react-carousel';
import { trendSetter } from 'Constants';
import ProductList from './ProductList';

const Homepage: React.FC = () => {
  // use State
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeTabManufacture, setActiveTabManufacture] = useState<number>(0);
  const [id, setId] = useState<number>();
  const [idManufacture, setManufactureId] = useState<number>();
  const [productDataShow, setProductDataShow] = useState<cardInput>();
  const [manufactureDataShow, setManufactureDataShow] = useState<any>();

  // Histroy
  const history = useHistory();

  // APi calls
  const [categoryList, categoryLoading] = useGetFetch(getAPIUrl(`${apiRoutes.CATEGORY}?is_on_home=true`));
  const [styleList, styleLoading] = useGetFetch(getAPIUrl(apiRoutes.STYLE));
  const [filteredProductData, filterProductLoading] = useGetFetch(id ? getAPIUrl(`${apiRoutes.PRODUCT}?category=${id}&tags=best_seller&page_size=10`) : null);
  const [filteredManufactureData, filterManufactureLoading] = useGetFetch(idManufacture ? getAPIUrl(`${apiRoutes.MANUFACTURER}?style=${idManufacture}&tags=best_seller&page_size=10`) : null);

  useEffect(() => {
    // For setting the product id for the first time
    setId(categoryList && categoryList.length !== 0 && categoryList[0].id);
    setManufactureId(styleList && styleList.length !== 0 && styleList[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryLoading, styleLoading]);

  useEffect(() => {
    setProductDataShow(filteredProductData.length > 0 ? filteredProductData : null);
    setManufactureDataShow(filteredManufactureData.length > 0 ? filteredManufactureData : null);
  }, [id, idManufacture, filteredProductData, filteredManufactureData]);

  // Show FilterData on Product List Page
  const showFilteredDataOnProductList = () => {
    history.push({
      pathname: `/product-list/category/${id}/1`,
    });
    window.scrollTo(0, 0);
  };

  const handleRedirectPage = (pathname: string) => {
    history.push(pathname);
  };

  // Highlight the selected Tab Function
  const selectedTabsFn = (condition: boolean) => (condition ? 'bg-lemon py-2 px-4 rounded-full border-2 border-black pt-2.5' : ' pt-3 ');

  // Set the Product Active tab
  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  };
  // Change the ID of Product List Data Shown
  const filterProductData = (id: number) => {
    setId(id);
  };

  // Set the Manufacture Active tab
  const onTabClickManufacture = (tab: number) => {
    setActiveTabManufacture(tab);
  };

  // Change the ID of Manufacture List Data Shown
  const filterManufactureData = (idManufacture: number) => {
    setManufactureId(idManufacture);
  };

  const onFileChange = async (event: any) => {
    const imageFile = event.target.files[0];
    successToast('Please wait your image is uploading...');
    new Compressor(imageFile, {
      quality: 0.6,
      success(result) {
        uploadImage(result);
      },
      error(err) {
        console.log('Compressor Error', err.message);
      },
    });
  };

  const uploadImage = async (selectedFile: any) => {
    try {
      // TODO Show respective error
      const fileUrl = await uploadPhoto(selectedFile);
      history.push(`/product-list/uploadImage/${fileUrl}`);
    } catch (e: any) {
      errorToast(e);
    // history.push("/login");
    }
  };

  // To show the trend setter product list
  const productListOfTrendSetter = (imageUrl: string) => {
    window.scroll(0, 0);
    history.push(`/product-list/uploadImage/${imageUrl}`);
  };

  return (
    <>
      <section className=" bg-smallOval bg-no-repeat bg-center small-oval-homepage md:bg-heroOval md:bg-left-hero-homepage  md:bg-repeat-space">
        <div className="new-container grid grid-cols-12 lg:py-20 pt-20">
          <div className="col-span-12 md:col-span-5">
            <img src={HomepageHeroImage} alt="Resource Homepage" className="mx-auto " />
          </div>
          <div className="col-span-12 md:col-span-6 md:col-start-8 text-center md:text-left py-5  md:mt-16">
            <h2 className="font-serif font-medium lg:font-medium text-3xl lg:text-5xl mt-2 lg:leading-snug">
              Creating any brand takes a village, with RE(SOURCE) you’re not alone
            </h2>
            <p className="mt-4 lg:mt-0 lg:py-10 md:text-xl tracking-tight">
              With a seamless sourcing experience, no minimum order quantity for
              most styles and logo requests, affordable prices and customization,
              RE(SOURCE) makes it easy.
            </p>
            <div className="md:flex">
              <div className="flex w-full items-center justify-center bg-grey-lighter">
                <label
                  className="font-medium font-serif bg-lemon border text-black
                  border-black px-10 pt-4 pb-4  md:ml-4
                  w-full my-5 md:my-0 rounded-full
                  cursor-pointer text-2xl hover:opacity-95"
                >
                  <div className="flex flex-row items-center justify-center text-base leading-normal">
                    <div className="font-serif text-lg text-black">Upload Photo</div>
                    <StarCamera className="w-10 h-10 ml-2 -mt-3 md:-mt-2 " />
                  </div>
                  <input type="file" className="hidden" onChange={onFileChange} />
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h2 className="circularLongButbg-black text-lemon bg-black text-3xl pt-5 pb-4 text-center font-serif">
          Why RE(SOURCE)?
        </h2>
        <div className="grid grid-cols-12 ">

          {/* <div className="col-span-6 order-1 bg-lemon py-5 px-5  md:px-2 border border-black  ">
            <div className="w-3/4 md:flex mx-auto">
              <img src={WhyResourceImage1} alt="Why Resource" />
              <p className=" mt-1 leading-tight md:leading-normal md:ml-4 font-medium md:text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna.
              </p>
            </div>
          </div> */}

          <div className="col-span-6  order-2 md:order-3 py-10 px-5 md:px-2 border border-black ">
            <div className="md:flex ml-2  md:ml-20  ">
              <div className="border-2 font-semibold font-serif border-black
            rounded-full w-16 h-16 flex items-center justify-center text-2xl "
              >
                2
              </div>
              <h4 className="font-serif font-medium text-xl lg:text-3xl lg:w-1/2 md:ml-5 mt-5 md:mt-0 ">
                High Quality, Vetted Sourcing
              </h4>
              {/* <p className="pt-3 font-serif text-base">
                We handpicked 200 of the best manufacturers from 1600 different producers by manually checking over 3000 products.
              </p> */}
            </div>
          </div>

          <div className="col-span-6 order-1 md:order-2 py-10 px-5 md:px-2 border border-black bg-lemon md:bg-transparent ">
            <div className=" md:flex ml-2   md:ml-20 ">
              <div
                className="border-2 font-semibold font-serif border-black
                           rounded-full w-16 h-16 flex items-center justify-center text-2xl"
              >
                1
              </div>
              <h4 className="font-serif font-medium text-xl lg:text-3xl lg:w-1/2 md:ml-5  pr-10 md:pr-0 mt-5 md:mt-0">
                Affordable Manufacturers
              </h4>
              {/* <p className="pt-3 font-serif text-base">
                Sourcing directly from manufacturers cuts out the middleman and saves you more money.
              </p> */}
            </div>
          </div>

          <div className="col-span-6  order-3 md:order-3 py-10 px-5 md:px-2 border border-black ">
            <div className="md:flex ml-2  md:ml-20  ">
              <div className="border-2 font-semibold font-serif border-black
            rounded-full w-16 h-16 flex   items-center justify-center  text-2xl "
              >
                3
              </div>
              <h4 className=" font-serif font-medium text-xl lg:text-3xl lg:w-1/2 md:ml-5 mt-5 md:mt-0  ">
                Private Labeling with Ease
              </h4>
              {/* <p className="pt-3 font-serif text-base">
                Apply a number of different logos to make your unique piece and with no or low minimum order quantity.
              </p> */}
            </div>
          </div>

          <div className="col-span-6 order-4  py-10 px-5 border md:px-2 border-black bg-lemon md:bg-transparent ">
            <div className="md:flex ml-2  md:ml-20 ">
              <div
                className="border-2 font-semibold font-serif border-black
                            rounded-full w-16 h-16 flex items-center justify-center text-2xl "
              >
                4
              </div>
              <h4 className=" font-serif font-medium text-xl lg:text-3xl lg:w-1/2 md:ml-5 mt-5 md:mt-0  ">
                No Minimum Order Quantity
              </h4>
              {/* <p className="pt-3 font-serif text-base">
                Order as much or as little as you desire with most logos and designs with no inventory risk.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {categoryList.length === 0 ? null
        : (
          <section>
            <H2Heading text="Best Sellers" additionalClasses="bg-black" />
            <ul className="flex flex-row pl-6 md:pr-1 pt-3 pb-4
        border-b items-center border-black bg-purple-300 overflow-scroll  "
            >
              {
                categoryList.map((tab: any, index: number) => (
                  <div onClick={() => filterProductData(tab.id)} key={tab.id}>
                    <Tab
                      style={`${selectedTabsFn(activeTab === index)} lg:mx-16`}
                      onTabClick={onTabClick}
                      currentTab={index}
                    >
                      <div className="flex flex-row cursor-pointer">
                        <div className="text-md font-serif font-semibold whitespace-nowrap ">{tab.name}</div>
                      </div>
                    </Tab>
                  </div>

                ))
              }

            </ul>
            {/* Product List */}
            <div className="mt-8  lg:px-36 ">
              {
                productDataShow && filteredProductData.length > 0
                  ? (
                    <ProductList isFilterBoxVisible productShowLimit={6} data={productDataShow} />
                  )
                  : <Loader />
              }
            </div>

            {
              productDataShow && filteredProductData.length > 0
                ? (
                  <div className="px-8 mb-10 flex justify-center">
                    <CircularBtn
                      additionalClasses="text-center py-3 rounded-full font-serif bg-purple-300
                        border-2 border-black px-12 lg:px-20
                        text-2xl font-semibold pt-4 pb-3 shadow-solid-black
                        hover:bg-white"
                      text=" See more"
                      onClick={() => showFilteredDataOnProductList()}
                    />

                  </div>
                ) : null
            }

          </section>
        )}

      <section className="mt-4">
        <H2Heading text="Trend Setters" additionalClasses="bg-black" />
        <div className="bg-purple-300 py-5 border-b border-black overflow-scroll">
          <div className="flex  px-5 ">
            {trendSetter.map((item, index: number) => (
              <div
                key={index}
                className="pl-2 pr-4 cursor-pointer lg:pl-"
                onClick={() => productListOfTrendSetter(item.url)}
              >
                <img
                  src={item.url}
                  alt="Trend Setter"
                  className="shadow rounded-full max-w-full h-auto align-middle
                  border border-black min-h-185px min-w-185px max-h-185px
                  lg:min-h-300px lg:min-w-300px lg:max-h-300px
                  "
                />
                <h5
                  className="font-serif font-medium text-center mt-4"
                >
                  {item.text}
                </h5>
              </div>
            ))}

          </div>
        </div>
      </section>

      <section className="bg-black">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-6 md:order-2">
            <img src={NetworkImage} alt="Networking" className="w-full lg:hidden" />
            <img src={NetworkImageDesktop} alt="Networking" className="w-full hidden lg:block" />
          </div>
          <div className="flex items-center justify-center col-span-12 md:col-span-6">
            <div
              className="text-center md:text-left md:w-3/4 lg:w-2/3
                text-lemon p-5  md:order-1 md:px-14"
            >
              <h2 className="font-semibold font-serif lg:leading-tight  px-2 lg:px-0 text-2xl md:text-4xl lg:text-3xl  py-4">
                RE(SOURCE) with a Pic and a Click!
              </h2>
              <p className="opacity-80">
                Using our reverse image search option, you can upload a picture of
                any style you want and we will search through both our vetted and out-of-network
                marketplaces of over 1 million styles, to find you similar or identical styles and
                pinpoint the right manufacturer for you.
              </p>

              <div className="flex md:block  rounded-full justify-center border text-black border-black bg-lemon items-center mt-5 md:mt-10">
                <label
                  className="font-semibold font-serif
                       my-2 hover:opacity-95 cursor-pointer"
                >
                  <div className="flex flex-row items-center justify-center text-base leading-normal">
                    <div className="font-serif text-2xl text-black">Upload Photo</div>
                    <CameraIcon className="w-8 h-8 ml-2 mt-1 md:-mt-2 " />
                  </div>
                  <input type="file" className="hidden" onChange={onFileChange} />
                </label>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* {styleList.length === 0 ? null
        : (
          <section>
            <div className=" border-b-2 border-lemon" />
            <h1 className="bg-black text-lemon text-3xl pt-5 pb-4 text-center font-serif opacity-95 ">
              Featured Manufactures
            </h1>
            <ul className="flex flex-row pl-6 md:pl-6 pr-1 pt-3
             pb-4 border-b border-black bg-purple-300 overflow-scroll lg:justify-around"
            >
              {
                styleList.map((tab: any, index: number) => (
                  <div onClick={() => filterManufactureData(tab.id)} key={tab.id}>
                    <Tab
                      style={selectedTabsFn(activeTabManufacture === index)}
                      onTabClick={onTabClickManufacture}
                      currentTab={index}
                    >
                      <div className="flex flex-row cursor-pointer">
                        <div className="text-sm font-serif font-semibold whitespace-nowrap ">{tab.name}</div>
                      </div>
                    </Tab>
                  </div>
                ))
              }
            </ul>

            <div className="-mb-2  ">
              <Carousel
                totalSliders={manufactureDataShow && manufactureDataShow.length}
                sliderWidth={150}
                sliderHeight={150}
              >
                {manufactureDataShow && manufactureDataShow.length > 0
                  && manufactureDataShow.map((object: any, index: number) => (
                    <Slide index={index} key={object.id} className="flex justify-center items-start ">
                      <div className=" border-2 border-black px-4 py-4 lg:px-10 lg:py-10 h-full ">
                        <img
                          src={object.brand_logo}
                          alt={object.business_name}
                          className=" w-full h-full object-contain "
                        />

                      </div>
                    </Slide>
                  ))}
              </Carousel>

            </div>
          </section>
        )} */}
      <section>
        <div className=" border-b-2 border-lemon" />
        <H2Heading text="Our Story" additionalClasses="bg-black" />
        <div className="grid grid-cols-12 max-h-full md:bg-smallOvalAbout md:bg-no-repeat small-oval-homepage-desktop">

          <div className="col-span-12  md:col-start-2 md:col-end-6 md:order-1 flex items-center justify-center">
            <div className=" p-5 text-center md:text-left  ">
              <h3 className="px-5 text-3xl font-serif font-semibold">
                From Two Family’s Debut, to the Unstoppable Team!
              </h3>
              <p className="p-5 font-serif text-base">
                RE(SOURCE) is an intergenerational dream come true inspired by
                the hard work of Victoria Wu; and headed by Ziqi Deng, her daughter,
                alongside Tatiana Tian. The two Duke graduates, together with a great team, made
                it their mission to simplify the relationship between
                apparel manufacturers and brand owners.
              </p>
              <div className="md:px-5 flex justify-center items-center md:block">
                <CircularBtn
                  text="More about us"
                  additionalClasses="circularLongButton bg-purple-300 hover:bg-purple-400 font-semibold
                    border border-black mt-2 pl-8 pr-6 text-xl"
                  onClick={() => handleRedirectPage('/about-us')}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12 md:hidden ">
            <img src={ourStoryImage} alt="Our Story Resource" className="w-full mt-5" />
          </div>
          <div className="hidden md:block md:col-start-7 md:col-end-12 md:order-2  ">
            <img src={ourStoryDesktopImage} alt="Our Story Resource" className="w-full mt-5" />
          </div>
        </div>

      </section>

    </>
  );
};

export default withRouter(Homepage);
