import React, { createContext, useContext, useReducer } from 'react';
import { cartReducer } from './reducer';

// Intialise the Context API object

const CartStateContext = createContext<any | undefined>([]);
const CartStateDispatchContext = createContext<any | undefined>([]);

// Add cart type here
const initialState: any[] = [];

export const CartProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartStateContext.Provider value={state}>
      <CartStateDispatchContext.Provider value={dispatch}>
        {children}
      </CartStateDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};

export function useCartState() {
  const context = useContext(CartStateContext);

  if (context === undefined) {
    throw new Error('Undefined State');
  }

  return context;
}

export function useCartDispatch() {
  const context = useContext(CartStateDispatchContext);

  if (context === undefined) {
    throw new Error('Undefined Dispatch');
  }

  return context;
}
