/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { withRouter } from 'react-router';

import Accordion from 'Components/Accordian';

import HeroImage from 'assets/images/placeholderImages/how_it_work_hero.png';
import FirstTabImage from 'assets/images/placeholderImages/first_tab_howitwork.png';
import SecondTabImage from 'assets/images/placeholderImages/second_tab_howitwork.png';

const HowItWorks: React.FC = () => {
  const [accordian, setAccordian] = useState<number>(1);

  return (
    <div>

      <section className="flex relative justify-between items-center">
        <img
          src={HeroImage}
          alt="how it work resource fashion"
          className="h-96 lg:h-screen w-screen object-cover "
        />
        <div className="absolute lg:w-3/4 pl-5 lg:pl-20">
          <h1 className="text-white text-4xl lg:text-6xl font-serif">
            Thorough quality checks from beginning to end.
          </h1>
          <span className="bg-lemon text-2xl px-1 mt-4">
            Explore our processes below
          </span>
        </div>
      </section>

      <section>

        <div onClick={() => setAccordian(1)}>
          <Accordion
            headingClass="bg-purple-shade py-5 px-5 font-semibold border-2 border-black"
            heading="RE(SOURCE) Manufacturers 101"
            h1Class="text-2xl text-center mx-auto"
            preOpen={accordian === 1}
          >
            <div className="grid grid-cols-12 bg-purple-shade border-black border-b-2 border-l-2 border-r-2">
              <div className="col-span-12 md:col-span-6 lg:col-span-6">
                <img src={FirstTabImage} alt="Resourece Manufacturers 101" />
              </div>
              <div className="p-10 font-medium font-serif col-span-12 md:col-span-6 lg:col-span-6">
                <p>
                  At RE(SOURCE), there are two types of manufacturers: vetted and out-of-network.
                  The main differences between the two are quality control, selection,
                  and the order process.
                  Vetted manufacturers have a much higher chance of providing better quality products
                  because our RE(SOURCE) team has manually checked over 3000 products from 1600 different
                  manufacturers and handpicked the 200 best manufacturers based on 2 product samples taken
                  at random from each producer. Our vetted producers provide 10k products while our
                  out-of-network producers provide over 5M style selections from 100k
                  network manufacturers.
                </p>
                <br />
                <p>
                  If you place an order from our vetted manufacturers,
                  they will be labeled under the Prime option on the website.
                  The order process will be similar to checking out of any other
                  e-commerce site: selecting the color, size, and quantity, adding to the
                  cart, and checking out. The order process for out-of-network manufacturers
                  varies slightly in that you must use the search image function to find
                  your ideal style selection. We will search through both our vetted and
                  out-of-network marketplaces of over 5M styles to select the most similar
                  style and the respective manufacturer to connect you with. Our team will
                  manually upload the product after 24 hours in the “My Requests” section
                  of the “My Account” section of our website in addition to an email sent
                  directly to you with the product details.  If you feel satisfied with
                  the product details you received you can move forward and select the
                  appropriate color, size, and quantity and check out.
                </p>
              </div>
            </div>
          </Accordion>
        </div>

        <div onClick={() => setAccordian(2)}>
          <Accordion
            headingClass="bg-lemon py-5 px-5 font-semibold border-l-2 border-r-2 border-black"
            heading="Customize & Legitimize"
            h1Class="text-2xl text-center mx-auto"
            preOpen={accordian === 2}
          >
            <div className="grid grid-cols-12 bg-lemon border-black border-t-2 border-l-2 border-r-2">
              <div className="col-span-12 md:col-span-6 lg:col-span-6 lg:order-2">
                <img src={SecondTabImage} alt="Resourece Manufacturers 101" />
              </div>
              <div className="p-10 font-medium font-serif col-span-12 md:col-span-6 lg:col-span-6 lg:order-1">
                <p>
                  Create your unique brand logos, designs, and signature symbol
                  with RE(SOURCE)! We provide a range of logo customization ranging
                  from heat transfer print, knit neck label, rubber label, embroidery,
                  and so much more! Although we are in the process of completely
                  automating our customization process, until then you can place
                  your logo order through our varied logo product links under the
                  customization tab on the product detail page. Once you have placed
                  the order for your logo we will contact you within 24 hours
                  requesting how you would like us to apply the logo onto the products ordered.
                </p>
                <br />
                <p>
                  If you would like to order the logo separate from any other product
                  that can be done with ease, just place your order on the website and
                  we will reach out to you about the design details within 24 hours.
                  If you have any questions or concerns, please do not hesitate to
                  contact us via Instagram  @resourcefashion or via email at
                  <a className="text-blue-600" href="mailto:ziqi@resourcefashion.co"> ziqi@resourcefashion.co</a>

                </p>
              </div>
            </div>
          </Accordion>
        </div>

        <div onClick={() => setAccordian(3)}>
          <Accordion
            headingClass="bg-sand py-5 px-5 font-semibold border-2 border-black"
            heading="Double Check before Dispatch"
            h1Class="text-2xl text-center mx-auto"
            preOpen={accordian === 3}
          >

            <div className="grid grid-cols-12 bg-sand border-black border-b-2 border-l-2 border-r-2">
              <div className="col-span-12 md:col-span-6 lg:col-span-6">
                <img src={FirstTabImage} alt="Resourece Manufacturers 101" />
              </div>
              <div className="p-10 font-medium font-serif col-span-12 md:col-span-6 lg:col-span-6">
                <p>
                  After your order is placed, manufacturers will go forward
                  and ship the products to our local office for a quality check.
                  This check consists of our RE(SOURCE) team taking photo and video
                  testimony of the products ordered in order to double check with your
                  satisfaction over your purchase. If you do not like the clothes based
                  on the pictures and videos, you can return them prior to shipment at
                  no additional charge and receive a full refund. If you are satisfied
                  with your order, then all that is left to do is confirm your approval
                  and the order will be shipped within 24 hours of approval. If you do
                  not confirm approval or disapproval of the products within 24 hours
                  then we will assume by default that you approve of the products and
                  they will be sent out within 24 hours of the quality check message’s delivery.
                </p>
              </div>
            </div>

          </Accordion>
        </div>

      </section>
    </div>
  );
};

export default withRouter(HowItWorks);
