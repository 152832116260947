import React from 'react';
import Globe from '../../assets/icons/globe.png';
import PlottedPlant from '../../assets/icons/potted-plant.png';

type TagInput = {
  tagName: string;
  style?: string;
};

const Tag: React.FC<TagInput> = (props) => {
  const { tagName, style } = props;
  return (
    <div className='flex flex-wrap'>
      <div className={`flex flex-row items-center mt-1 border whitespace-nowrap border-black rounded-2xl bg-lemon ${style ? 'px-2' : 'px-1'} py-1`}>
        <img src={tagName === 'Ethical trade' ? Globe : PlottedPlant} alt={tagName} />
        <div className={`ml-0.5 ${style ? style : 'text-3xs'}`}>
          {tagName}
        </div>
      </div>
    </div>
  );
};

export default Tag;
