import React from 'react';
import ProductList from './ProductList';

type listInput = {
  listData: Card[];
};
const WishList: React.FC<listInput> = () => (
  <ProductList />
);

export default WishList;
