import React from 'react';
import { isAuthenticated } from 'API/APIHelpers';

import { useHistory } from 'react-router';
import { errorToast } from 'Util/Utility';
import PrimeTag from './atoms/PrimeTag';
import Tags from './Tags';

import { ReactComponent as Heart } from '../assets/icons/heart.svg';

const Card: React.FC<cardInput> = (props) => {
  const {
    image,
    productPrice,
    productName,
    location,
    manufacturerName,
    minQuantity,
    type,
    status,
    ethicalProduction,
    sustainableSourcing,
    priceRange,
    is_prime,
    productType,
    productId,
  } = props;

  const isUserAuthenticated = isAuthenticated();

  const history = useHistory();

  const handleRedirectPage = (pathname:string | any) => {
    if (pathname) {
      history.push(`product/${pathname}`);
    } else {
      errorToast('This product is not avaible right now');
    }
  };

  return (
    <div>
      <div className={`my-1  ${status === 'pending' ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
        <div className="relative">
          <img
            src={image}
            alt={productName}
            className="border-black border-2 w-full object-contain bg-center
             max-h-150px min-w-150px max-w-150 min-h-150px
             md:max-h-300px md:min-h-300px md:max-w-300px
             "
          />
          {/* commented for future use */}
          {/* <div className='absolute top-2 right-2 border border-black rounded-full p-2 bg-white'>
          <Heart />
        </div> */}

          {
           status === 'complete' && (
           <button
             onClick={() => handleRedirectPage(productId)}
             type="button"
             className="flex items-center border border-black rounded-full absolute bottom-3 left-1
                           text-xxs mx-auto bg-lemon hover:opacity-80  pt-3 pb-3 px-5 font-serif font-medium whitespace-nowrap"
           >
             View
             product listing
           </button>
           )
}

        </div>
        <div className="mt-3">
          {type === 'manufacturer' && (
            <div>
              <h2 className="text-sm font-semibold">{manufacturerName}</h2>
              {location && <p className="text-gray-500 text-xs">{location}</p>}
              <p className="text-gray-500 text-xs">{priceRange}</p>
              <Tags
                ethicalProduction={ethicalProduction}
                sustainableSourcing={sustainableSourcing}
              />
            </div>
          )}
          {(type === 'product' || type === 'wishlist') && (
            <div>
              <h2 className="text-sm font-semibold font-serif max-w-300px">{productName}</h2>
              <p className="text-gray-500 text-sm">{manufacturerName}</p>
              {minQuantity && <p className="text-gray-500 text-xs">{`Minimum ${minQuantity} ${minQuantity > 1 ? 'pieces' : 'piece'}`}</p>}
              {isUserAuthenticated
                && productPrice && (
                <p className="text-sm font-semibold font-serif mb-2">
                  $
                  {`${productPrice}`}
                </p>
              )}
              {is_prime && <PrimeTag />}

              {/* commented for future use */}
              {/* <button
              onClick={handleAddToCart}
              className='className="border-3xl border bg-white
              hover:bg-gray-400 text-center py-3 rounded-3xl px-10 w-full font-serif border-black'
            >
              Add To Cart
            </button> */}
            </div>
          )}
          {type === 'requestlist' && (
            <div>
              <span
                className={`mt-1 text-3xs border whitespace-nowrap border-black rounded-2xl ${status === 'pending' ? 'bg-yellow-300' : 'bg-parrot'
                } px-1.5 py-1`}
              >
                {status}
              </span>
              {location && <p className="text-gray-500 text-xs mt-1">{location}</p>}
              {minQuantity && <p className="text-gray-500 text-xs">{`Minimum ${minQuantity} ${minQuantity > 1 ? 'pieces' : 'piece'}`}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;

// TODO - IMPLEMENT SKELTON IN CARDS
// <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
//   <div className="animate-pulse flex space-x-4">
//     <div className="rounded-full bg-blue-400 h-12 w-12"></div>
//     <div className="flex-1 space-y-4 py-1">
//       <div className="h-4 bg-blue-400 rounded w-3/4"></div>
//       <div className="space-y-2">
//         <div className="h-4 bg-blue-400 rounded"></div>
//         <div className="h-4 bg-blue-400 rounded w-5/6"></div>
//       </div>
//     </div>
//   </div>
// </div>
