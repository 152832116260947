import pricingimage from "assets/images/pricingimage.png";
import tshirt from "assets/images/tshirt.png";
import Enterprise from "assets/images/Enterprise.png";
import check from "assets/images/check.png";
import checkWhite from "assets/images/checkWhite.png";
import { pricingStyles, selectQuantity, talkToSales } from "Constants";
import { useHistory } from "react-router";
import { useState } from "react";


const Pricing = () => {
  const [pricingStyle, setPricingStyle] = useState<number>();
  const [pricingDetails, setPricingDetails] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number[]>([]);
  const [techniques, setTechniques] = useState<any>();
  const handleTalkToSales = () => {
    talkToSales();
  }

  const history = useHistory();
  const handleOneWeekTrial = () => {
    history.push('/production/signup');
    window.scrollTo(0, 0);
  }

  const handlePricingStyle = (value: number) => {
    setPricingStyle(value);
    setQuantity(selectQuantity[value]['quantities']);
    setPricingDetails(false);
    setTechniques([]);
  }

  const handleQuantityChange = (e: any) => {
    const pricing = pricingStyle ? pricingStyle : 0;
    setPricingDetails(true);
    setTechniques(selectQuantity[pricing]['techniques'][0][e.target.value]);
  }

  const twoTwentyDays = '<220';
  return (
    <>
      <h1 id="responsive-text"
        className="pricing-img text-center w-full h-full block p-20 text-5xl font-white font-semibold font-FahkwangRegular font-semibold"
        style={{
          backgroundImage: `url(${pricingimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#F8F5EB",
        }}
      >
        Find The Best Plan For your Brand
      </h1>
      <section>
        <div className="container mx-auto p-6 grid grid-cols-1 gap-4 md:grid-cols-2 my-10">
          <div className="col-span-1 flex flex-col bg-white border-2 border-black rounded-lg p-4">
            <div className="flex">
              <img className="h-8 w-8" src={tshirt} alt="tshirt" />
              <h1 className="text-4xl font font-FahkwangRegular ml-1.5 font-semibold">Starter</h1>
            </div>
            <p className=" font-helvetica italic font-normal pt-6">
              Perfect for small businesses and solo entrepreneurs getting started with clothing manufacturers. No strings attached. Subscribe, pause and cancel anytime.
            </p>
            <div className="plan-container">
              <p>
                <span className="font-FahkwangRegular mr-1.5 font-bold fc-orange pricing-text">
                  $249
                </span>
                per month
              </p>
              <p className="text-indent">
                <span className="font-FahkwangRegular mr-1.5 font-bold fc-orange pricing-text">
                  +$50
                </span>
                per sample *
              </p>
            </div>
            <h6 className="pt-7 font-helvetica">INCLUDES</h6>
            <div className="pt-5 font-helvetica ml-5 list-items">
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">Access to our no MOQ (minimum order quantity), fast, and quality clothing supply chain.</div>
              </div>
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">Dedicated account manager from overseas to answer any production questions and get on virtual fitting calls.</div>
              </div>
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">Access to our fabric library (1000+ available fabric options) and unlimited rounds of custom fabric sourcing.</div>
              </div>
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">15% (10% lower than industry standard) bulk production markup and full pricing transparency.</div>
              </div>
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">Access to weekly product Q&A calls with the founder.</div>
              </div>
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">Custom packaging and tags solutions.</div>
              </div>
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">
                  Discounted fast air shipping (7 days) and affordable sea shipping (
                  {twoTwentyDays}
                  lbs , 20 days).
                </div>
              </div>
              <div className="flex my-1.5">
                <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                <div className="p-l-12">Up to 10 styles in sampling / production at a given time.</div>
              </div>
              <div className="flex">
                <div className="p-l-12">
                  *$50/ piece of sample does not include fabric,trim and
                  shipping and is our standard pricing for most daily
                  wears. In situations where the styles are more complicated,
                  we will quote case by case.
                </div>
              </div>
              <div className="flex flex-wrap mt-auto pt-3 text-xs">
                <button className="bg-black text-white font-medium block text-center text-xs m-auto mt-2 rounded-full p-1.5  mb-3 w-48 h-11" onClick={handleOneWeekTrial}>
                  Get 1-week Trial
                </button>
              </div>
            </div>
          </div>

          <div className="col-span-1 flex flex-col bg-white border-2 rounded-lg border-black p-4">
            <div className="flex">
              <img className="h-8 w-8" src={Enterprise} alt="tshirt" />
              <h1 className="text-4xl font font-FahkwangRegular ml-1.5 font-semibold">Enterprise</h1>
            </div>
            <p className="italic font-helvetica pt-6">
              Advanced options for large teams with complex projects.
            </p>
            <div className="plan-container">
              <p>
                <span className="font-bold pricing-text">Let's Talk</span>
              </p>
              <p>
                <span className="font-bold pricing-text">Get A Quote</span>
              </p>
            </div>
            <div className="mb-4 flex flex-wrap">
              <h6 className="pt-7 font-helvetica">INCLUDES</h6>
              <div className="pt-5 font-helvetica ml-5 list-items">
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">Access to our flexible, fast, and quality clothing supply chain along with inventory management.</div>
                </div>
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">Dedicated account team from overseas to answer any production questions and get on virtual fitting calls.</div>
                </div>
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">Unlimited rounds of custom fabric sourcing and options to build your brands’ own fabric booklet.</div>
                </div>
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">15% (10% lower than industry standard) bulk production markup and full pricing transparency.</div>
                </div>
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">Options to schedule calls with the founder when needed.</div>
                </div>
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">Custom packaging and tags solutions.</div>
                </div>
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">
                    Discounted fast air shipping (7 days) and affordable sea shipping (
                    {twoTwentyDays}
                    lbs , 20 days).
                  </div>
                </div>
                <div className="flex my-1.5">
                  <img className="h-3 w-3 m-t-5" src={check} alt="check" />
                  <div className="p-l-12">We will arrange resources based on brands’ needs and scale, with options to build pilot digital tools along with brands.</div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-auto pt-3 text-xs">
              <button className="bg-black text-white font-medium block text-center text-xs m-auto mt-2 rounded-full p-1.5  mb-3 w-48 h-11" onClick={handleTalkToSales}>
                Talk To Sales
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="px-4 bg-black text-white flex-grow flex flex-col items-center pb-12">
        <div className="w-100 md:w-7/12">
          <h1 className="pt-12 text-4xl font-FahkwangRegular text-center font-normal">
            What does monthly subscription entail?
          </h1>
          <div className="pt-5 flex justify-center font-helvetica ml-5 mr-5 list-items">
            <div className="flex flex-col justify-center">
              <div className="flex my-2.5">
                <img className="h-3 w-3 m-t-5" src={checkWhite} alt="check" />
                <div className="p-l-45">Think of it as Amazon Prime membership. We are building an infrastructure that allows individual brands to have access to a digital-first, efficient and flexible supply chain that  big brands traditionally allocate large resources and finances to do.</div>
              </div>
              <div className="flex my-2.5">
                <img className="h-3 w-3 m-t-5" src={checkWhite} alt="check" />
                <div className="p-l-45">Agencies and traditional clothing manufacturers make profit in bulk production, and thus selectively work with big brands. Membership model allows us to work with brands of all sizes regardless of production quantity.</div>
              </div>
              <div className="flex my-2.5">
                <img className="h-3 w-3 m-t-5" src={checkWhite} alt="check" />
                <div className="p-l-45">Ultimately, we built a supply chain and make it rentable and shareable among our member brands. The capacity of our supply chain is reserved for our subscribed members only.</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="bg-orangeShade w-full flex justify-center items-center h-auto py-14">
        <div className="w-4/5 md:w-3/5 lg:w-2/5">
          <h1 className="font-semibold text-black text-center text-4xl font-FahkwangRegular">
            Sample Bulk Quotes
          </h1>
          <div className="mt-2.5">
            <span className="text-center font-helvetica font-normal">
              Bulk production quotes will be available after samples are produced by us and shipped to you for approval. Our bulk pricing is fully transparent, affordable and we work with brands to lower price (by switching fabric / techniques / facories) in certain cases. The below pricing is only used as a reference as material and labor cost constantlty changes in the market.
            </span>
          </div>
          <div className="mt-2">
            <span className="font-bold">Styles</span>
          </div>
          <div className="flex justify-between my-2">
            {pricingStyles.map((item, i) => (
              <div key={i} className={`image-box flex flex-col justify-center items-center bg-white cursor-pointer`} onClick={() => handlePricingStyle(i)}>
                <img className={`image-box object-cover border-2 ${pricingStyle === i ? "border-4 border-violet" : "border-black"}`} src={item.styleImage} alt="tshirt" />
              </div>
            ))}
          </div>
          <select
            onChange={(e) => handleQuantityChange(e)}
            className="block border-2 border-black border-radius-20 w-full mx-auto my-7"
            defaultValue="Select Quantity"
          >
            <option selected={!pricingDetails} disabled>Select Quantity</option>
            {quantity.map((item) => (
              <option>{item}</option>
            ))}
          </select>
          {pricingDetails && (
            <div>
              <div className="flex justify-center mt-9">
                <div className="w-full border-t-2 border-black px-auto"></div>
              </div>
              {/* {techniques} */}
              <div className="flex justify-start">
                <div className="w-100">
                  <div className="quotes-container">
                    <div className="flex">
                      <div className="w-100 font-bold mt-4">
                        <span className="font-helvetica">QUOTES</span>
                      </div>
                    </div>
                    <div className="flex mt-2">
                      <div className="w-3/6">
                        <span className="font-helvetica">Total production cost</span>
                      </div>
                      <div className="w-3/6 font-bold text-right">
                        <span className="font-helvetica text-xl">{techniques.total_production}</span>
                      </div>
                    </div>
                    <div className="flex mt-2">
                      <div className="w-3/6">
                        <span className="font-helvetica">Unit Price</span>
                      </div>
                      <div className="w-3/6 font-bold text-right">
                        <span className="font-helvetica text-xl">{techniques.unit_price}</span>
                      </div>
                    </div>
                  </div>
                  <div className="quotes-container">
                    <div className="flex">
                      <div className="w-100 font-bold mt-4">
                        <span className="font-helvetica">Cost breakdown per unit</span>
                      </div>
                    </div>
                    {techniques.cost_breakdown && Object.keys(techniques.cost_breakdown).map((item) => (
                      <div className="flex mt-2">
                        <div className="w-3/6">
                          <span className="font-helvetica text-sm">{item === 'cut_sew' ? 'Cut and sew' : item}</span>
                        </div>
                        <div className="w-3/6 font-bold text-right">
                          <span className="font-helvetica text-sm">{techniques.cost_breakdown[item]}</span>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                  <div className="mt-2">
                    <span className="font-helvetica italic text-sm">*Note: Shipping price is not estimated as it is highly dependent on the weight of the product, the method of shipping and whether brands decide to ship along with other products. We have strong partnership with freight forwarders and provide highly discounted price for our member brands</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
// eslint-disable-next-line eol-last
export default Pricing;
