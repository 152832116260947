import { withRouter, useHistory } from 'react-router';
import { errorToast, successToast } from 'Util/Utility';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import { resetPassword } from 'API/Login';
import { ResetPasswordSchema } from 'validations';

const ResetPassword: React.FC = () => {
  const history = useHistory();

  const initialValues: LoginForm = {
    email: '',
  };
  const handleResetPassword = async (values: LoginForm) => {
    try {
      const response = await resetPassword(values);
      history.push('/login');
      successToast('You should receive an email to reset your password in the email');
    } catch (e: any) {
      errorToast(e);
    }
  };
  return (
    <div className="new-container lg:px-96 lg:min-h-90screen flex justify-center items-center">
      <Formik
        validationSchema={ResetPasswordSchema}
        onSubmit={handleResetPassword}
        initialValues={initialValues}
      >
        {() => (
          <div className="w-100">
            <h1 className="text-4xl font-serif font-medium pl-4 pt-10 ">Reset Password</h1>
            <Form className="px-4 py-60 lg:py-0">
              <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                {/* Email */}
                <div className="col-span-12">
                  <Field name="email" placeholder="email*" type="email" className="inputTextField" />
                  <ErrorMessage component="p" name="email" className="text-red-500 text-xs ml-2" />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="smallLemonButton mt-4"
                >
                  Reset
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(ResetPassword);
