import React from 'react';
import { Link } from 'react-router-dom';

type NavLinkType = {
  linkName: string;
  linkURL: string;
  additionalClasses?: string;
};

const NavbarLink: React.FC<NavLinkType> = (props) => {
  const {
    linkName, linkURL, additionalClasses,
  } = props;
  return (
    <Link to={linkURL} className={`block text-lg font-serif mb-2 hover:underline active:border-none focus-visible:outline-none visited:border-none checked:border-none ${additionalClasses}`}>
      {linkName}
    </Link>
  );
};

export default NavbarLink;
