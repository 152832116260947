import { getAPIUrl, getAuthHeaders } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';

export default async function RequestProduct(payload: any) {
  try {
    const headers = getAuthHeaders();
    const res = await fetch(getAPIUrl(apiRoutes.REQUEST), {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject('Failed to Request');
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function RequestProductSizeChart(payload: any) {
  try {
    const headers = getAuthHeaders();
    const res = await fetch(getAPIUrl(apiRoutes.SIZE_CHART_REQUEST), {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject('Failed to Request');
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}
