import { Switch } from '@headlessui/react';

type ToggleButtonTypes={
    enabled:boolean,
    setEnabled: (value: boolean) => void;
}

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ');
}

export default function ToggleButton(props:ToggleButtonTypes) {
  const { enabled, setEnabled } = props;

  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className="flex-shrink-0 group relative rounded-full inline-flex items-center
      justify-center h-5 w-10 cursor-pointer focus:outline-none"
    >
      <span aria-hidden="true" className="pointer-events-none absolute  w-full h-full rounded-md" />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'bg-lemon' : 'bg-gray-200',
          'pointer-events-none absolute rounded-full ease-in-out duration-200  h-4 w-9 mx-auto  transition-colors border border-black ',
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5 bg-lemon' : 'translate-x-0 bg-white ',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-black rounded-full  shadow transform ring-0 transition-transform ease-in-out duration-200',
        )}
      />
    </Switch>
  );
}
