/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { HashRouter as Router, Link, withRouter } from 'react-router-dom';
import Footer from 'Components/Footer';
import Navbar from 'Components/Navbar';
import ProductList from 'pages/ProductList';
import 'main.css';
import Homepage from 'pages/Homepage';
import Pricing from 'pages/Pricing';
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import Checkout from 'pages/Checkout';
import WishList from 'pages/WishList';
import RequestList from 'pages/RequestList';
import { CartProvider } from 'Context/Cart';
import OrderHistory from 'pages/OrderHistory';
import ResetPassword from 'pages/ResetPassword';
import ManufacturerList from 'pages/ManufacturerList';
import Faq from 'pages/Faq';
import About from 'pages/About';
import PrivateRoute from 'Components/Routing/PrivateRoute';
import Cart from 'pages/Cart';
import StripeIntegration from 'pages/Stripe/StripeIntegration';
import Thankyou from 'pages/Thankyou';
import DesktopLaunch from 'assets/images/placeholderImages/desktop_launch.png';
import ProductDetail from 'pages/ProductDetailPage';
import ManufacturerDetail from 'pages/ManufacturerDetail';
import Reset from 'pages/Reset';
import Settings from 'pages/Settings';
import ReviewSection from 'pages/Review';
import PilotProgram from 'pages/PilotProgram';
import HowItWorks from 'pages/HowItWorks';
import ProductionHowItWorks from 'pages/ProductionHowItWorks';
import ProductionAbout from 'pages/ProductionAbout';
import ProductionFaq from 'pages/ProductionFaq';
import ProductionNavbar from 'Components/ProductionNavbar';
import ProductionOnboardingPage from 'pages/ProductionOnboarding';
import ProductionFooter from 'Components/ProductionFooter';

type GlobalProvidersProps = {
  children: React.ReactNode;
};

// To add a new provider, just include it in the below list.
// Providers from the top of the list can be accessed by their followers
const providers = [CartProvider];

function GlobalProviders(props: GlobalProvidersProps) {
  const { children } = props;

  const content = providers.reduceRight((acc, Provider) => <Provider>{acc}</Provider>, children);

  return <>{content}</>;
}

const App: React.FC = () => (
  <>
    <Router>
      <Main />
    </Router>
  </>
);

const Main = withRouter(({ location }) => (
  <div>
    <GlobalProviders>
      {/* <div className="flex">
        <div className={`text-center py-2 bg-purple-shade flex-grow  ${location.pathname.includes('/production') ? '' : ' border-2 border-purple-700'}`}>
          <Link
            to="/home"
            className={` font-FahkwangRegular text-xs md:text-xl   cursor-pointer hover:underline top-2 ${location.pathname.includes('/production') ? 'text-gray-700' : ' font-semibold'}`}
          >
            MARKETPLACE
          </Link>
        </div>
        <div className={`text-center py-2  bg-seaGreen flex-grow  ${location.pathname.includes('/production') ? ' border-2 border-green-700' : ' '}`}>
          <Link
            to="/production"
            className={`font-FahkwangRegular text-xs md:text-xl  cursor-pointer hover:underline top-2 ${location.pathname.includes('/production') ? 'font-semibold' : 'text-gray-700 '}`}
          >
            PILOT PROGRAM
          </Link>
        </div>
      </div> */}
      <ProductionNavbar />
      <Switch>
        <div className="bg-sand">
          <Route exact path="/" render={() => <Redirect to="/production" />} />
          <Route exact path="/production/home" render={() => <Redirect to="/production" />} />
          <Route exact path="/home" render={() => <Redirect to="/production" />} />

          <Route exact path="/production" component={ProductionOnboardingPage} />
          <Route exact path="/production/home" component={ProductionOnboardingPage} />
          <Route exact path="/production/login" component={Login} />
          <Route exact path="/home" component={Homepage} />
          <Route exact path="/login" component={Login} />
          <Route path="/production/forgot" component={ResetPassword} />
          <Route path="/reset/:uid/:token" component={Reset} />
          <Route exact path="/product-list" component={ProductList} />
          <Route path="/product-list/tags/:tags/:page" component={ProductList} />
          <Route path="/product-list/category/:categoryid/:page" component={ProductList} />
          <Route path="/product-list/style/:styleid/:page" component={ProductList} />
          <Route path="/product-list/uploadImage/:fileUrl" component={ProductList} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/production/signup" component={SignUp} />
          <Route path="/manufacturer/:manufacturerId" component={ManufacturerDetail} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/how-it-work" component={HowItWorks} />
          <Route path="/production/reviewsection/" component={ReviewSection} />
          <Route path="/about-us" component={About} />
          <Route exact path="/production/faq" component={ProductionFaq} />
          <Route exact path="/production/how-it-works" component={ProductionHowItWorks} />
          <Route path="/production/about-us" component={ProductionAbout} />
          <Route path="/manufacturerList/" component={ManufacturerList} />
          <Route path="/thankyou/" component={Thankyou} />
          <Route path="/production/pricing/" component={Pricing} />

          <PrivateRoute exact path="/production/settings" component={Settings} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute path="/cart" component={Cart} />
          <PrivateRoute path="/stripe" component={StripeIntegration} />
          <PrivateRoute path="/product/:id" component={ProductDetail} />
          <PrivateRoute path="/checkout" component={Checkout} />
          <PrivateRoute path="/wishlist/" component={WishList} />
          <PrivateRoute path="/requestlist/" component={RequestList} />
          <PrivateRoute path="/order-history/" component={OrderHistory} />
          <PrivateRoute exact path="/pilotProgram" component={PilotProgram} />
          <PrivateRoute exact path="/production/pilotProgram" component={PilotProgram} />
        </div>
      </Switch>
      {location.pathname.includes('/production') ? <ProductionFooter /> : <Footer />}
    </GlobalProviders>
  </div>
));

export default App;
