import React, { useEffect, useState } from 'react';
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  Slide,
  DotGroup,
  Dot,
  ButtonFirst,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

type imageCarouselInput = {
  images: imagesData[];
  selectedImageId?: number;
  selectedColorId?: any
};

const ImageCarousel: React.FC<imageCarouselInput> = (props) => {
  const { images, selectedColorId } = props;
  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={150}
        naturalSlideHeight={200}
        totalSlides={images.length}
        interval={5000}
        playDirection="backward"
        // eslint-disable-next-line react/jsx-boolean-value
        infinite={true}
        currentSlide={0}
      // currentSlide={currentSlide}

      >
        <Slider>
          {images.map((data: any, index: number) => (
            <Slide index={index} key={data.id}>
              <div>
                <img
                  src={data.image || data.file}
                  alt={data.product}
                  className="w-full h-full max-h-90screen  min-h-full object-center my-auto object-contain "
                />
              </div>
            </Slide>
          ))}
        </Slider>
        {/* TODO - if you want to show Next and previous button in the image scroller
        **remove hidden class */}
        <div className="hidden">
          <div className="absolute  left-0 bg-black bg-opacity-20">
            <ButtonBack>Back</ButtonBack>
          </div>
          <div className="absolute  right-0 bg-black bg-opacity-20">
            <ButtonNext>Next</ButtonNext>
          </div>
        </div>
        <div className="flex items-center justify-center ">
          <DotGroup>
            <Dot slide={images.length - 1} />
          </DotGroup>
        </div>
        {/* <DotGroup className='text-center carousel__dot carousel__dot--selected' /> */}
      </CarouselProvider>
    </div>
  );
};

export default ImageCarousel;
