/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import { apiRoutes } from 'API/APIRoutes';
import { getAPIUrl, Loader } from 'API/APIHelpers';
import useGetFetch from 'hooks/useGetFetch';

import ProductQuantity from 'Components/ProductQuantity';
import SizeChartModal from 'Components/SizeChartModal';
import Breadcrumbs from 'Components/BreadCrumbs';
import ImageCarousel from 'Components/ImageCarousel';
import Tags from 'Components/Tags';
import Accordion from 'Components/Accordian';

import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import SizeChartRuler from 'assets/icons/size-chart-ruler.png';
import CustomImage1 from 'assets/images/placeholderImages/custom_image_1.png';
import CustomImage2 from 'assets/images/placeholderImages/custom_image_2.png';
import CustomBar from 'assets/images/placeholderImages/custom_bar.png';

import { defaultImgArr, manufacturerDefaultImg } from 'Constants';
import { errorToast, successToast } from 'Util/Utility';
import RequestSizeChartModal from 'Components/RequestSizeChartModal';
import { RequestProductSizeChart } from 'API/RequestProduct';
import ProductImageCarouselModal from 'Components/ProductImageCarouselModal';

const ProductDetail: React.FC = () => {
  const { id } = useParams<Pick<productList, 'id'>>();

  const [product, loading] = useGetFetch(getAPIUrl(`${apiRoutes.PRODUCT + id}/`));

  const [productSku, setProductSku] = useState<sku[]>();
  const [showSizeChartButton, setShowSizeChartButton] = useState<boolean>(false);
  const [showRequestSizeChartModal, setShowRequestSizeChartModal] = useState<boolean>(false);
  const [showProductImgCarouselModal, setShowProductImgCarouselModal] = useState<boolean>(false);
  const [productImgsOnModal, setProductImgsOnModal] = useState<imagesData[]>();
  const [showProductImages, setShowProductImages] = useState<imagesData[]>();
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [selectedColorId, setSelectedColorId] = useState<number | null>(null);
  const [totalProductQuantity, setTotalProductQuantity] = useState<number>(0);

  const history = useHistory();

  const handleRequestSizeChartModal = () => {
    setShowRequestSizeChartModal((prevState) => !prevState);
    window.scroll(0, 0);
  };

  const sendSizeChatRequest = async (item: productList) => {
    try {
      const requestedProductSize = {
        product: id,
      };
      const response = await RequestProductSizeChart(requestedProductSize);
      if (response.id) {
        setShowSizeChartButton(true);
        successToast('Your request has been successfully sent');
        setShowRequestSizeChartModal((prevState) => !prevState);
      }
    } catch (e: any) {
      errorToast('You request has been failed ');
    }
  };

  const handlePageRedirect = (pathname: string) => {
    history.push(pathname);
  };

  // Setting the images in the Image Crarousel on the page render
  useEffect(() => {
    if (product && product.pictures && product.pictures.length > 0) {
      let filteredColouredImages;
      const defaultImages = product.pictures.filter((picture: imagesData) => !picture.color);
      if (selectedColorId) {
        filteredColouredImages = product.colours.filter((picture: imagesData) => picture.id === selectedColorId);
      }
      setShowProductImages(filteredColouredImages
        && filteredColouredImages.length
        ? [...filteredColouredImages, ...defaultImages, ...product.colours.filter((picture: imagesData) => picture.id !== selectedColorId)]
        : [...defaultImages, ...product.colours.filter((item: imagesData) => item.image)]);
    }

    // Setting the sku quantity of product to zero on intital render
    if (selectedColorId && product && product.sku && product.sku.length > 0) {
      setProductSku([]);
      const skuArr = product.sku.filter((sku: sku) => sku.colour === selectedColorId)
        .map((sku: sku) => ({ ...sku, quantity: 0 }));
      setProductSku(skuArr);
    }
  }, [product, selectedColorId]);

  // Calculating the total product quantity of Product SKU
  useEffect(() => {
    if (productSku?.length) {
      const totalQuantity = productSku?.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
      setTotalProductQuantity(totalQuantity);
    }
  }, [productSku, product]);

  if (loading) {
    return <Loader />;
  }

  if (product.length === 0) {
    return (
      <div>
        No available products
      </div>
    );
  }

  const {
    name, description, moq, manufacturer, prices, colours, weight, inner_fabric,
    inner_fabric_percentage, main_fabric_2, main_fabric_percentage, main_fabric, main_fabric_2_percentage,
  } = product;

  const calculateProductPrice = () => {
    let price;
    let minQuantity;
    let maxQuantity;
    // eslint-disable-next-line no-loops/no-loops
    for (let i = 0; i < prices.length; i++) {
      const range = prices[i].range.split(/>|-/);
      if (range[0].length) {
        [minQuantity, maxQuantity] = range;
        if (totalProductQuantity >= minQuantity
          && totalProductQuantity <= maxQuantity) price = prices[i]._price;
      } else {
        [, minQuantity] = range;
        if (totalProductQuantity >= minQuantity) price = prices[i]._price;
      }
      if (price) break;
    }
    return price;
  };

  const handleAddToCart = () => {
    const price = calculateProductPrice();
    const amount = price * totalProductQuantity;
    const cartItem = {
      id,
      order_items: productSku?.map((sku: sku) => (sku.quantity ? ({ ...sku, price }) : sku)),
      name,
      manufacturer: manufacturer && manufacturer.business_name,
      image: showProductImages && showProductImages[0],
      amount,
      prices,
      moq,
      individualProductWeight: (weight || 350) / 1000,
      totalProductWeight: ((weight || 350) * totalProductQuantity) / 1000,
    };
    const cartState = JSON.parse(localStorage.getItem('cartItem') || '[]');
    const cartValue = [...cartState, cartItem];
    localStorage.setItem('cartItem', JSON.stringify(cartValue));
    handlePageRedirect('/cart');
    window.scrollTo(0, 0);
    successToast('Your Product is successfully added !!!');
  };

  const ProductPages: page[] = [
    { name: 'Products', href: '/product-list', current: false },
    { name, href: `/product/${product.id}`, current: true },
  ];

  const selectedTabsFn = (condition: boolean) => (condition
    ? ' border-4 border-purple-300 mr-3 rounded-full ' : 'mr-3');

  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  const setColorId = (id: number) => {
    setSelectedColorId(id);
  };

  const onChangeProductQuantity = (selectedSku: sku, quantity: number) => {
    const updatedSku = productSku?.map(
      (sku: sku) => (sku.id === selectedSku.id ? { ...sku, quantity } : sku),
    );
    setProductSku(updatedSku);
  };

  const addQuantity = (selectedSku: sku, quantity: number) => {
    const updatedSku = productSku?.map(
      (sku: sku) => (sku.id === selectedSku.id ? { ...sku, quantity: (quantity + 1) } : sku),
    );
    setProductSku(updatedSku);
  };

  const removeQuantity = (selectedSku: sku) => {
    const updatedSku = productSku?.map(
      (sku: sku) => ((sku.id === selectedSku.id && sku.quantity > 0)
        ? { ...sku, quantity: sku.quantity - 1 } : sku),
    );
    setProductSku(updatedSku);
  };

  const handleProductImgCarousel = (selectedImgId: number | undefined) => {
    const filteredSelectedImg = showProductImages?.filter((img: imagesData) => img.id === selectedImgId);
    if (filteredSelectedImg && showProductImages) {
      setProductImgsOnModal([...filteredSelectedImg, ...showProductImages.filter((img: imagesData) => img.id !== selectedImgId)]);
      setShowProductImgCarouselModal(true);
      window.scroll(0, 0);
    }
  };

  return (
    <div className="lg:px-4 grid grid-cols-12">
      {/* {showSizeChart && <SizeChartModal setShowSizeChart={setShowSizeChart} />} */}
      {showRequestSizeChartModal
        && (
          <div className="absolute top-0 left-0 z-50 bg-black bg-opacity-40 pt-40 min-h-screen min-w-screen        ">
            <div className="max-w-md mx-auto">
              <RequestSizeChartModal
                handleRequestSizeChartModal={handleRequestSizeChartModal}
                sendSizeChatRequest={sendSizeChatRequest}
              />
            </div>
          </div>
        )}
      {showProductImgCarouselModal
        && (
          <div className="absolute top-0 left-0 z-50 bg-black bg-opacity-40 pt-40 sm:pt-20 min-h-full min-w-screen">
            <div className="max-w-lg mx-auto">
              <ProductImageCarouselModal
                setShowProductImgCarouselModal={setShowProductImgCarouselModal}
                productImages={productImgsOnModal}
              />
            </div>
          </div>
        )}
      <div className="pl-6 lg:pl-12 col-span-12">
        <Breadcrumbs pages={ProductPages} />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <div
          className="md:hidden"
        >
          <ImageCarousel
            images={showProductImages || defaultImgArr}
            selectedColorId={selectedColorId}
          />
        </div>
        <div className="hidden md:block px-10 pb-10">
          <img
            src={showProductImages && showProductImages[0].image}
            alt="Product"
            className="border-2 border-black w-full"
            onClick={() => handleProductImgCarousel(showProductImages && showProductImages[0] && showProductImages[0].id)}
          />
          <div className="grid grid-cols-2 gap-8 mt-8">
            {showProductImages && showProductImages?.slice(1).map((item: imagesData) => (
              <img
                key={item.id}
                src={item.image}
                onClick={() => handleProductImgCarousel(item.id)}
                alt="Product Details"
                className="border-2 border-black object-contain
                  max-w-full min-w-full lg:max-h-472px lg:min-h-472px  "
              />
            ))}
          </div>

        </div>

      </div>

      <div className="col-span-12 lg:col-span-5">
        <div className="px-10 md:px-0 mt-10 lg:mt-3">
          <div className="flex flex-row justify-between">
            {name && <div className="font-serif text-2xl lg:text-3xl lg:font-semibold font-medium">{name}</div>}
            {/* TODO - PARKED FOR NOW */}
            {/* <div className="border-2 border-black rounded-full p-2 bg-white">
              <Heart className="cursor-pointer" />
            </div> */}
          </div>
          <div className="font-sans text-lg">{manufacturer && manufacturer.business_name}</div>
          {manufacturer && (
            <Tags
              ethicalProduction={manufacturer.ethical_trade}
              sustainableSourcing={manufacturer.sustainable_business}
              style="text-xs pl-0.5"
            />
          )}
        </div>
        <div className="pl-10 md:pl-0 pr-5 mt-10 mb-6">
          <div className="font-serif text-2xl font-medium">Bulk Pricing</div>
          <div className="overflow-auto">
            <table className="w-full">
              <tr className="flex flex-row mt-4 items-center">
                <th className="w-14 text-left text-sm font-serif">Price</th>
                {prices && prices.length > 0
                  && prices.map((bulkPrice: bulkPrice, index: number) => (
                    <td className="w-20 text-center px-1" key={index}>
                      <div className="text-center p-1  border border-black rounded-3xl bg-purple-shade text-sm">
                        {/* round to two decimal places */}
                        {`$${Math.round((bulkPrice._price / 100) * 100) / 100}`}
                      </div>
                    </td>
                  ))}
              </tr>
              <tr className="flex flex-row items-center mt-3">
                <th className="w-14 text-left text-sm font-serif">Quantity</th>
                {prices && prices.length > 0 && prices.map((bulkPrice: bulkPrice) => (
                  <td className="w-20 text-center p-1 text-xs font-sans" key={bulkPrice.range}>{bulkPrice.range}</td>
                ))}
              </tr>
            </table>

          </div>
          {moq && (
            <div className="text-sm  tracking-wide mt-2 text-gray-500">
              Minimum order quantity:
              <span className="font-semibold">
                {`${moq} `}
                {moq > 1 ? 'pieces' : 'piece'}
              </span>
            </div>
          )}
          {colours && (
            <div>
              <div className="font-serif text-2xl mt-8 font-medium">Colors</div>
              <div className="flex flex-row py-2 overflow-auto">
                {colours.length ? colours.map((color: imagesData, index: number) => (
                  <div onClick={() => setColorId(color.id)} key={color.id}>
                    <div
                      onClick={() => onTabClick(index)}
                    >
                      <div className=" cursor-pointer overflow-auto ">
                        <img
                          src={color.image || manufacturerDefaultImg}
                          alt={color && color?.name}
                          title={color && color?.name}
                          className={`min-w-40px max-h-40 min-h-40 w-11 h-10 rounded-full object-contain mr-4 
                          ${selectedTabsFn(activeTab === index)}`}
                        />
                        <p className="text-xs overflow-clip max-w-30px mt-2 ml-2 ">{color && color?.name}</p>
                      </div>
                    </div>
                  </div>
                ))
                  : 'No colors available for this product'}
              </div>
              {selectedColorId && productSku && productSku.length > 0 && (
                <div>
                  <div className="text-2xl mt-5 py-3 font-serif font-medium">Sizes</div>
                  {
                    productSku.map((sku: sku, index: number) => (
                      <div key={index}>
                        <ProductQuantity
                          removeQuantity={removeQuantity}
                          addQuantity={addQuantity}
                          sku={sku}
                          onChangeProductQuantity={onChangeProductQuantity}
                        />
                      </div>
                    ))
                  }
                  <div className="flex items-center justify-center my-10 lg:block lg:ml-20">
                    <button
                      disabled={!(totalProductQuantity >= moq)}
                      onClick={handleAddToCart}
                      type="button"
                      className={`${!(totalProductQuantity >= moq)
                        ? 'bg-gray-100' : 'bg-lemon'} 
                        text-sm border whitespace-nowrap border-black rounded-full px-5 py-3 font-serif`}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              )}
              {!showSizeChartButton
                && (
                  <div className="flex items-center my-5">
                    <div
                      className="text-sm flex py-1 font-serif cursor-pointer"
                      onClick={handleRequestSizeChartModal}
                    >
                      <img src={SizeChartRuler} className="w-5 h-5 mr-2" alt="View Size chart of clothes" />
                      <span className="border-b border-black pb-1">
                        Request size chart
                      </span>
                    </div>
                  </div>
                )}

            </div>
          )}
        </div>
        <div>
          <Accordion
            heading="Product Description"
            headingClass="px-10 lg:px-0 py-5 w-full text-lg border-t border-black font-serif"
          >
            <div className="px-10 lg:px-0  pb-5">
              {description}
              <div>
                {inner_fabric && inner_fabric_percentage ? `${inner_fabric}:${inner_fabric_percentage}%` : null}
              </div>
              <div>
                {main_fabric && main_fabric_percentage ? `${main_fabric}:${main_fabric_percentage}%` : null}
              </div>
              <div>
                {main_fabric_2 && main_fabric_2_percentage ? `${main_fabric_2}:${main_fabric_2_percentage}%` : null}
              </div>
            </div>
          </Accordion>

          {/* {manufacturer && manufacturer.length > 0 && (
            <Accordion heading="Manufacturer Info"
            headingClass="px-10
            lg:px-0 py-5 text-lg border-t border-black font-serif">
              <div className="px-10 lg:px-0 pb-5">
                {manufacturer.business_description}
              </div>
            </Accordion>
          )} */}

          <Accordion heading="Customization" headingClass="px-10 lg:px-0 py-5 text-lg border-t border-black font-serif">
            <div className="px-10 lg:px-0  pb-5">
              <img src={CustomBar} alt="Bar" className="my-3" />
              <div className="flex">
                <img src={CustomImage1} alt="Custom 1" className="mr-3" />
                <img src={CustomImage2} alt="Custom 2" />
              </div>
            </div>

          </Accordion>
        </div>
      </div>
    </div>

  );
};

export default ProductDetail;
