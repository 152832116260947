/* eslint-disable react/button-has-type */
import { isAuthenticated } from "API/APIHelpers";
import CircularBtn from "Components/atoms/CircularBtn";
import Tab from "Components/Tab";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import leftArrow from "../assets/icons/left-arrow.svg";
import rightArrow from "../assets/icons/right-arrow.svg";
import Aritzia from "../assets/icons/Aritzia_Logo_White.png";
import onboardedDummyImage from "assets/images/onboardedDummyImage.png";
import landingElevate from "assets/images/placeholderImages/Landing_Elevate.png";
import landingCameraGirl from "assets/images/placeholderImages/Landing_Camera_Girl.png";
import landingJourney from "assets/images/placeholderImages/Landing_Journey.png";
import landingJourneyMobile from "assets/images/placeholderImages/Landing_Journey_Mobile.png";
import landingOrangeArch from "assets/images/placeholderImages/Landing_Orange_Arch.png";
import landingOrangeArchMobile from "assets/images/placeholderImages/Landing_Orange_Arch_Mobile.png";
import landingPendent from "assets/images/placeholderImages/Landing_Pendent.png";

import onboardingGirlMobileImg from "assets/images/placeholderImages/onboarding_girl_mobile.png";
import onboardingLevelMobileImg from "assets/images/placeholderImages/onboarding_level_mobile.png";
// import onboardingAboutMobileImg from 'assets/images/placeholderImages/onboarding_about_mobile.png';
import onboardingAboutImg from "assets/images/placeholderImages/Production_Team.png";
import onboardingSecondImg from "assets/images/placeholderImages/onboarding_second.png";
import onboardingSecondMobileImg from "assets/images/placeholderImages/onboarding_second_mobile.png";
import Accordion from "Components/Accordian";
import { typeformLink, goodCompany } from "Constants";
import SeeHowWeCompare from "Components/SeeHowWeComapreComponent";
import { ReactComponent as TickIcon } from "../assets/icons/tick_icon.svg";

const ProductionOnboardingPage: React.FC = () => {
  const [currentslide, setCurrentslide] = useState(0);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [accordian, setAccordian] = useState<number>(1);

  const handleSignOut = (pathname: string) => {
    history.push(pathname);
    localStorage.clear();
    isAuthenticated();
  };
  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  const handleRedirectPage = (pathname: string) => {
    history.push(pathname);
    window.scrollTo(0, 0);
  };

  const handleGetStarted = (url: string) => {
    window.open(url);
  };

  const handleReviewMore = (id: string) => {
    history.push({
      pathname: '/production/reviewsection',
      search: `?id=${id}`
    })
    window.scrollTo(0, 0);
  };

  const tenDays = "< 10 days";

  const thirtyDays = "< 30 days";

  const selectedTabsFn = (condition: boolean) => {
    return condition ? "border-2 border-black rounded-full px-4 py-1 bg-seaGreen" : "";
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.curator.io/published/09518a48-2759-41da-aa1b-e9cfa9094d84.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div>
      <section className="block new-container overflow-hidden">
        <div className="relative min-h-80screen md:min-h-screen">
          <div className="">
            <img
              src={landingOrangeArch}
              alt="resource fashion"
              className="object-contain bg-contain absolute productionPageOrangeGradient hidden md:block"
            />
            <img
              src={landingOrangeArchMobile}
              alt="resource fashion"
              className="object-contain bg-contain absolute productionPageOrangeGradient md:hidden"
            />
          </div>
          <div className="absolute textOrangeGradient text-center">
            <h1
              className="font-LuthonSouthard reimagineText text-6xl
            md:text-4xl
            lg:text-4.5rem lg:leading-none
            2xl:text-8xl"
            >
              Reimagine your clothing supply chain
            </h1>
            <div className=" lg:pt-2 xl:pt-4  2xl:pt-6">
              <CircularBtn
                onClick={() => handleGetStarted(typeformLink)}
                text="Learn More"
                additionalClasses="bg-black rounded-full text-sand
              hover:opacity-90 pb-3 pt-2 pr-5 pl-8  mt-12
              font-FahkwangRegular font-medium text-xl mx-auto"
              />
            </div>
          </div>
          <img
            src={landingElevate}
            alt="resource fashion"
            className="object-contain bg-contain absolute elevateYourBrand"
          />
          <img
            src={landingCameraGirl}
            alt="resource fashion"
            className="object-contain bg-contain absolute cameraGirlStyle"
          />
          <img
            src={landingPendent}
            alt="resource fashion"
            className="object-contain bg-contain absolute pandentStyle"
          />
          <img
            src={landingJourney}
            alt="resource fashion"
            className="object-contain bg-contain absolute landingJourneyStyle hidden md:block"
          />
          <img
            src={landingJourneyMobile}
            alt="resource fashion"
            className="object-contain bg-contain absolute landingJourneyStyle md:hidden"
          />
        </div>
      </section>

      <section className="bg-lightYellow">
        <div className="p-10 md:p-24 md:pt-28  grid grid-cols-11 new-container">
          <p className="font-FahkwangRegular text-3xl md:text-3xl col-span-11 mt-3 lg:col-span-5 lg:px-20 hidden md:block md:tracking-wide ">
            A production supply chain specifically designed for emerging fashion brands.
          </p>
          {/* For Mobile View only */}
          <p className="font-FahkwangRegular text-3xl md:text-4xl col-span-11 mt-3 lg:col-span-5 md:px-20 md:hidden">
            A production supply chain specifically
            <br />
            designed for emerging
            <br />
            fashion brands.
          </p>
          {/*  */}
          <div className="col-span-11 lg:col-span-2 w-3/4 md:w-full">
            <div className="mt-10 md:mt-8">
              <TickIcon />
            </div>
            <h2 className="mt-2 font-FahkwangRegular text-xl md:text-2xl font-medium">
              Small batch
            </h2>
            <p className="mt-4 text-sm">
              Many clothing manufacturers require large minimum orders. We offer true small batch
              manufacturing on demand.
            </p>
          </div>
          <div className="col-span-11 lg:col-span-2 w-3/4 md:w-full">
            <div className="mt-10 md:mt-8">
              <TickIcon />
            </div>
            <h2 className="mt-2 font-FahkwangRegular text-xl md:text-2xl font-medium">
              High quality
            </h2>
            <p className="mt-4 text-sm">
              Quality fashion doesn’t just happen on its own. Our team has over 20 years of
              experience in production.
            </p>
          </div>
          <div className="col-span-11 lg:col-span-2 w-3/4 md:w-full">
            <div className="mt-10 md:mt-8">
              <TickIcon />
            </div>
            <h2 className="mt-2 font-FahkwangRegular text-xl md:text-2xl font-medium">
              Short lead time
            </h2>
            <p className="mt-4 text-sm ">
              An easy online supply chain management platform gives you total control in real time,
              on time.
            </p>
          </div>
        </div>
      </section>

      <section className="new-container">
        <div className="pt-10 pb-5 px-5 md:px-32 md:py-16">
          <h2 className="hidden md:block font-sans text-lg font-light w-3/4 ">
            We are not agents going after big brands...
          </h2>
          <h2 className="md:hidden font-sans text-lg font-light w-3/4 leading-tight">
            We are not agents
            <br />
            going after big brands...
          </h2>
          <div className="relative flex flex-center mt-1">
            <img
              src={onboardingSecondMobileImg}
              alt="Our Story Resource"
              className="w-full mt-4 md:hidden"
            />
            <img
              src={onboardingSecondImg}
              alt="Our Story Resource"
              className="w-full hidden md:block"
            />
            <p className="md:hidden absolute font-sans md:text-xl text-white italic top-4/5 md:top-17/20 text-right right-2 lg:right-8">
              We are enablers for manufacturers
              <br />
              and emergent brands.
            </p>
            <p className="hidden md:block absolute font-sans md:text-xl text-white italic top-4/5 md:top-17/20 text-right right-2 lg:right-8">
              We are enablers for manufacturers and emergent brands.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="px-5 pt-10 bg-lightYellow">
          <h1 className="font-FahkwangRegular text-2xl md:text-3xl font-medium md:font-semibold tracking-tight md:tracking-normal text-center">
            WE ARE UNIQUE IN WHAT WE DO
          </h1>
          <div className="w-full mt-5 md:mt-8">
            <ul className="flex flex-row justify-center items-center list-none pl-4 py-2 border-b border-dashed border-gray-400 overflow-x-auto pb-3 ">
              {["MOQ", "Quality", "Lead Time", "Efficiency"].map((tab: any, index: number) => (
                <div key={index} className="">
                  <Tab
                    style={`${selectedTabsFn(activeTab === index)} lg:mx-8`}
                    onTabClick={onTabClick}
                    currentTab={index}
                    comeFromOnboarding
                  >
                    <div className="flex flex-row cursor-pointer whitespace-nowrap">
                      <div className="text-xs font-FahkwangRegular font-semibold ">{tab}</div>
                    </div>
                  </Tab>
                </div>
              ))}
            </ul>

            {activeTab === 0 && (
              <div>
                <SeeHowWeCompare
                  handleRedirectPage={handleRedirectPage}
                  mainHeading="Manufacturers prefer big quantity but we focus on making small minimums work for you to test and find your market fit."
                  treaditionHeading="Over 100 pieces"
                  logoHeading="1 piece"
                  additionalClasses="text-xs md:text-lg w-11/12"
                  additionalLogoClasses="text-xs md:text-lg w-11/12 pb-5 md:pb-10"
                />
              </div>
            )}

            {activeTab === 1 && (
              <div>
                <SeeHowWeCompare
                  handleRedirectPage={handleRedirectPage}
                  mainHeading="Good design makes your name, but poor quality breaks reputation. We only onboard trusted partners and conduct thorough check to ensure quality consistency."
                  treaditionHeading="Inconsistent quality"
                  logoHeading="Trusted partners and in-house quality check"
                  additionalClasses="text-xs md:text-lg w-11/12"
                  additionalLogoClasses="text-xs md:text-lg w-11/12 pb-5 md:pb-10"
                />
              </div>
            )}

            {activeTab === 2 && (
              <div>
                <SeeHowWeCompare
                  handleRedirectPage={handleRedirectPage}
                  mainHeading="Manufacturers prioritize big brands and emergent brands experience delay in production due to a lack of power. We guarantee timely delivery as we bet on you."
                  treaditionHeading="3 weeks for samples; 3 month for bulk"
                  logoHeading="Up to 10 days for samples; up to 1 month for bulk"
                  additionalClasses="text-xs md:text-lg w-11/12"
                  additionalLogoClasses="text-xs md:text-lg w-11/12 pb-5 md:pb-10"
                />
              </div>
            )}

            {activeTab === 3 && (
              <div>
                <SeeHowWeCompare
                  handleRedirectPage={handleRedirectPage}
                  mainHeading="We wouldn’t send communication by snail mail, and we don’t handle our business the old fashioned way. Our SaaS platform is always on, always accessible, intuitive and up to date. "
                  treaditionHeading="Email, phone calls, text messages"
                  logoHeading="A modern personalized dashboard"
                  additionalClasses="text-xs md:text-lg w-11/12"
                  additionalLogoClasses="text-xs md:text-lg w-11/12 pb-5 md:pb-10"
                />
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="grid py-12 grid-cols-12 md:new-container">
        <section className="px-5 md:px-0 flex relative justify-around mb-7 md:mb-0 items-center col-span-12 md:col-span-6 md:new-container">
          <img
            src={onboardingGirlMobileImg}
            alt="resource fashion"
            className="h-full md:h-4/5 object-contain"
          />
          <div className="absolute md:w-3/4 pl-5">
            <h1 className="pt-10 font-FahkwangRegular text-center text-4xl md:text-6xl text-white">
              WE GOT YOU COVERED
            </h1>
          </div>
        </section>

        <section className="col-span-12 md:col-span-6 my-auto">
          <div onClick={() => setAccordian(1)}>
            <Accordion
              headingClass="bg-orangeShade py-5 px-5 font-medium border-2 border-black"
              heading="Fabric Sourcing"
              h1Class="text-2xl text-center mx-auto font-FahkwangRegular"
              preOpen={accordian === 1}
            >
              <div className="border-black border-b-2 border-l-2 border-r-2">
                <p className="p-10 font-sans">
                  The right fabric can make or break your designs. Our local office is in the center
                  of one of the largest fabric markets worldwide. With our years of experience in
                  fashion manufacturing and established relationships with the best fabric
                  suppliers, we can help you to find the perfect fabric for your designs, no matter
                  what your specific needs are.
                </p>
              </div>
            </Accordion>
          </div>

          <div onClick={() => setAccordian(2)}>
            <Accordion
              headingClass="bg-orangeShade-2 py-5 px-5 font-medium border-l-2 border-r-2 border-black"
              heading="Sample Production"
              h1Class="text-2xl text-center mx-auto font-FahkwangRegular"
              preOpen={accordian === 2}
            >
              <div className="border-black border-t-2 border-l-2 border-r-2">
                <p className="p-10 font-sans">
                  Samples are the best way to see if the vision in your head matches reality, which
                  is why we work hard to create the very best samples from your vision. With a real
                  world example of your finished product in your hands, it’s easy to decide if
                  you’ve hit the bullseye, or you need to go back to the drawing board.
                </p>
              </div>
            </Accordion>
          </div>

          <div onClick={() => setAccordian(3)}>
            <Accordion
              headingClass="bg-orangeShade py-5 px-5 font-medium border-2 border-black"
              heading="Sample Revision"
              h1Class="text-2xl text-center mx-auto font-FahkwangRegular"
              preOpen={accordian === 3}
            >
              <div className=" border-black border-b-2 border-l-2 border-r-2">
                <p className="p-10 font-sans">
                  Sometimes, you have to go back to the drawing board more than once. Our sample
                  revision service takes all your proposed changes and makes them real, from design
                  changes to fabric switches. With that, you can keep nipping, tucking and
                  perfecting, as long as it takes.
                </p>
              </div>
            </Accordion>
          </div>

          <div onClick={() => setAccordian(4)}>
            <Accordion
              headingClass="bg-orangeShade-2 py-5 px-5 font-medium border-b-2 border-r-2 border-l-2 border-black"
              heading="Supply Chain Management"
              h1Class="text-2xl text-center mx-auto font-FahkwangRegular"
              preOpen={accordian === 4}
            >
              <div className="bg-sand border-black border-b-2 border-l-2 border-r-2">
                <p className="p-10 font-sans">
                  Our proprietary SaaS software is designed to make your life infinitely easier. You
                  can track your order’s progress or raise questions and flag issues anytime, with
                  just a few clicks. No more follow-up emails or phone calls. Just fast, efficient
                  and crystal clear production process.
                </p>
              </div>
            </Accordion>
          </div>

          <div onClick={() => setAccordian(5)}>
            <Accordion
              headingClass="bg-orangeShade-3 py-5 px-5 font-medium border-l-2 border-r-2 border-b-2 border-black"
              heading="Shipping"
              h1Class="text-2xl text-center mx-auto font-FahkwangRegular"
              preOpen={accordian === 5}
            >
              <div className="border-black border-b-2 border-l-2 border-r-2">
                <p className="p-10 font-sans">
                  We want you to focus on the fashion than freight. Our existing shipping network
                  relationships mean that whether you need fast air freight or bulk sea shipping, we
                  can help with the best negotiated rates. We take care of anything from packaging
                  to customs clearance.
                </p>
              </div>
            </Accordion>
          </div>
        </section>
      </div>

      <section>
        <div className="px-5 py-10 bg-lightYellow">
          <div className="new-container">
            <h1 className="font-FahkwangRegular text-3xl font-semibold text-center pb-5 border-b border-dashed border-gray-400">
              OUR TIMELINES
            </h1>
            <div className="flex md:justify-around py-8 border-b border-dashed align-middle px-5 md:px-10 border-gray-400">
              <h1 className="relative font-FahkwangRegular text-4xl font-medium md:text-6xl">
                {tenDays}
                <div className="font-light absolute text-right text-xl justify-end md:text-2xl top-14 -right-24 hidden md:block">
                  on average
                </div>
                <div className="font-light absolute  text-xl top-9 whitespace-nowrap text-right right-0 left-0 md:hidden">
                  on average for sample orders.
                </div>
              </h1>
              <div className="flex mt-11">
                <p className="hidden md:block">__________for sample orders.</p>
              </div>
            </div>
            <div className="py-8 flex md:justify-around  border-b align-middle px-5 md:px-10 border-dashed border-gray-400">
              <h1 className="relative font-FahkwangRegular text-4xl font-medium md:text-6xl">
                {thirtyDays}
                <div className="absolute font-light text-xl justify-end md:text-2xl top-14 -right-24 hidden md:block">
                  on average
                </div>
                <div className="font-light absolute  text-xl top-9 whitespace-nowrap right-0 left-0 md:hidden">
                  on average for bulk orders.
                </div>
              </h1>
              <div className="flex mt-11">
                <p className="hidden md:block">__________for bulk orders.</p>
              </div>
            </div>
            <div className="flex justify-end pt-3">
              <p className="text-xs">*excluding shipping</p>
            </div>
          </div>
        </div>
      </section>
      <div className="grid py-12 grid-cols-12 bg-brown md:new-container">
        <section className="px-5 md:px-0 flex relative justify-around mb-7 md:mb-0 items-center col-span-12 md:col-span-6 md:new-container">
          <img
            src={onboardedDummyImage}
            alt="resource fashion"
            className="h-full md:h-4/5 object-contain rounded-full"
          />
        </section>

        <section className="col-span-12 md:col-span-6 my-auto">
          <h3 className="px-5 text-3xl font-FahkwangRegular font-semibold text-sand">A MODERN CLOTHING SUPPLY CHAIN PRICED FOR EMERGENT BRANDS</h3>
          <p
            className="p-5 py-10 font-serif text-sand"
            dangerouslySetInnerHTML={{
              __html:
                "Our pricing model is as unique and thoughtfully designed as our supply chain. We built a membership model so we can better align our interests with our brands’. Conventional clothing manufacturers and agencies are incentivized to work with large brands but we are incentivized to provide consistently the best care to our members, no matter the sizes and stages of the company. Check out our one-of-a-kind, transparent, and affordable pricing plan.",
            }}
          />
          <div className="md:px-5 flex justify-center items-center md:block">
            <CircularBtn
              text="See our plans"
              additionalClasses="text-center py-3 rounded-full px-12 lg:px-20 bg-orangeShade hover:bg-seaGreen font-semibold font-FahkwangRegular
                                    border-2 border-black mt-5 pl-8 pr-6 text-xl shadow-solid-black-less"
              onClick={() => handleRedirectPage("/production/pricing")}
            />
          </div>
        </section>
      </div>

      {/* <section className="pt-10 pb-7 md:pt-12 md:pb-10 bg-orangeShade">
        <div className="md:new-container">
          <h1 className="font-FahkwangRegular text-3xl font-semibold text-center md:hidden">
            YOU’RE IN
            <br />
            GOOD COMPANY
          </h1>
          <h1 className="font-FahkwangRegular text-3xl font-semibold text-center hidden md:block">
            YOU’RE IN GOOD COMPANY
          </h1>
          <div>

            <div className="py-7 overflow-scroll relative">
              <div className="flex ml-5 md:ml-32 ">
                {goodCompany.map((item, index: number) => (
                  <div
                    key={index}
                    className="mx-3 md:mx-8 flex justify-center items-center cursor-pointer bg-white shadow rounded-full max-w-full h-auto align-middle
                min-h-185px min-w-185px max-h-185px
                 lg:min-h-185px lg:min-w-185px lg:max-h-185px"
                  >
                    <img
                      src={item.url}
                      alt="Good Company"
                      className=""
                    />
                  </div>
                ))}
              </div>
              <div className="hidden md:block absolute p-3 bg-orangeShade-3 rounded-full border border-black top-24 left-7">
                <BackwardArrow />
              </div>
              <div className="hidden md:block absolute p-3 bg-orangeShade-3 rounded-full border border-black right-7 top-24">
                <ForwardArrow className="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="md:new-container grid grid-cols-12">
        <section className="col-span-12 md:col-span-6 flex justify-center items-center">
          <div className="m-10 py-12 md:m-0 md:ml-44 md:mr-5 h-5/6 md:h-3/5 px-10 md:px-20 bg-black flex justify-center items-center">
            <div>
              <h1 className="font-FahkwangRegular text-2xl md:text-4xl text-sand font-medium">
                Join the Program
              </h1>
              <p className="pt-5 md:pt-10 font-sans text-sand">
                As founders, we come from manufacturer family and founded a fashion brand before
                building RE(SOURCE). We understand the production pains of fashion founder and
                challenges for manufacturers. You want to speak to us if you are the pioneer, the
                creative, the imaginative who want to build something new and exciting. We work with
                you to make your vision a reality.
              </p>
            </div>
          </div>
        </section>

        <section className="px-5 flex relative justify-center items-center md:pr-32 col-span-12 md:col-span-6">
          <img
            src={onboardingLevelMobileImg}
            alt="resource fashion"
            className=" h-5/6 md:h-3/5 object-fit"
          />
          <div className="absolute">
            <CircularBtn
              onClick={() => handleGetStarted(typeformLink)}
              text="Get Connected"
              additionalClasses="bg-seaGreen hover:bg-white  pb-2 pt-3 px-12 md:px-14 mt-20 text-center rounded-full
              border-black font-FahkwangRegular font-semibold shadow-solid-black-less"
            />
          </div>
        </section>
      </div>
      <section className=" bg-brown overflow-hidden">
        <div className="max-h-full grid grid-cols-12 gap-1 md:gap-2 md:new-container">
          <div className="col-span-12 align-middle md:col-span-7 p-8 text-white  m-auto">
            <div className=" font-FahkwangRegular text-4xl sm:text-5xl md:text-6xl ">WHAT THE </div>
            <div className="text-brown text-shadow-white font-AgrandirRegular text-5xl sm:text-6xl md:text-7xl font-black mt-2">
              RE(SOURCE)
            </div>
            <div className=" font-FahkwangRegular text-4xl sm:text-5xl md:text-6xl">FAM SAYS </div>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="container flex my-6 md:shadow-3xl  h-3/4 ">
              <div className="carousel w-full h-full relative no-scrollbar md:overflow-hidden scroll-snap-x">
                <div
                  className="carousel-inner  whitespace-nowrap transition-all h-full "
                  style={{ transform: `translateX(${-currentslide * 100}%)` }}
                >
                  <div className=" relative h-full carousel-item inline-block w-3/4 mx-4  md:w-full md:mx-0 bg-darkbrown border-2 whitespace-normal text-center shadow-3xl md:shadow-none scroll-centre">
                    <div>
                      <h1 className="text-2xl text-white m-4 font-FahkwangRegular">VAPPI</h1>
                    </div>
                    <div className=" text-lightbrown">Kareen Bedewi,Co-founder of VAPPI</div>
                    <div className="text-lightbrown">@vappi</div>
                    <div className="w-full h-36 text-white text-left font-semibold font-FahkwangRegular px-8 text-base md:text-xl my-4">
                      "We had thought about quitting before we started working with the RE(SOURCE) team and told ourselves that maybe we were too ambitious and naive about starting a brand ..."
                    </div>
                    <div className="absolute right-2 text-lightbrown bottom-3percent">

                      <span className="cursor-pointer" onClick={() => handleReviewMore('0')}>
                        Read more
                        {' '}
                        <span className="text-4xl"> &rarr;</span>
                      </span>
                    </div>
                  </div>
                  <div className=" relative carousel-item inline-block w-3/4 mx-4  md:w-full md:mx-0 bg-darkbrown border-2 h-full whitespace-normal text-center shadow-3xl md:shadow-none scroll-centre">
                    <div>
                      <h1 className="text-2xl text-white m-4  font-FahkwangRegular">FOREST INK</h1>
                    </div>
                    <div className=" text-lightbrown">Beth Welch,Owner of forest ink</div>
                    <div className="text-lightbrown">@forestink</div>
                    <div className="w-full h-36 text-white text-left font-semibold font-FahkwangRegular px-8 text-base md:text-xl my-4">
                      "We have thoroughly enjoyed the process of working with the RE(SOURCE) team. The dashboard is very easy to navigate and helps us keep track of everything. We have also  ..."
                    </div>
                    <div className="absolute right-2 text-lightbrown bottom-3percent">
                      <span className="cursor-pointer" onClick={() => handleReviewMore('1')}>
                        Read more
                        {' '}
                        <span className="text-4xl"> &rarr;</span>
                      </span>
                    </div>
                  </div>
                  <div className="relative carousel-item inline-block w-3/4 mx-4 md:w-full md:mx-0 bg-darkbrown border-2 h-full whitespace-normal text-center shadow-3xl md:shadow-none scroll-centre">
                    <div>
                      <h1 className="text-2xl text-white m-4  font-FahkwangRegular">INTIMATELY</h1>
                    </div>
                    <div className=" text-lightbrown">Maddie Highland,Designer</div>
                    <div className="text-lightbrown">@intimately</div>
                    <div className="w-full h-36 text-white text-left font-semibold font-FahkwangRegular px-8 text-base md:text-xl my-4">
                      "I have worked in the industry for almost 10 years, and this process has just felt more collaborative and organized than anything I’ve experienced before the process is really ..."
                    </div>
                    <div className="absolute right-3 text-lightbrown bottom-3percent">
                      <span className="cursor-pointer" onClick={() => handleReviewMore('2')}>
                        Read more
                        {' '}
                        <span className="text-4xl"> &rarr;</span>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className=" flex justify-end my-4 h-32 invisible md:visible ">
              <div
                className={`inline-block w-12 mr-4 h-12 rounded-full border-2 cursor-pointer ${currentslide < 1 ? "opacity-50" : ""}`}
                onClick={() => {
                  if (currentslide > 0) {
                    let i = currentslide;
                    i--;
                    setCurrentslide(i);
                  }
                }}
              >
                <img src={leftArrow} className="w-1/2 h-1/2 m-auto my-2.5"></img>
              </div>
              <div
                className={`inline-block w-12 md:mr-4 lg:-mr-12 h-12 rounded-full border-2 cursor-pointer${currentslide > 1 ? " opacity-50" : ""}`}
                onClick={() => {
                  if (currentslide < 2) {
                    let i = currentslide;
                    i++;
                    setCurrentslide(i);
                  }
                }}
              >
                <img src={rightArrow} className="w-1/2 h-1/2 m-auto my-2.5 "></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightYellow pt-8 md:px-20 md:pt-20">
        <div className="max-h-full grid grid-cols-12 gap-1 md:gap-2 md:new-container">
          <div className="flex items-center justify-center col-span-12 md:col-span-6 md:order-2 md:w-43/50">
            <div className=" p-5 text-center md:text-left  ">
              <h3 className="px-5 text-3xl font-FahkwangRegular font-semibold">ABOUT US</h3>
              <p
                className="p-5 py-10 font-serif text-base"
                dangerouslySetInnerHTML={{
                  __html:
                    "RE(SOURCE) is not your average clothing manufacturer. In fact, we’re like nothing in this industry. Our venture backed startup combines new technology with twenty years of family fashion experience at <a style=color:blue href='http://vicgarments.com/'>VIC.GARMENTS</a>. We don’t just make clothes. We turn fashion dreams into reality, and we’re excited to partner with the next big thing. Nice to meet you.",
                }}
              />
              <div className="md:px-5 flex justify-center items-center md:block">
                <CircularBtn
                  text="More about us"
                  additionalClasses="text-center py-3 rounded-full px-12 lg:px-20 bg-orangeShade hover:bg-seaGreen font-semibold font-FahkwangRegular
                                    border-2 border-black mt-5 pl-8 pr-6 text-xl shadow-solid-black-less"
                  onClick={() => handleRedirectPage("/production/about-us")}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-6 md:order-1">
            <img src={onboardingAboutImg} alt="Our Story Resource" className="w-full" />
          </div>
        </div>
      </section>
      <section className="pt-8 pb-8 md:pt-20 pb-20">
        <div className="max-h-full grid grid-cols-12 gap-1 md:gap-2 md:new-container">
          <div className="col-span-12 flex items-center justify-center md:col-span-6">
            <div className="w-100">
              <div className="text-center">
                <p className="font-semibold font-FahkwangRegular text-xl sm:text-3xl md:text-6xl">YOU’LL WANT</p>
                <p className="font-semibold font-FahkwangRegular text-xl sm:text-3xl md:text-6xl"> TO SEE THIS</p>
              </div>
              <div className="text-center mt-2.5">
                <span className="font-FahkwangRegular text-2xl">Watch RE(SOURCE) in action</span>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 mt-12 mb-12 sm:m-0">
            <div className="grid grid-cols-12 mx-4">
              <div className="col-span-6 justify-center items-center mr-2.5">
                <a href="https://www.instagram.com/tv/CeAPC-2OAFm/?utm_source=ig_web_copy_link" target="_blank"><div className={`bg-center object-contain ${'bg-liveStream1'}`} /></a>
                <a href="https://www.instagram.com/tv/CdKOYK6sXqp/?utm_source=ig_web_copy_link" target="_blank"><div className={`bg-center object-contain ${'bg-liveStream2'}`}></div></a>
              </div>
              <div className="col-span-6 justify-center items-center">
                <a href="https://podcasts.apple.com/us/podcast/what-manufacturing-in-china-is-really-like-with-ziqi/id1588757826?i=1000559289449" target="_blank"><div className={`bg-center object-contain ${'bg-podcast1'}`}></div></a>
                <a href="https://podcasts.apple.com/us/podcast/ep-51-ziqi-deng-and-tatiana-tian-cofounders-of-re/id1566184855?i=1000557312108" target="_blank"><div className={`bg-center object-contain ${'bg-podcast2'}`}></div></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="px-5 py-10 bg-lightYellow">
          <div className="new-container">
            <h1 className="font-FahkwangRegular text-3xl font-semibold text-center pb-5">
              GET INSPIRED
            </h1>
          </div>
          <div className="m-l-r-5 mt-33">
            <div id="curator-feed-default-feed-layout">
              <a href="https://curator.i" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a>
            </div>
          </div>
        </div>
      </section>
      <div />
    </div>
  );
};

export default ProductionOnboardingPage;
