/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// Icons For Navbar
import { Link } from "react-router-dom";
import useGetFetch from "hooks/useGetFetch";
import { getAPIUrl, isAuthenticated } from "API/APIHelpers";
import { apiRoutes } from "API/APIRoutes";
import { Company } from "Constants";
import { onButtonEmptyFn } from "Util/Utility";
// import { ReactComponent as Logo } from '../assets/images/placeholderImages/production-logo.svg';
import { ReactComponent as Logo } from "../assets/images/placeholderImages/Production_Logo.svg";
import User from "../assets/icons/Production_User.png";
import allbirdsBanner from "../assets/images/allbirds-banner.png";
import { ReactComponent as ShoopingCart } from "../assets/icons/shopping-cart.svg";
import { ReactComponent as HamburgerIcon } from "../assets/icons/hamburger.svg";
import { ReactComponent as MagnifyingGlass } from "../assets/icons/magnifying-glass.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/navCloseIcon.svg";
import { ReactComponent as ForwardArrow } from "../assets/icons/forward_arrow.svg";
// Atoms Component
import MenuBar from "./MenuBar";
import HamburgerSheet from "./HamburgerSheet";
import SearchBarSheet from "./SearchBarSheet";
import CircularBtn from "./atoms/CircularBtn";
import ClothingLinkSheet from "./ClothingLinkSheet";
import NavbarLink from "./atoms/NavbarLink";
import Tab from "./Tab";
import Accordion from "Components/Accordian";

const ProductionNavbar: React.FC = () => {
  const location = useLocation();
  const [openHamburger, setOpenHamburger] = useState<boolean>(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const x = isAuthenticated();
    setIsUserLoggedIn(x);
  }, [location]);
  // APi calls

  const handleSignOut = (pathname: string) => {
    history.push(pathname);
    localStorage.clear();
    isAuthenticated();
  };

  const handleBtnClick = (pathname: string) => {
    history.push(pathname);
  };

  return (
    <div className="border border-gray-300 bg-sand no-scrollbar">
      <div className="rolling-banner">
        <a className="banner-link" href="https://www.eventbrite.com/e/406614233117">
          <div className="banner-contents">
            <img
              src={allbirdsBanner}
              alt="Resource x Allbirds-banner"
              className="banner-image"
            />
            <img
              src={allbirdsBanner}
              alt="Resource x Allbirds-banner"
              className="banner-image right"
            />
          </div>
        </a>
      </div>
      <div className="flex flex-row justify-between  px-4 py-1 lg:p-10 lg:py-5">
        <div>
          <Link to="/production">
            <Logo className="w-40 h-full md:w-full" />
          </Link>
        </div>

        <div className={`flex flex-row  ${openHamburger ? null : "z-20"} `}>
          {isUserLoggedIn ? (
            <div>
              <div className="hidden lg:flex ">
                {/* <CircularBtn
                onClick={() => handleSignOut('/production/login')}
                text="Sign Out "
                additionalClasses="bg-purple-300 hover:bg-purple-400  py-2 pr-4 pl-7 text-center rounded-full border border-black font-serif font-semibold mr-6"
              /> */}
                <button
                  onClick={() => handleBtnClick("/production/pilotProgram")}
                  type="button"
                  className="flex items-center justify-center  bg-gradient-to-r from-seaGreen to-purple-shade py-3 pr-4 pl-7 text-center rounded-full border border-black font-FahkwangRegular font-semibold mr-6"
                >
                  Dashboard
                  <span className="ml-1.5">
                    <ForwardArrow className="h-5" />
                  </span>
                </button>
              </div>
              <div
                onClick={() => handleBtnClick("/production/pilotProgram")}
                className="hover:opacity-80 p-2 rounded-full
                  border border-black ml-2.5 cursor-pointer lg:border-0 text-center center-icon
                  bg-gradient-to-bl from-orangeShade to-purple-shade-1 md:hidden"
              >
                <img src={User} alt="user" className="" />
              </div>
            </div>
          ) : (
            <div className="hidden lg:flex ">
              <CircularBtn
                onClick={() => handleSignOut("/production/login")}
                text="Log In "
                additionalClasses="hover:bg-yellow-100 pb-2 pt-3 pr-4 pl-7 text-center rounded-full border border-black font-FahkwangRegular font-semibold mr-6"
              />
              <CircularBtn
                onClick={() => handleSignOut("/production/signup")}
                text="Sign Up"
                additionalClasses="bg-orangeShade-4 hover:opacity-80  pb-2 pt-3 pr-4 pl-7 text-center rounded-full border border-black font-FahkwangRegular font-semibold mr-6"
              />
            </div>
          )}

          <div
            onClick={() => setOpenHamburger(!openHamburger)}
            className="bg-seaGreen hover:opacity-80 p-2 rounded-full
          border border-black ml-2.5 cursor-pointer
              lg:bg-sand lg:border-0 text-center center-icon
              n
          "
          >
            {openHamburger ? (
              <CloseIcon onClick={() => setOpenHamburger(false)} />
            ) : (
              <HamburgerIcon className="lg:w-10 lg:h-full " />
            )}

            {openHamburger && (
              <MenuBar
                open={openHamburger}
                setOpen={setOpenHamburger}
                positionClasses="top-111px border-none lg:top-24 lg:right-4 xl:top-24 "
              >
                <Hamburger setOpen={setOpenHamburger} />
              </MenuBar>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type HamburgerProps = {
  setOpen: (value: boolean) => void;
};

const Hamburger: React.FC<HamburgerProps> = (props) => {
  const { setOpen } = props;
  const history = useHistory();
  const isUserAuthenticated = isAuthenticated();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [accordian, setAccordian] = useState<number>(0);

  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  };
  const selectedTabsFn = (condition: boolean) => {
    return condition ? "border-2 border-black rounded-full px-4 py-1 bg-orangeShade" : "";
  };

  const handleBtnClick = (pathname: string) => {
    history.push(pathname);
    localStorage.clear();
    setOpen(false);
  };
  return (
    <div
      className="
              flex flex-col justify-between align-bottom bg-white  text-left overflow-hidden
               bg-productionMenu bg-cover  bg-no-repeat h-screen  bg-center object-contain
              min-w-screen no-scrollbar
              lg:bg-none
              lg:bg-orangeShade lg:min-w-full lg:h-full lg:max-h-80screen lg:overflow-scroll
              "
    >
      {/* Deskktop View */}
      <div className="hidden lg:block">
        <div className="flex flex-col border-1 border-black">
          <div className="border border-black py-10 pl-16 pr-24" onClick={() => setOpen(false)}>
            <h2 className="font-FahkwangRegular font-black text-2xl pb-7 ">Menu</h2>
            <NavbarLink
              linkName="Dashboard"
              linkURL="/production/pilotProgram"
              additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5   "
            />
            {/* <NavbarLink linkName="Dashboard" linkURL="/production/settings" additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  " /> */}
            <NavbarLink
              linkName="Settings"
              linkURL="/production/settings"
              additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
            />
          </div>
          <div className="pt-10 pl-16 pr-24 border-b border-l border-r border-black flex-grow">
            <h2 className="  font-FahkwangRegular font-black font- text-2xl pb-7">Company</h2>
            <div onClick={() => setOpen(false)}>
              <NavbarLink
                linkName="How it Works"
                linkURL="/production/how-it-works"
                additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
              />
              <NavbarLink
                linkName="About Us"
                linkURL="/production/about-us"
                additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
              />
              <NavbarLink
                linkName="FAQ"
                linkURL="/production/faq"
                additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
              />
              <a className="block text-lg font-serif mb-2 hover:underline active:border-none focus-visible:outline-none visited:border-none checked:border-none font-FahkwangRegular font-thin text-2xl pb-5  " href="https://resourcefashion.co/blog/">Blog</a>
              <NavbarLink
                linkName="Pricing"
                linkURL="/production/pricing"
                additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
              />
            </div>
            <div className="w-56 mb-5" onClick={() => setAccordian(1)}>
              <Accordion
                headingClass="bg-orangeShade font-medium"
                heading="Case Studies"
                h1Class="text-2xl text-center font-FahkwangRegular font-thin text-2xl"
                preOpen={accordian === 1}
              >
                <div onClick={() => setOpen(false)}>
                  <NavbarLink
                    linkName="A venture-backed startup brand (Intimately)"
                    linkURL="/production/reviewsection?id=2"
                    additionalClasses="font-FahkwangRegular font-thin text-xl mt-5 font-light"
                  />
                  <NavbarLink
                    linkName="Boutique turned wholesaler (Forest-Ink)"
                    linkURL="/production/reviewsection?id=1"
                    additionalClasses="font-FahkwangRegular font-thin text-xl mt-5 font-light"
                  />
                  <NavbarLink
                    linkName="Self-funded startup brand (Vappi)"
                    linkURL="/production/reviewsection?id=0"
                    additionalClasses="font-FahkwangRegular font-thin text-xl mt-5 font-light"
                  />
                </div>
              </Accordion>
            </div>
            <div className="flex items-center justify-center">
              {isUserAuthenticated ? (
                <CircularBtn
                  text="Sign Out"
                  additionalClasses="bg-black border-black font-FahkwangRegular text-white
              font-medium block text-center py-3 pl-8 pr-6 text-xl rounded-full text-center mt-1 mb-10"
                  onClick={() => handleBtnClick("/production/login")}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* Mobile View */}
      <div className="lg:hidden ">
        <ul className=" flex flex-row justify-center items-center list-none pl-4 py-2 border-b border-black overflow-x-auto pb-3 relative ">
          {["Dashboard", "Company"].map((tab: any, index: number) => (
            <div key={index} className="">
              <Tab
                style={`${selectedTabsFn(activeTab === index)} md:mx-12`}
                onTabClick={onTabClick}
                currentTab={index}
                comeFromOnboarding
              >
                <div className="flex flex-row cursor-pointer whitespace-nowrap">
                  <div className="text-sm font-FahkwangRegular font-semibold ">{tab}</div>
                </div>
              </Tab>
            </div>
          ))}
        </ul>
        <div className="mobile-scroll">
          {activeTab === 0 && (
            <div className="p-5 " onClick={() => setOpen(false)}>
              <NavbarLink
                linkName="Dashboard"
                linkURL="/production/pilotProgram"
                additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5 "
              />
              <NavbarLink
                linkName="Settings"
                linkURL="/production/settings"
                additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
              />
            </div>
          )}

          {activeTab === 1 && (
            <div className="p-5">
              <div onClick={() => setOpen(false)}>
                <NavbarLink
                  linkName="How it Works"
                  linkURL="/production/how-it-works"
                  additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
                />
                <NavbarLink
                  linkName="About Us"
                  linkURL="/production/about-us"
                  additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
                />
                <NavbarLink
                  linkName="FAQ"
                  linkURL="/production/faq"
                  additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
                />
                <NavbarLink
                  linkName="Pricing"
                  linkURL="/production/pricing"
                  additionalClasses="font-FahkwangRegular font-thin text-2xl pb-5  "
                />
              </div>
              <div className="w-56 mb-5" onClick={() => setAccordian(1)}>
                <Accordion
                  headingClass="font-medium"
                  heading="Case Studies"
                  h1Class="text-2xl text-center font-FahkwangRegular font-thin text-2xl"
                  preOpen={accordian === 1}
                >
                  <div onClick={() => setOpen(false)}>
                    <NavbarLink
                      linkName="A venture-backed startup brand (Intimately)"
                      linkURL="/production/reviewsection?id=2"
                      additionalClasses="font-FahkwangRegular font-thin text-xl mt-5 font-light"
                    />
                    <NavbarLink
                      linkName="Boutique turned wholesaler (Forest-Ink)"
                      linkURL="/production/reviewsection?id=1"
                      additionalClasses="font-FahkwangRegular font-thin text-xl mt-5 font-light"
                    />
                    <NavbarLink
                      linkName="Self-funded startup brand (Vappi)"
                      linkURL="/production/reviewsection?id=0"
                      additionalClasses="font-FahkwangRegular font-thin text-xl mt-5 font-light"
                    />
                  </div>
                </Accordion>
              </div>
            </div>
          )}
          <div className="w-full m-b-25">
            {isUserAuthenticated ? (
              <CircularBtn
                text="Sign Out"
                additionalClasses="bg-seaGreen hover:opacity-80 border-black font-FahkwangRegular
              font-medium block text-center border  py-4 text-xl rounded-full w-11/12 mx-auto mt-1 "
                onClick={() => handleBtnClick("/production/login")}
              />
            ) : (
              <div className="mt-2 px-8">
                <CircularBtn
                  text="Log In"
                  additionalClasses="bg-white hover:bg-gray-400 border-black font-FahkwangRegular
                  font-medium block text-center border  py-4 text-xl rounded-full w-full "
                  onClick={() => handleBtnClick("/production/login")}
                />
                <CircularBtn
                  text="Sign Up"
                  additionalClasses="bg-seaGreen hover:opacity-80 border-black font-FahkwangRegular
                  font-medium block text-center border  py-4 text-xl rounded-full w-full mt-5"
                  onClick={() => handleBtnClick("/production/signup")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductionNavbar;
