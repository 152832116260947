import React from 'react';
import { useHistory } from 'react-router-dom';

import { getAPIUrl, Loader } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';
import useGetFetch from 'hooks/useGetFetch';
import { manufacturerDefaultImg, priceRangeMap } from 'Constants';
import FilterBox from 'Components/FilterBox';
import Card from '../Components/Card';
import Breadcrumbs from 'Components/BreadCrumbs';

type ManufactureListTypes = {
  isFilterBoxVisible?: boolean
  productShowLimit?: number
}

const ManufacturerList: React.FC<ManufactureListTypes> = (props) => {
  const { isFilterBoxVisible, productShowLimit } = props;
  const [manufacturerList, loading] = useGetFetch(getAPIUrl(apiRoutes.MANUFACTURER));
  // For Filter
  const [categoryList, categoryLoading] = useGetFetch(getAPIUrl(apiRoutes.CATEGORY));
  const [styleList, styleLoading] = useGetFetch(getAPIUrl(apiRoutes.STYLE));

  const history = useHistory();

  const ManufacturerPage: page[] = [
    { name: 'Manufacturer List', href: '/manufacturerList', current: true },
  ];

  const redirectToManufactureDetails = (productId: string) => {
    history.push({
      pathname: `/manufacturer/${productId}`,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      {loading ? <Loader />
        : (
          <div className="pb-10 lg:py-20">
            <div className="new-container ">
              <div>
                <Breadcrumbs pages={ManufacturerPage} />
              </div>
              <div className="grid grid-cols-12 gap-4 lg:px-8">
                <div className="col-span-12 lg:col-span-3" />
                <div className={`col-span-12 lg:col-span-${isFilterBoxVisible ? '12' : '8'} `}>
                  <div className="grid grid-cols-12 gap-4">

                    {manufacturerList.slice(0, productShowLimit).map((item: manufacturerList) => (
                      <div
                        key={item.id}
                        className="col-span-6 sm:col-span-4 lg:col-span-4"
                        onClick={() => redirectToManufactureDetails(item.id)}
                      >
                        <Card
                          image={item.brand_logo || manufacturerDefaultImg}
                          manufacturerName={item.business_name}
                          location={item.address}
                          priceRange={priceRangeMap[item.price_range]}
                          type="manufacturer"
                          ethicalProduction={item.ethical_production}
                          sustainableSourcing={item.sustainable_sourcing}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default ManufacturerList;
