import { withRouter, useHistory, useParams } from 'react-router';
import { errorToast, successToast } from 'Util/Utility';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import { resetNewPassword } from 'API/Login';
import { ResetSchema } from 'validations';

const Reset : React.FC = () => {
  const { uid, token } = useParams<any>();

  const history = useHistory();

  const initialValues: any = {
    new_password1: '',
    new_password2: '',
    uid,
    token,
  };
  const handleResetPassword = async (values: LoginForm) => {
    try {
      const response = await resetNewPassword(values);
      console.log('response', response);
      history.push('/login');
      successToast('Your password has been successfully changed !!');
    } catch (e: any) {
      errorToast(e.new_password1 && e.new_password1[0]);
      errorToast(e.new_password2 && e.new_password2[0]);
      errorToast(e.token && e.token[0]);
      errorToast(e.uid && e.uid[0]);
    }
  };
  return (
    <>
      <Formik
        validationSchema={ResetSchema}
        onSubmit={handleResetPassword}
        initialValues={initialValues}
      >
        {() => (
          <div className="new-container lg:px-96">
            <h1 className="text-4xl font-serif font-medium pl-4 pt-10">Reset your password</h1>
            <Form className="px-4 py-36">
              <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                {/* Email */}
                <div className="col-span-12">
                  <Field name="new_password1" placeholder="New Password" type="password" className="inputTextField" />
                  <ErrorMessage component="p" name="new_password1" className="text-red-500 text-xs ml-2" />
                </div>
                {/* Password */}
                <div className="col-span-12">
                  <Field name="new_password2" placeholder="Confirm Password" type="password" className="inputTextField" />
                  <ErrorMessage component="p" name="new_password2" className="text-red-500 text-xs ml-2" />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="smallLemonButton mt-4"
                >
                  Reset Password
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>

    </>
  );
};

export default Reset;
