/* eslint-disable no-restricted-syntax */
import { getAPIUrl, getAuthHeaders, getHeaders } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';

export async function SearchProducts(payload: any) {
  try {
    const headers = getHeaders();
    const res = await fetch(getAPIUrl(apiRoutes.SEARCH_IMAGE), {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject('Failed to Search Product');
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export default async function uploadPhoto(imageFile: any) {
  try {
    const headers = getHeaders();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete headers['Content-Type'];
    const formData = new FormData();
    formData.append('file', imageFile);
    const res = await fetch(getAPIUrl(apiRoutes.UPLOAD + imageFile.name), {
      method: 'PUT',
      headers,
      body: formData,
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject('Failed to Upload Photo');
    }
    return Promise.resolve(response && response.file_url);
  } catch (error) {
    return Promise.reject(error);
  }
}
