/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import useGetFetch from 'hooks/useGetFetch';
import { errorToast, successToast } from 'Util/Utility';

import { apiRoutes } from 'API/APIRoutes';
import {
  getAPIUrl, getFilterProductUrl, Loader,
} from 'API/APIHelpers';

import { SearchProducts } from 'API/SearchProduct';
import RequestProduct from 'API/RequestProduct';

import Breadcrumbs from 'Components/BreadCrumbs';
import AliBabaRequestModal from 'Components/AliBabaRequestModal';
import { manufacturerDefaultImg } from 'Constants';
import Modal from 'Components/Modal';
import ToggleButton from 'Components/atoms/ToggleButton';
import PrimeTag from 'Components/atoms/PrimeTag';
import Card from '../Components/Card';
import FilterBox from '../Components/FilterBox';

type ProductListTypes = {
  isFilterBoxVisible?: boolean
  productShowLimit?: number
  data?: any
}

const ProductList: React.FC<ProductListTypes> = (props) => {
  const { isFilterBoxVisible, productShowLimit, data } = props;

  // This state to show product in the component
  const [showProduct, setShowProduct] = useState<any>();
  const {
    categoryid,
    styleid,
    fileUrl,
    page,
    tags,
  } = useParams<any>();

  const [searchedProducts, setSearchedProducts] = useState<any>();
  const [searchedProductLoading, setSearchedProductLoading] = useState(false);
  const [showAliBabaRequestModal, setShowAliBabaRequestModal] = useState<any>(false);

  // Get the filter's id from the filter component
  const [dataStyleFilter, setDataStyleFilter] = useState<number[]>([Number(styleid)].filter(Boolean));
  const [dataCategoryFilter, setDataCategoryFilter] = useState<number[]>([Number(categoryid)].filter(Boolean));
  const [dataManufactureFilter, setDataManufactureFilter] = useState<number[]>([]);

  const [sortValue, setSortValue] = useState<string>('');

  // Toggle Button of Prime
  const [isPrimeEnabled, setIsPrimeEnabled] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  // Separte the image url for the window location
  const new_url = window.location.toString();
  const newurl = new_url.split('/uploadImage/');
  // History
  const history = useHistory();

  // API Calls

  const url = !data && !fileUrl ? getFilterProductUrl(apiRoutes.PRODUCT, dataStyleFilter, dataCategoryFilter, dataManufactureFilter, sortValue, isPrimeEnabled, tags) : null;

  const [product, loading, hasMore] = useGetFetch(url ? `${url}page=${pageNumber}&page_size=100` : null, pageNumber);
  const [manufacturerList, manufacturerloading] = useGetFetch(getAPIUrl(apiRoutes.MANUFACTURER));
  const [categoryList, categoryLoading] = useGetFetch(getAPIUrl(apiRoutes.CATEGORY));
  const [styleList, styleLoading] = useGetFetch(getAPIUrl(apiRoutes.STYLE));

  // observer to track the position of last product
  const observer = useRef<IntersectionObserver | null>(null);

  const lastProductElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber((prevPageNumber: number) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const searchProducts = async (fileUrl: any) => {
    window.scroll(0, 0);
    try {
      const payload = {
        // image_url: window.location.pathname.slice(2),
        image_url: newurl[1],
      };
      setSearchedProductLoading(true);
      const searchedproducts = await SearchProducts(payload);
      setSearchedProducts(searchedproducts);
      setSearchedProductLoading(false);
    } catch (e: any) {
      errorToast(e);
    }
  };

  useEffect(() => {
    setPageNumber(1);
    styleid && setDataStyleFilter([Number(styleid)]);
    categoryid && setDataCategoryFilter([Number(categoryid)]);
  }, [page, categoryid, styleid]);

  useEffect(() => {
    if (fileUrl) {
      searchProducts(fileUrl);
    }
  }, [fileUrl]);

  const ProductPage: page[] = [
    { name: 'Product List', href: '/product-list', current: true },
  ];

  useEffect(() => {
    if (searchedProducts) {
      setShowProduct(searchedProducts);
    } else if (data) {
      setShowProduct(data);
    } else {
      setShowProduct(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, product, showProduct, searchedProducts]);

  // Redirect to Product Details Page
  const handleProduct = (productId: string) => {
    history.push({
      pathname: `/product/${productId}`,
    });
    window.scrollTo(0, 0);
  };

  // Filter image
  const [selectItem, setSelectedItem] = useState<any>({});
  const RequestProductFromAlibaba = (id: string) => {
    const item = searchedProducts.find((product: any) => product.alibaba_id === id);
    if (item) {
      window.scroll(0, 0);
      setSelectedItem(item);
      setShowAliBabaRequestModal(true);
    } else {
      throw new Error('That product is not avaiable');
    }
  };

  // Sort start here

  const handleSort = (value: string) => {
    setSortValue(value);
    setPageNumber(1);
  };

  // Filter Start Here

  // Set the selected style filter id
  const hanldeSelectStyleId = (styleids: number) => {
    setDataStyleFilter(
      (existingFilterIds) => (existingFilterIds.includes(styleids)
        ? existingFilterIds.filter((existingFilterId) => existingFilterId !== styleids)
        : [styleids, ...existingFilterIds]),
    );
    setPageNumber(1);
    // window.scroll(0, 0);
  };

  // Set the selected category filter id
  const handleSelectCategoryId = (categoryids: number) => {
    setDataCategoryFilter(
      (existingCategoryIds) => (existingCategoryIds.includes(categoryids)
        ? existingCategoryIds.filter((existingCategoryId) => existingCategoryId !== categoryids)
        : [categoryids, ...existingCategoryIds]),
    );
    setPageNumber(1);
    // window.scroll(0, 0);
  };

  // Set the selected manufacture filter id
  const handleSelectManufactureId = (manufactureids: number) => {
    setDataManufactureFilter(
      (existingManufactureIds) => (existingManufactureIds.includes(manufactureids)
        ? existingManufactureIds.filter((existingManufactureId) => existingManufactureId !== manufactureids)
        : [manufactureids, ...existingManufactureIds]),
    );
    setPageNumber(1);
    // window.scroll(0, 0);
  };

  // To Clear all the filter and set the normal product list api
  const clearAllFilter = () => {
    history.push('/product-list');
    setShowProduct(product);
    setDataCategoryFilter([]);
    setDataManufactureFilter([]);
    setDataStyleFilter([]);
  };

  return (
    <>
      <div className="pb-20">
        {/* Showing the alibaba request modal */}

        {showAliBabaRequestModal
          && (
            <div className="absolute top-0 z-50 bg-black bg-opacity-40 pt-20 min-h-screen min-w-screen">
              <div className="max-w-2xl mx-auto">
                <AliBabaRequestModal
                  item={selectItem}
                  showAliBabaRequestModal={showAliBabaRequestModal}
                  setShowAliBabaRequestModal={setShowAliBabaRequestModal}
                />
              </div>
            </div>
          )}

        {isFilterBoxVisible ? null
          : (
            <>
              <div className="px-6 md:px-12 lg:px-12">
                <Breadcrumbs pages={ProductPage} />
              </div>

              {fileUrl

                ? (
                  <div className="col-span-12 bg-black text-lemon text-center py-5">
                    <h4 className="font-serif font-semibold">
                      Prime vs. Out-of-Network
                    </h4>
                    <p className=" text-sm px-5 opacity-90 max-w-5xl mx-auto">
                      The main differences between Prime and Out-of-network are quality control,
                      selection, and the order process. Prime products are from vetted manufacturers
                      have a much higher chance of providing better quality products. Our vetted producers
                      provide 10k styles while our out-of-network producers provide over 5M style selections
                      from 100k network manufacturers.
                    </p>

                  </div>
                )
                : (
                  <div className="col-span-12 bg-black text-lemon text-center py-5">
                    <h4 className="font-serif font-semibold">
                      What is Prime?
                    </h4>
                    <p className=" text-sm px-5  opacity-90 max-w-5xl mx-auto">
                      Prime products are from vetted manufacturers. To develop our Prime section,
                      we ordered 3200 samples from 1600 different manufacturers and handpicked 200 of
                      the best producers that align with our customer service values and clothing quality.
                    </p>

                  </div>
                )}

            </>
          )}

        <div className="md:new-container lg:mt-10">
          <div className="grid grid-cols-12 gap-2 md:px-12 lg:px-12">
            <div className="col-span-12 lg:col-span-2 lg:col-start-2">
              {fileUrl && (
                <div className="col-span-12 lg:col-span-3 mb-5">
                  <div className="grid grid-cols-1">
                    <h2 className="font-serif bg-purple-400 text-black
                        font-semibold text-center text-3xl md:text-xl py-2 md:order-2 w-full "
                    >
                      Uploaded Image
                    </h2>
                    <img
                      src={newurl[1]}
                      alt="Uploaded"
                      className="border-black border-2 w-full lg:max-w-xs max-h-48
                          object-center object-cover min-w-150px min-h-150px md:rounded-t-full
                          md:order-1 md:border md:border-black
                          "
                    />
                  </div>
                </div>
              )}
              {fileUrl
                ? (
                  <div className="px-5 lg:px-0 mt-5">
                    <h4 className="font-serif font-semibold text-xl ">
                      Show only
                      <span className="ml-3">
                        <PrimeTag />
                      </span>
                    </h4>
                    <ToggleButton enabled={isPrimeEnabled} setEnabled={setIsPrimeEnabled} />

                  </div>
                )
                : null}

              {isFilterBoxVisible || fileUrl ? null
                : (
                  <div className="px-6 md:px-0 md:mt-4">
                    <FilterBox
                      categoryList={categoryList}
                      styleList={styleList}
                      manufacturerList={manufacturerList}
                      dataStyleFilter={dataStyleFilter}
                      dataCategoryFilter={dataCategoryFilter}
                      dataManufactureFilter={dataManufactureFilter}
                      isPrimeEnabled={isPrimeEnabled}
                      setIsPrimeEnabled={setIsPrimeEnabled}
                      hanldeSelectStyleId={hanldeSelectStyleId}
                      handleSelectCategoryId={handleSelectCategoryId}
                      handleSelectManufactureId={handleSelectManufactureId}
                      clearAllFilter={clearAllFilter}
                      sortValue={sortValue}
                      handleSort={handleSort}
                    />
                  </div>
                )}
            </div>

            <div className={`px-6 md:px-0 col-span-12 ${isFilterBoxVisible ? 'lg:col-start-2 lg:col-span-12' : ' lg:col-span-8 lg:col-start-5 '} `}>
              {loading || searchedProductLoading ? (
                <Loader />
              ) : (
                showProduct?.length ? (
                  <div className="grid grid-cols-12 gap-4">
                    {showProduct.slice(0, productShowLimit).map((item: productList, index: number) => (
                      <div className="col-span-6 sm:col-span-6 md:col-span-4" key={item.id}>
                        <div
                          onClick={() => !item.product_type && handleProduct(item.id)}
                          ref={showProduct.length === index + 1 ? lastProductElementRef : null}
                        >
                          <Card
                            image={item.primary_picture_url || manufacturerDefaultImg}
                            productName={item.product_type ? '' : item.name}
                            manufacturerName={item.manufacturer && item.manufacturer.business_name}
                            minQuantity={item.moq}
                            productPrice={item.wholesale_display_price && Math.round((item.wholesale_display_price / 100) * 100) / 100}
                            type="product"
                            is_prime={item.manufacturer && item.manufacturer.is_prime}
                            productType={item.product_type}
                          />
                        </div>
                        <div>
                          {item.product_type && (
                            <button
                              // @ts-ignore
                              onClick={() => RequestProductFromAlibaba(item.alibaba_id)}
                              type="button"
                              className="flex items-center border border-black rounded-full
                                text-xs bg-lemon hover:opacity-80  py-4 px-5 font-serif font-medium"
                            >
                              Request

                              Product Details
                            </button>

                          )}

                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <h2 className="text-4xl text-center font-serif font-semibold py-10">
                      No Products To Show
                    </h2>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ProductList;
