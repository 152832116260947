import NavbarLink from 'Components/atoms/NavbarLink';
import { withRouter, useHistory } from 'react-router';
import { errorToast, successToast } from 'Util/Utility';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import loginUser from 'API/Login';
import { LoginSchema } from 'validations';
import useLocalStorage from 'hooks/useLocalStorage';
import LoginPNG from 'assets/images/placeholderImages/login.png';
import ProductionLogin from 'assets/images/placeholderImages/production_signup.png';

const Login: React.FC = (props) => {
  const [, setToken] = useLocalStorage('token');

  const history = useHistory();
  const redirectToNextPage = (pathname: string) => {
    history.push(pathname);
  };

  const initialValues: LoginForm = {
    email: '',
    password: '',
  };
  const isProduction = history.location.pathname.includes('/production');
  const errorMsgTextColor = isProduction ? 'text-red-500 lg:text-black' : 'text-red-500';

  const handleLogin = async (values: LoginForm) => {
    try {
      const response = await loginUser(values);
      setToken(response.key);
      history.push(isProduction ? '/production/pilotProgram' : '/home');
      successToast("You're successfully logged in");
    } catch (e: any) {
      errorToast(e.non_field_errors && e.non_field_errors[0]);
    }
  };
  return (
    <>
      <Formik
        validationSchema={LoginSchema}
        onSubmit={handleLogin}
        initialValues={initialValues}
      >
        {() => (
          <div className="grid grid-cols-12 ">

            <div className={`col-span-12 lg:col-span-6 lg:border-2 lg:border-black flex justify-center items-center ${isProduction ? 'lg:bg-orangeShade' : 'lg:bg-purple-shade'}`}>
              <div className="new-container lg:px-24 w-full">
                <h1 className="text-4xl font-serif font-medium pl-4 pt-10">Welcome Back</h1>
                <Form className="px-4 py-48 lg:py-10">
                  <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                    {/* Email */}
                    <div className="col-span-12">
                      <Field name="email" placeholder="email*" type="email" className="inputTextField" />
                      <ErrorMessage component="p" name="email" className={`${errorMsgTextColor} text-xs ml-2`} />
                    </div>
                    {/* Password */}
                    <div className="col-span-12">
                      <Field name="password" placeholder="password*" type="password" className="inputTextField" />
                      <ErrorMessage component="p" name="password" className={`${errorMsgTextColor} text-xs ml-2`} />
                    </div>
                  </div>
                  <NavbarLink linkName="Forgot your Password ?" linkURL="/production/forgot" additionalClasses="text-base ml-3 mt-3" />
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className={` mt-4 ${isProduction ? 'smallBlackButton' : 'smallLemonButton'}`}
                    >
                      Log In
                    </button>
                  </div>
                </Form>
                <div
                  className="font-medium pb-10 text-center cursor-pointer"
                  onClick={() => redirectToNextPage(isProduction ? '/production/signup' : '/signup')}
                >
                  Don't have an account ?
                  <span className={`ml-3 font-semibold ${isProduction ? 'text-seaGreen bg-black' : 'text-purple-500 lg:text-lemon lg:bg-black'}`}>Sign up</span>
                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-6 hidden lg:block">
              <img
                src={isProduction ? ProductionLogin : LoginPNG}
                alt="Resource Login page"
                className="bg-center bg-cover w-full p-20"
              />
            </div>

          </div>
        )}
      </Formik>

    </>
  );
};

export default withRouter(Login);
