// eslint-disable-next-line import/prefer-default-export
export const apiRoutes = {
  SIGNUP: 'rest-auth/signup/',
  LOGIN: 'rest-auth/login/',
  RESET_PASSWORD: 'rest-auth/password/reset/',
  CHANGE_PASSWORD: 'rest-auth/password/reset/confirm/',
  PRODUCT: 'product/',
  MANUFACTURER: 'manufacturer/',
  REQUEST: 'request/',
  CATEGORY: 'category/',
  STYLE: 'style/',
  PRODUCT_PICTURE: 'product_picture/',
  PRODUCT_BULK_PRICING: 'product_bulk_pricing/',
  UPLOAD: 'upload/',
  SEARCH_IMAGE: 'searchImage/',
  SIZE_CHART_REQUEST: 'size_chart_request/',
  CURRENT_SHIPPING_RATE: 'https://shipping.resourcefashion.co/current-shipping-rate/',
  ORDER: 'order/',
  CHARGE: 'charge/',
  PROFILE: 'profile/self/',
  MANAGE_STRIPE_SUBSCRIPTION: 'manage_stripe_subscription/',
  DISCOUNT_COUPON: 'discount_coupon/',
  SUBSCRIBE_NEWSLETTER: 'newsletter'
};
