import ImageCarousel from 'Components/ImageCarousel';
import { ReactComponent as Cross } from '../assets/icons/cross.svg';

const ReviewRealProductPhotosModal = (props: any) => {
  const { handleReviewRealProductModal, realProductImages } = props;

  return (
    <div className="px-5">
      <div className="bg-sand px-5 py-5">
        <div className="flex justify-end pb-5">
          <Cross onClick={handleReviewRealProductModal} className="cursor-pointer" />
        </div>
        <div className='grid grid-cols-12'>
          <div className="col-span-12 lg:col-span-12">
            <div>
              <ImageCarousel
                images={realProductImages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewRealProductPhotosModal;
