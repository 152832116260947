/*
  PrivateRoute requires current user is logged in to render the route.
  In this case, current logged in is managed by Firebase Auth
*/

import { isAuthenticated } from 'API/APIHelpers';
import { Redirect, Route } from 'react-router-dom';
import { APP_REDIRECT_ROUTES } from '../../Constants';

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const URLPath: string = window.location.pathname;
  // Set this variable when user is authed.

  // const isAuthed = isAuthenticated();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const UrlRedirect = APP_REDIRECT_ROUTES.includes(URLPath)
    ? `/login?redirect_uri=${encodeURIComponent(URLPath)}`
    : '/login';

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(routeProps: any) => {
        const isProductionRoute = routeProps.location.pathname.includes('/production');
        return (
          isAuthenticated()
            ? <RouteComponent {...routeProps} />
            : (isProductionRoute ? <Redirect to="/production/login" /> : <Redirect to={UrlRedirect} />))
      }}
    />
  );
};

export default PrivateRoute;
