import createNewUser from 'API/Signup/Signup';
import { CountryDropdown } from 'react-country-region-selector';
import { useState } from 'react';
import StepsInMobile from 'Components/Steps_in_mobile';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import useLocalStorage from 'hooks/useLocalStorage';
import { useHistory, withRouter } from 'react-router';
import { errorToast, successToast } from 'Util/Utility';
import * as yup from 'yup';
import Steps from 'Components/Steps';
import 'react-phone-input-2/lib/style.css'

import LoginPNG from 'assets/images/placeholderImages/login.png';
import ProductionLogin from 'assets/images/placeholderImages/production_signup.png';
import PhoneInput from 'react-phone-input-2';

type PropsSignup = {
  history: {
    push(url: string): void;
  };
}

const phoneRegExp = /^[+]\d{1,2}\d{6,10}$/;
/*
-> /^ and $/ is for starting and ending
-> The ? mark is used for conditional formatting where before question mark is available or not it will work
-> ([+]\d{2}) this indicates that the + sign with two digits '\d{2}' here you can place digit as per country
-> after the ? mark '\d{10}' this says that the digits must be 10 of length change as per your country mobile number length
*/
const signupFormScheme = yup.object().shape({
  first_name: yup.string().required('*Required'),
  last_name: yup.string().required('*Required'),
  email: yup.string().email('Please enter valid email').required('*Required'),
  phone_number: yup.string().matches(phoneRegExp, 'Enter a valid phone number (e.g. +12125552368).').required('Required'),
  password1: yup.string().required('*Required.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password must have at least one alphabet'),
  password2: yup.string().oneOf([yup.ref('password1'), null], "Passwords don't match")
    .required('*Required'),
  brand_name: yup.string().required('*Required'),
  // Used regex for filtering the valid website
  brand_website: yup.string().notRequired(),
});
function SignUp(props: PropsSignup) {
  const [loading, setLoading] = useState<boolean>(false);
  // const [phoneValidation, setPhoneValidation] = useState<string>('');
  // const [phoneInputValue, setphoneInputValue] = useState<string>('');
  const history = useHistory();
  const redirectToNextPage = (pathname: string) => {
    history.push(pathname);
  };

  const [, setToken] = useLocalStorage('token');
  const [countryColor, setCountryColor] = useState("text-darkgrey");

  const isProduction = history.location.pathname.includes('/production');
  const errorMsgTextColor = 'text-red-500';

  const createUser = async (values: signUpFormType) => {
    try {
      setLoading(true);
      const response = await createNewUser(values);
      setToken(response.key);
      history.push(isProduction ? '/production/pilotProgram' : '/home');
      successToast("You're successfully logged in");
      setLoading(false);
    } catch (e: any) {
      setLoading(true);
      errorToast(e.email && e.email[0]);
      errorToast(e.password1 && e.password1[0]);
      errorToast(e.password2 && e.password2[0]);
      errorToast(e.username && e.username[0]);
      errorToast(e.first_name && e.first_name[0]);
      errorToast(e.last_anme && e.first_name[0]);
      errorToast(e.phone_number && e.phone_number[0]);
      setLoading(false);
    }
  };

  const initialValues: signUpFormType = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password1: '',
    password2: '',
    brand_name: '',
    brand_website: '',
    country: '',
  };
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 lg:col-span-4 hidden lg:block px-20 py-20 bg-gradient-to-tr from-purple-shade to-seaGreen">
        <Steps />
      </div>
      <div className="col-span-12 lg:col-span-4 md:hidden block">
        <StepsInMobile />
      </div>
      <div className={`col-span-12 lg:col-span-8  flex justify-center items-center relative ${isProduction ? 'lg:bg-sand' : 'lg:bg-purple-shade'}`}>
        {
          loading
            ? (
              <div className='absolute z-10 bg-black bg-opacity-30 w-full h-full flex'>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 50 50" className=' m-auto'>
                  <path fill="#ff815c" d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z">
                    <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.5s" repeatCount="indefinite" />
                  </path>
                </svg>
              </div>
            )
            : <div></div>}
        <div className="new-container lg:px-24 2xl:w-3/4 px-0 w-full h-full mt-10">
          <h1 className="text-4xl  font-FahkwangRegular font-semibold pl-4 pt-10 text-center">SIGN UP</h1>
          <Formik
            validationSchema={signupFormScheme}
            onSubmit={(values) => {
              createUser(values);
            }}
            initialValues={initialValues}
          >
            {({ values, handleChange, isSubmitting }) => (

              <Form className="sm:px-20 md:px-40 lg:px-10 xl:px-40 2xl:px-20 px-5 py-6">
                <div className="grid grid-cols-8 gap-x-6 gap-y-2 mt-3">
                  {/* First Name */}
                  <div className="col-span-4 ">
                    <Field name="first_name" placeholder="first name*" type="text" className="inputTextField pl-5  py-3 text-sm" />
                    <ErrorMessage component="p" name="first_name" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  {/* Last Name */}
                  <div className="col-span-4">
                    <Field name="last_name" placeholder="last name*" type="text" className="inputTextField pl-5  py-3 text-sm" />
                    <ErrorMessage component="div" name="last_name" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  {/* Email */}
                  <div className="col-span-8">
                    <Field name="email" placeholder="email*" type="email" className="inputTextField pl-5  py-3 text-sm" />
                    <ErrorMessage component="div" name="email" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  {/* Phone Number */}
                  <div className="col-span-8">
                    <PhoneInput
                      country={'us'}
                      placeholder="phone*"
                      inputClass="phoneInputField w-100"
                      buttonClass="country-dropdown "
                      value={values.phone_number}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => handleChange(e)}
                      autoFormat={false}
                      inputProps={{
                        name: 'phone_number',
                        required: true,
                      }}
                    />
                    <ErrorMessage component="div" name="phone_number" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  <div className="col-span-8 ">
                    <Field name="password1" placeholder="password*" type="password" className="inputTextField pl-5  py-3 text-sm" />
                    <ErrorMessage component="div" name="password1" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  {/* Re-enter Password */}
                  <div className="col-span-8">
                    <Field name="password2" placeholder="re-enter password*" type="password" className="inputTextField pl-5  py-3 text-sm" />
                    <ErrorMessage component="div" name="password2" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  {/* Brand Name */}
                  <div className="col-span-8">
                    <Field name="brand_name" placeholder="brand name*" type="text" className="inputTextField pl-5  py-3 text-sm" />
                    <ErrorMessage component="div" name="brand_name" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  {/* Brand Website */}
                  <div className="col-span-8">
                    <Field name="brand_website" placeholder="brand website" type="text" className="inputTextField pl-5  py-3 text-sm" />
                    <ErrorMessage component="div" name="brand_website" className={`${errorMsgTextColor} text-xs ml-2`} />
                  </div>
                  {/* Country */}
                  <div className="col-span-4">
                    <CountryDropdown classes={`inputTextField pl-5 ${countryColor} py-3 text-sm`} name="country" value={values.country} onChange={(_, e) => { handleChange(e); setCountryColor("text-black") }} />
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className={` mt-4 ${isProduction ? 'smallBlackButton' : 'smallLemonButton'}`}
                  >
                    Sign Up
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div
            className="font-medium pt-5 pb-10 text-center cursor-pointer"
            onClick={() => redirectToNextPage(isProduction ? '/production/login' : '/login')}
          >
            Already have an account ?
            <span className={`ml-3 font-semibold ${isProduction ? 'text-seaGreen bg-black' : 'text-purple-500 lg:text-lemon lg:bg-black'}`}>Log in</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SignUp);
