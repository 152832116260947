import Modal from './Modal';

type MenuBarProps={
  open:boolean,
  setOpen: (value: boolean) => void;
  children:any
  positionClasses?:string

}

const MenuBar:React.FC<MenuBarProps> = (props) => {
  const {
    open, setOpen, children, positionClasses,
  } = props;
  return (
    <Modal open={open} setOpen={setOpen} positionClasses={positionClasses}>
      {children}
    </Modal>
  );
};
export default MenuBar;
