/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { ReactComponent as UpwardArrow } from '../assets/icons/upward-arrow.svg';
import { ReactComponent as DownwardArrow } from '../assets/icons/downward-arrow.svg';

type AccordianType = {
  heading: JSX.Element | string;
  headingClass?: string;
  preOpen?: boolean;
  h1Class?:string
};

const Accordion: React.FC<AccordianType> = (props) => {
  const {
    heading, headingClass, preOpen, h1Class,
  } = props;

  const [clicked, setClicked] = useState<boolean>(preOpen || false);

  useEffect(() => {
    if ('preOpen' in props) {
      setClicked(Boolean(preOpen));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preOpen]);
  return (
    <div className="acc_container">
      <div role="button" className={`wrap ${headingClass}`} onClick={() => setClicked(!clicked)}>
        <h1 className={h1Class}>
          {heading}
        </h1>
        <span>
          {clicked === false ? (
            <DownwardArrow className="h-5 w-5" />
          ) : (
            <UpwardArrow className="h-5 w-5" />
          )}
        </span>
      </div>
      {clicked === true ? (
        <>
          {props.children}
        </>
      ) : null}
    </div>

  );
};

export default Accordion;
