/* eslint-disable react/button-has-type */
/* eslint-disable no-self-compare */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import {
  useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useGetFetch from 'hooks/useGetFetch';
import { getAPIUrl, getAuthHeaders, Loader } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';

import CircularBtn from 'Components/atoms/CircularBtn';
import CheckoutProductCard from 'Components/atoms/CheckoutProductCard';

import StripeIcon from 'assets/icons/stripe.png';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as Venmo } from 'assets/icons/venmo.svg';
import { ReactComponent as Paypal } from 'assets/icons/paypal.svg';
import { ReactComponent as Zelle } from 'assets/icons/zelle.svg';
import { ReactComponent as CartQuestion } from 'assets/icons/cart_question.svg';
import { errorToast } from 'Util/Utility';

const Cart = () => {
  const history = useHistory();

  const [cartState, setCartState] = useState(JSON.parse(localStorage.getItem('cartItem') || '[]'));

  const [amount, setAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [weight, setWeight] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountCode, setDiscountCode] = useState('');

  const [isEditPromoCode, setIsEditPromoCode] = useState<boolean>(true);
  const [promoCode, setPromoCode] = useState<string>('');
  const [promoUserName, setPromoUserName] = useState<string>('');
  const [paymentApp, setPaymentApp] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [estimatedAmountInDollar, setEstimatedAmountInDollar] = useState(0);

  const [shippingCharges, loading] = useGetFetch(cartState && cartState.length && weight ? `${apiRoutes.CURRENT_SHIPPING_RATE + weight}` : null, undefined, false, true);

  const { price_in_usd: shippingPriceInDollar } = shippingCharges;

  const shippingPriceInCent = (Math.round((Math.round(shippingPriceInDollar * 100) / 100) * 100)) || 0;

  const getTotalOrderAmount = useCallback(() => {
    const totalOrderAmount = cartState.reduce((previousValue: any, currentValue: any) => previousValue + currentValue.amount, 0);
    return totalOrderAmount;
  }, [cartState]);

  const getTotalOrderWeight = useCallback(() => {
    const totalOrderWeight = cartState.reduce((previousValue: any, currentValue: any) => previousValue + currentValue.totalProductWeight, 0);
    return totalOrderWeight;
  }, [cartState]);

  const handleCoupon = async (promocode:string) => {
    if (promoCode) {
      const couponDetails = {
        code: promocode,
        amount,
      };
      try {
        const headers = getAuthHeaders();
        const res = await fetch(getAPIUrl(apiRoutes.DISCOUNT_COUPON), {
          method: 'POST',
          headers,
          body: JSON.stringify(couponDetails),
        });
        const response = await res.json();
        if (res.status === 404) {
          setErrorMessage('Invalid Coupon Code');
        } else if (res.status >= 400) {
          setErrorMessage(response && response.detail || response.order[0]);
          setIsEditPromoCode(true);
          setDiscountPrice(0);
          setFinalAmount(0);
        } else {
          setErrorMessage('');
          setIsEditPromoCode(false);
          setAmount(response.amount);
          setDiscountPrice(response.discount_amount);
          setDiscountCode(response.code);
          setFinalAmount(response.final_amount);
          localStorage.setItem('discount_uuid', JSON.stringify(response.id));
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  useEffect(() => {
    setAmount(getTotalOrderAmount());
    setWeight(getTotalOrderWeight());
  }, [cartState, getTotalOrderWeight, getTotalOrderAmount]);

  useEffect(() => {
    localStorage.setItem('cartItem', JSON.stringify(cartState));
    setPromoCode('');
    setIsEditPromoCode(true);
    setDiscountPrice(0);
    setFinalAmount(0);
    setErrorMessage('');
  }, [cartState]);

  useEffect(() => {
    localStorage.setItem('amount', JSON.stringify(finalAmount || amount));
    localStorage.setItem('shippingCharges', JSON.stringify(shippingPriceInCent));
    localStorage.setItem('totalAmount', JSON.stringify((finalAmount || amount) + shippingPriceInCent));
    setEstimatedAmountInDollar((finalAmount || discountPrice ? ((finalAmount / 100) + (shippingPriceInDollar)) : ((amount / 100) + (shippingPriceInDollar))));
  }, [amount, shippingPriceInCent, finalAmount, discountPrice]);

  const handleUpdateCart = (productSku: sku[], productId: string, totalProductAmount: number, totalProductWeight: number) => {
    const updatedCart = cartState.map(
      (cartItem: any) => (
        cartItem.id === productId
          ? {
            ...cartItem,
            order_items: productSku,
            amount: totalProductAmount,
            totalProductWeight,
          }
          : cartItem
      ),
    );
    setCartState(updatedCart);
  };

  const handleDeleteCart = (selectedCartItemItndex: number) => {
    const updatedCart = cartState.filter((cartState: any, index: number) => index !== selectedCartItemItndex);
    setCartState(updatedCart);
  };

  const handlePageRedirect = (pathname: string) => {
    localStorage.setItem('promoCode', promoCode);
    localStorage.setItem('promoUserName', promoUserName);
    localStorage.setItem('paymentApp', paymentApp);
    history.push(pathname);
  };

  const handlePromoCode = (event: any) => {
    setPromoCode(event?.target.value);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="grid grid-cols-12 new-container py-5">
      <div className="col-span-12 ">

        <button
          onClick={() => history.push('/product-list')}
          type="button"
          className="font-sans text-sm flex"
        >
          <ChevronLeft className="mr-2  " />
          back to marketplace
        </button>

        <h1 className="text-3xl font-serif font-medium py-5">My Cart</h1>
      </div>

      <div className="col-span-12  md:col-span-8">

        {/* Cart product */}
        {cartState && cartState.length ? (
          <div className="px-4">
            {cartState.map((item: any, index: number) => (
              <div key={index} className="mb-4 pt-3 pb-6 border-b border-gray-500">
                <CheckoutProductCard
                  id={item.id}
                  productName={item.name}
                  manufacturerName={item.manufacturer}
                  color={item.name}
                  image={item.image.image}
                  orderItems={item.order_items}
                  handleUpdateCart={handleUpdateCart}
                  handleDeleteCart={handleDeleteCart}
                  moq={item.moq}
                  prices={item.prices}
                  individualProductWeight={item.individualProductWeight}
                  cartItemIndex={index}
                  bigImage
                />
              </div>
            ))}
          </div>
        )
          : (
            <div
              className="my-5 text-center text-xl font-serif"
            >
              No items in your cart
            </div>
          )}
      </div>

      <div className="col-span-12 md:col-span-4">
        {/* Promo code */}
        <div className="mt-2 pb-3">
          <h2 className="font-medium bg-black text-lemon py-4 px-10 font-serif  ">Promo Code</h2>
          <div className="px-5 font-serif py-5">
            {isEditPromoCode
              ? <input className="inputTextField" placeholder="enter code" type="text" value={promoCode} onChange={handlePromoCode} />
              : (
                <h5>
                  Coupon Applied:
                  <span className="font-semibold ml-4">{promoCode}</span>
                </h5>
              )}

            {isEditPromoCode ? (
              <button
                className="hover:bg-yellow-100 pb-2 pt-3 mx-auto pr-7 pl-7 text-center rounded-full
                border border-black font-serif font-semibold flex items-center justify-center"
                onClick={() => handleCoupon(promoCode)}
              >
                Apply
              </button>
            ) : (
              <button
                className="hover:bg-yellow-100 pb-2 pt-3 mx-auto pr-7 pl-7 text-center rounded-full
              border border-black font-serif font-semibold flex items-center justify-center mt-3"
                onClick={() => setIsEditPromoCode(true)}
              >
                Edit
              </button>
            )}

            {errorMessage
              ? <h6 className="text-red-500 mt-4">{errorMessage}</h6> : null}
            {/* <div className="flex justify-between py-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-black focus:ring-black w-6 h-6 border-2 rounded-full border-black bg-sand mr-1"
                  name="paymentMethod"
                  value="venmo"
                  onChange={() => setPaymentApp('venmo')}
                />
                <Venmo />
              </label>

              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-black focus:ring-black w-6 h-6 border-2 rounded-full border-black bg-sand mr-1"
                  name="paymentMethod"
                  value="paypal"
                  onChange={() => setPaymentApp('paypal')}
                />
                <Paypal />
              </label>

              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-black focus:ring-black w-6 h-6 border-2 rounded-full border-black bg-sand mr-1"
                  name="paymentMethod"
                  value="stripe"
                  onChange={() => setPaymentApp('stripe')}
                />
                <img src={StripeIcon} alt="Stripe Payment Method" />
              </label>

              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-black focus:ring-black w-6 h-6 border-2 rounded-full border-black bg-sand mr-1"
                  name="paymentMethod"
                  value="zelle"
                  onChange={() => setPaymentApp('zelle')}
                />
                <Zelle />
              </label>
            </div>
            <div>
              <input className="inputTextField" placeholder="enter username" type="text" value={promoUserName} onChange={handlePromoUserName} />
              <div className="px-5 flex">
                <CartQuestion />
                <p className="font-sans text-xs italic  ">
                  We will send your discount amount as a
                  refund through your selected digital payment app.
                </p>
              </div>
            </div> */}

          </div>
        </div>

        {/* Subtotal Card */}
        <div className="mt-2 bg-gray-200 pb-3 border border-black">
          <h2 className="font-medium bg-black text-lemon py-4 px-10 font-serif  ">Order Summary</h2>
          <div className="pl-10 pr-3 font-serif py-3">
            <div className="flex ">
              <h5 className="flex-1 text-left">Subtotal:</h5>
              {cartState && <h5 className="pr-4 text-right">{`$${amount / 100}`}</h5>}
            </div>

            {discountPrice && discountCode
              ? (
                <div className="flex text-red-500">
                  <h5 className="flex-1 text-left">Discount Price:</h5>
                  <h5 className="pr-4 text-right">{`- $${(Math.round(discountPrice) / 100) || 0}`}</h5>
                </div>
              )
              : null}
            <div className="flex">
              <h5 className="flex-1 text-left">Shipping & Taxes estimate:</h5>
              <h5 className="pr-4 text-right">{`$${(Math.round(shippingPriceInDollar * 100) / 100) || 0}`}</h5>
            </div>

            <div className="flex font-semibold border-black border-t pt-3">
              <h5 className="flex-1 text-left">Estimated Total:</h5>
              <h5 className="pr-4 text-right">{`$${Math.round(estimatedAmountInDollar * 100) / 100}`}</h5>
            </div>
            <CircularBtn
              text="Checkout"
              // onClick={() => handlePageRedirect('/stripe', 100, '12345')}
              // round to two decimal places then convert dollar into cent
              onClick={() => handlePageRedirect('/checkout')}
              additionalClasses="font-serif border border-black bg-lemon rounded-full w-full mt-4  text-center py-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
