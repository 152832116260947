import { useState } from 'react';
import { withRouter } from 'react-router';

import Tab from 'Components/Tab';
import { faqs_production } from 'Constants';

import Accordion from 'Components/Accordian';

import { ReactComponent as FaqText } from 'assets/images/placeholderImages/production-faq-text.svg';

const ProductionFaq: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  // Highlight the selected Tab Function

  const selectedTabsFn = (condition: boolean) => (condition ? 'bg-black text-seaGreen py-2 pt-2.5' : ' pt-3 bg-white ');
  // Set Tab Active onCLick
  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  };
  return (
    <div className="new-contianer p-8">
      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-6 border-2 border-black bg-seaGreen py-10 order-2 lg:order-1  ">
          <div className="p-5">
            <h1 className="text-4xl font-semibold font-serif uppercase pl-4 pb-4">
              {faqs_production[activeTab].heading}
            </h1>
            {faqs_production[activeTab].info.map((faqInfo) => (
              <div key={faqInfo.question}>
                <Accordion
                  heading={faqInfo.question}
                  headingClass=" text-black text-left  font-bold text-lg pt-4 px-2 border-t-2 border-black py-4 font-serif "
                >
                  <div className="px-4 pb-5" dangerouslySetInnerHTML={{ __html: faqInfo.answer }} />

                </Accordion>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 bg-productionFaqImage bg-no-repeat bg-center bg-cover w-full text-center order-1 lg:order-2
        min-h-472px lg:min-h-90screen border-t-2 lg:border-b-2 border-r-2 border-l-2 lg:border-l-0 border-black "
        >
          <div className="flex flex-col justify-center items-center mt-20 lg:mt-40 ">
            <FaqText />

            <ul className="lg:block pl-6 md:pr-1 pt-3 pb-4 overflow-auto lg:justify-around ">
              {
              ['Basics', 'Sustainability and Ethics', 'Agents vs RE(SOURCE)'].map((tab: string, index: number) => (
                <div key={index}>
                  <Tab
                    style={`border-2 border-black rounded-full text-center my-5 font-light px-2.5 tracking-tighter ${selectedTabsFn(activeTab === index)}`}
                    onTabClick={onTabClick}
                    currentTab={index}
                  >
                    <div className="cursor-pointer">
                      <div className="text-md font-serif font-semibold whitespace-nowrap text-center px-5 w-full">{tab}</div>
                    </div>
                  </Tab>
                </div>
              ))
            }
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};

export default withRouter(ProductionFaq);
