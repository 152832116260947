import { isEmpty } from 'Util/Utility';
import { API_BASE_URL } from './APIConstants';

export function isAuthenticated() {
  const token = localStorage.getItem('token');
  if (!isEmpty(token)) {
    return true;
  }
  return false;
}

export const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return headers;
};

export const getAuthHeaders = () => {
  const token = localStorage.getItem('token');

  if (isEmpty(token)) {
    throw 'Token not found';
  }

  const headers = {
    'Content-Type': 'application/json',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Authorization: `Token ${JSON.parse(token)}`,
  };
  return headers;
};

export function getAPIUrl(endpoint: string) {
  return API_BASE_URL + endpoint;
}

export function getStripeApiUrl(prefilledEmail = false) {
  let stripeUrl = process.env.REACT_APP_STRIPE_URL;
  if (prefilledEmail) {
    stripeUrl += '?prefilled_email=' + prefilledEmail;
  }
  return stripeUrl;
}

export function getFilterProductUrl(
  endpoint: string,
  dataStyleFilter: number[] | any,
  dataCategoryFilter: number[] | any,
  dataManufactureFilter: number[] | any,
  sortValue: string,
  isPrimeEnabled:boolean,
  tags:string| null,
) {
  const base_url = getAPIUrl(endpoint);
  return `${base_url}?${dataStyleFilter.length ? `style=${dataStyleFilter}&` : ''}${dataCategoryFilter.length ? `category=${dataCategoryFilter}&` : ''}${dataManufactureFilter.length ? `manufacturer=${dataManufactureFilter}&` : ''}${sortValue ? `sort_price=${sortValue}&` : ''}${tags ? `tags=${tags}&` : ''}${isPrimeEnabled ? `is_prime=${isPrimeEnabled}&` : ''}`;
}

export function Loader() {
  return (
    <div className="-mt-8 h-screen flex justify-center items-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500" />
    </div>
  );
}
