import { getAuthHeaders, getHeaders } from 'API/APIHelpers';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

function useGetFetch(url: any, page?: number, authRequest = false, charge = false): [any, any, boolean] {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(false);
  useEffect(() => {
    // add loader to specific api call
    if (page === 1) setLoading(true);
    const headers = authRequest ? getAuthHeaders() : getHeaders();

    async function fetchGet() {
      const apiParams = { method: 'GET', headers };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (charge) delete apiParams.headers;
      const res = await fetch(url, apiParams);
      if (res.status >= 400) {
        setData([]);
      } else {
        const json = await res.json();
        if (page) {
          setData((prevData) => page === 1 ? json.results : [...prevData, ...json.results]);
          setHasMore(json.count > json.results.length);
        } else {
          setData(json);
        }
      }
      setLoading(false);
    }
    if (!isEmpty(url)) {
      fetchGet();
    } else {
      setData([]);
      setLoading(false);
    }
  }, [url, authRequest, page, charge]);

  return [data, loading, hasMore];
}
export default useGetFetch;
