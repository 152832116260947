import React from 'react';
import Tag from './atoms/Tag';

type TagsInput = {
  ethicalProduction?: string;
  sustainableSourcing?: string;
  style?: string;
};

const Tags: React.FC<TagsInput> = (props) => {
  const { ethicalProduction, sustainableSourcing, style } = props;
  return (
    <div className='flex flex-wrap'>
      {
        ethicalProduction && (
          <div className='mr-3'>
            <Tag tagName='Ethical trade' style={style} />
          </div>
        )
      }
      {
        sustainableSourcing && (
          <Tag tagName='Sustainable' style={style} />
        )
      }
    </div>
  );
};
export default Tags;
