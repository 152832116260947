import { getAPIUrl, getHeaders } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';

export default async function loginUser(payload: LoginForm) {
  const headers = getHeaders();
  try {
    const res = await fetch(getAPIUrl(apiRoutes.LOGIN), {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function resetPassword(payload: LoginForm) {
  const headers = getHeaders();
  try {
    const res = await fetch(getAPIUrl(apiRoutes.RESET_PASSWORD), {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function resetNewPassword(payload: any) {
  const headers = getHeaders();
  try {
    const res = await fetch(getAPIUrl(apiRoutes.CHANGE_PASSWORD), {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    if (res.status >= 400) {
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function subscribeNewsletter(payload: string) {
  try {
    const headers = getHeaders();
    const raw = JSON.stringify({
      email: payload,
    });
    const res = await fetch(getAPIUrl(apiRoutes.SUBSCRIBE_NEWSLETTER), {
      method: "POST",
      headers,
      body: raw,
    });
    if (res.status >= 400) {
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

