import Accordion from 'Components/Accordian';
import PrimeTag from 'Components/atoms/PrimeTag';
import ToggleButton from 'Components/atoms/ToggleButton';
import Modal from 'Components/Modal';
import { SortData } from 'Constants';
import React, { useState } from 'react';
import { ReactComponent as CloseButton } from '../assets/icons/navCloseIcon.svg';

type filterProps = {
  showModal: boolean;
  categoryList: categoryTypes[]
  styleList: categoryTypes[],
  isPrimeEnabled: boolean,
  setIsPrimeEnabled: (value: boolean) => void;
  setShowModal: (value: boolean) => void;
  sortValue: string;
  handleSort: (value: string) => void;
  manufacturerList?: any

  dataStyleFilter: number[]
  dataCategoryFilter: number[]
  dataManufactureFilter: number[]

  hanldeSelectStyleId: any
  handleSelectCategoryId: any
  handleSelectManufactureId: any
  clearAllFilter: any
};

const Sort: React.FC<filterProps> = (props) => {
  const {
    showModal, setShowModal, styleList, categoryList, manufacturerList,
    isPrimeEnabled, setIsPrimeEnabled, clearAllFilter,
    dataStyleFilter, dataCategoryFilter, dataManufactureFilter,
    hanldeSelectStyleId, handleSelectCategoryId, handleSelectManufactureId, sortValue, handleSort,

  } = props;
  const [filterData, setFilterData] = useState(SortData);
  // const handleClearBtn = () => {
  //   filterData.forEach((item: filter, index: number) => {
  //     item.values.forEach((value: filterValues, i: number) => {
  //       item.values[i].selected = false;
  //     });
  //   });
  //   setFilterData([...filterData]);
  //   setShowModal(false);
  // };

  const handleClearAll = () => {
    setShowModal(false);
    clearAllFilter();
  };

  return (
    <>
      {/* TODO -  */}
      {/* <Modal open={showModal} setOpen={setShowModal}> */}
      <div className="min-h-screen max-h-screen overflow-scroll min-w-screen bg-sand absolute top-0 left-0 z-50">
        <div className="bg-purple-300 h-14 p-4 border border-black">
          <CloseButton onClick={() => setShowModal(false)} className="h-5 w-5 ml-auto mr-3.5 cursor-pointer" />
        </div>
        {/* Sorting Filter */}
        <div className="border-b border-black">
          <div>
            <Accordion
              heading="Sort"
              headingClass="pl-10 pr-5 py-5  font-serif font-semibold"
            >
              {SortData.map((item: filterValues, index: number) => (
                <div className="wrap pb-3 pl-10 cursor-pointer" key={index}>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-black focus:ring-black
                        w-6 h-6 border-2  border-black"
                      name="sort-radio"
                      value={item.value}
                      onChange={() => handleSort(item.value)}
                      defaultChecked={sortValue === item.value}
                    />
                    <span className="ml-4 text-lg">{item.name}</span>
                  </label>
                </div>
              ))}
            </Accordion>
          </div>
        </div>
        {/* Categories */}
        <div className="border-b border-black">
          <Accordion heading="Categories" headingClass="pl-10 pr-5 py-5 font-serif font-semibold">
            {categoryList.map((item: categoryTypes) => (
              <div className="wrap pb-3 pl-10 " key={item.id}>
                <label className="inline-flex items-center" htmlFor={item.name}>
                  <input
                    id={item.name}
                    type="checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectCategoryId(item.id);
                    }}
                    className="form-radio text-black focus:ring-black w-6 h-6 border-2  border-black bg-sand"
                    name="radio-colors"
                    value={item.name}
                    checked={dataCategoryFilter.includes(item.id)}
                  />
                  <span className="ml-4 text-lg">{item.name}</span>
                </label>
              </div>
            ))}
          </Accordion>
        </div>
        {/* Style */}
        <div className="border-b border-black">
          <Accordion heading="Style" headingClass="pl-10 pr-5 py-5  font-serif font-semibold">
            {styleList.map((item: categoryTypes) => (
              <div
                className="wrap pb-3 pl-10 "
                key={item.id}
              >
                <label
                  className="inline-flex items-center"
                  htmlFor={item.name}
                >
                  <input
                    id={item.name}
                    type="checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                      hanldeSelectStyleId(item.id);
                    }}
                    className="form-radio text-black focus:ring-black w-6 h-6 border-2  border-black bg-sand"
                    name="radio-colors"
                    checked={dataStyleFilter.includes(item.id)}
                    value={item.name}
                  />
                  <span className="ml-4 text-lg">{item.name}</span>
                </label>
              </div>
            ))}
          </Accordion>

        </div>
        {/* Manufacturer List */}
        <div className="border-b border-black ">
          <Accordion heading="Manufacturer" headingClass="pl-10 pr-5 py-5  font-serif font-semibold">
            <div className="max-h-472px overflow-scroll border border-black w-10/12 mx-auto mb-10">
              {manufacturerList.map((item: any) => (
                <div className="wrap py-3 pl-5" key={item.id}>
                  <label className="inline-flex text-left" htmlFor={item.id}>
                    <input
                      id={item.id}
                      type="checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectManufactureId(item.id);
                      }}
                      className="form-radio text-black focus:ring-black w-6 h-6 border-2  border-black bg-sand"
                      name="radio-colors"
                      value={item.business_name}
                      checked={dataManufactureFilter.includes(item.id)}
                    />
                    <span className="ml-4 text-lg">{item.business_name}</span>
                  </label>
                </div>
              ))}
            </div>

          </Accordion>

        </div>
        <div className="pl-10 pr-5 py-5 text-left">
          <h4 className="font-serif font-semibold text-xl ">
            Show only
            <span className="ml-3">
              <PrimeTag />
            </span>
          </h4>
          <ToggleButton enabled={isPrimeEnabled} setEnabled={setIsPrimeEnabled} />

        </div>
        <div className="m-8 ">
          <button
            onClick={() => setShowModal(false)}
            type="button"
            className="border-3xl border border-black m-11text-xl text-black font-serif font-semibold bg-purple-300 text-center py-3  rounded-3xl w-full "
          >
            Apply Filter
          </button>
        </div>

        <div className="m-8 ">
          <button
            onClick={() => handleClearAll()}
            type="button"
            className="border-3xl border text-xl text-yellow-200 font-serif font-semibold bg-black text-center py-3  rounded-3xl w-full "
          >
            Clear
          </button>
        </div>
      </div>
      {/* </Modal> */}
    </>
  );
};

export default Sort;
