import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type ModalPropsTypes = {
  open:boolean,
  setOpen: (value: boolean) => void;
  children:any
  positionClasses?:string
}

const Modal:React.FC<ModalPropsTypes> = (props) => {
  const {
    open, setOpen, children, positionClasses,
  } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-20 overflow-y-auto border border-black top-18  ${positionClasses || 'lg:top-0 bg-black bg-opacity-40'}`}
        onClose={() => setOpen(false)}
      >
        <div className="flex items-end justify-center  text-center sm:block sm:p-0 ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="lg:hidden  sm:inline-block  " aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
