import { ElementsConsumer } from '@stripe/react-stripe-js';
import React from 'react';
import PayWithCard from './PayWithCard';

class CheckoutForm extends React.Component {
  render() {
    return (
      <div>
        <PayWithCard handleSubmit={this.handleSubmit} stripe={!this.props.stripe} />
      </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => <CheckoutForm stripe={stripe} elements={elements} />}
    </ElementsConsumer>
  );
}
