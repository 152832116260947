import { useState } from 'react';
import RequestProduct from 'API/RequestProduct';
import { errorToast, successToast } from 'Util/Utility';
import { ReactComponent as Cross } from '../assets/icons/cross.svg';

const AliBabaRequestModal = (props:any) => {
  const [showConfirm, setSetConfirm] = useState<boolean>(false);
  const { item, showAliBabaRequestModal, setShowAliBabaRequestModal } = props;
  // Get a request from user for ailbaba product
  const RequestProductFromAlibaba = async (item: productList) => {
    try {
      const requestedProduct = {
        alibaba_id: item.alibaba_id,
        address: item.address,
        name: item.name,
        moq: item.moq,
        image_url: item.primary_picture_url,
      };
      const response = await RequestProduct(requestedProduct);
      if (response.alibaba_id) {
        setShowAliBabaRequestModal(false);
        successToast('Your request has been successfully sent');
      }
    } catch (e: any) {
      errorToast('Please Signup to raise the request details of products.');
    }
  };
  return (
    <div className="px-5 ">
      <div className="bg-sand px-5 py-5">
        <div className="flex justify-between">
          <h2 className="font-serif font-semibold text-xl">Detail Request</h2>
          <Cross onClick={() => setShowAliBabaRequestModal(false)} className="cursor-pointer" />
        </div>
        <div className="text-left text-xs my-3">
          <p>
            Since this item is produced by an out-of-network manufacturer,
            we will need to request the full product details before you can place an order.
          </p>
          <p className="font-semibold mt-3">
            Please confirm that you would like to request
            the product details for the following item:
          </p>
        </div>
        <div>
          <img
            src={item.primary_picture_url}
            alt={item.name}
            className="mx-auto max-h-150px max-w-150px min-h-150px min-w-150px"
          />
        </div>
        <div className="flex justify-between mt-4">
          <div className="w-full ">
            <button
              type="button"
              className="bg-black text-lemon text-center py-3 font-serif px-12 rounded-full  text-xs"
              onClick={() => setShowAliBabaRequestModal(false)}
            >
              Cancel
            </button>
          </div>

          <button
            type="button"
            className="bg-lemon border border-black text-black text-center pt-2 pb-2
            font-serif px-5 rounded-full whitespace-nowrap text-xs"
            onClick={() => RequestProductFromAlibaba(item)}
          >
            Submit Request
          </button>

        </div>
      </div>
    </div>

  );
};

export default AliBabaRequestModal;
