import { useState } from 'react';
import RequestProduct from 'API/RequestProduct';
import { errorToast, successToast } from 'Util/Utility';
import { ReactComponent as Cross } from '../assets/icons/cross.svg';

const RequestSizeChartModal = (props: any) => {
  const { handleRequestSizeChartModal, sendSizeChatRequest } = props;

  return (
    <div className="px-5 ">
      <div className="bg-sand px-5 py-5">
        <div className="flex justify-between">
          <h2 className="font-serif font-semibold text-xl">Request Size Chart</h2>
          <Cross onClick={handleRequestSizeChartModal} className="cursor-pointer" />
        </div>
        <div className="text-left text-xs my-3">
          <p className="font-semibold mt-3">
            Would you like to request the size chart of the product? Once requested, the information shall be delivered to your inbox within one business day.
          </p>
        </div>
        <div className="flex justify-between mt-4">
          <div className="w-full ">
            <button
              type="button"
              className="bg-black text-lemon text-center py-3 font-serif px-12 rounded-full  text-xs"
              onClick={handleRequestSizeChartModal}
            >
              Cancel
            </button>
          </div>

          <button
            type="button"
            className="bg-lemon border border-black text-black text-center pt-2 pb-2
            font-serif px-5 rounded-full whitespace-nowrap text-xs"
            onClick={sendSizeChatRequest}
          >
            Confirm
          </button>

        </div>
      </div>
    </div>

  );
};

export default RequestSizeChartModal;
