import { useState } from 'react';
import { SortData } from 'Constants';
import Sort from 'pages/Sort';
import PrimeTag from './atoms/PrimeTag';
import ToggleButton from './atoms/ToggleButton';

type FilterBoxTypes={
  categoryList:categoryTypes[]
  styleList:categoryTypes[],
  manufacturerList?:any
  dataStyleFilter:number[]
  dataCategoryFilter:number[]
  dataManufactureFilter:number[]
  isPrimeEnabled:boolean
  setIsPrimeEnabled: (value: boolean) => void;
  hanldeSelectStyleId:any
  handleSelectCategoryId:any
  handleSelectManufactureId:any
  clearAllFilter:any
  sortValue: string
  handleSort: (value: string) => void;
}

const FilterBox:React.FC<FilterBoxTypes> = (props) => {
  const {
    categoryList, styleList, manufacturerList, clearAllFilter,
    dataStyleFilter, dataCategoryFilter, dataManufactureFilter, isPrimeEnabled,
    hanldeSelectStyleId, handleSelectCategoryId, handleSelectManufactureId, setIsPrimeEnabled, sortValue, handleSort,

  } = props;

  const [showModal, setShowModal] = useState(false);
  const [filterData, setFilterData] = useState();

  return (
    <div>
      {showModal && (
      <Sort
        showModal={showModal}
        setShowModal={setShowModal}
        categoryList={categoryList}
        styleList={styleList}
        manufacturerList={manufacturerList}
        isPrimeEnabled={isPrimeEnabled}
        setIsPrimeEnabled={setIsPrimeEnabled}
        dataStyleFilter={dataStyleFilter}
        dataCategoryFilter={dataCategoryFilter}
        dataManufactureFilter={dataManufactureFilter}
        hanldeSelectStyleId={hanldeSelectStyleId}
        handleSelectCategoryId={handleSelectCategoryId}
        handleSelectManufactureId={handleSelectManufactureId}
        clearAllFilter={clearAllFilter}
        sortValue={sortValue}
        handleSort={handleSort}
      />
      )}

      <div className="lg:hidden">
        <div className=" pt-3 ">
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="bg-lemon font-serif font-medium rounded-full border border-black px-3 mr-3 pt-1"
          >
            Sort
          </button>
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="bg-lemon font-serif font-medium rounded-full border border-black px-3 pt-1"
          >
            Filter
          </button>
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="">
          <h4 className="font-serif font-semibold text-xl ">
            Show only
            <span className="ml-3">
              <PrimeTag />
            </span>
          </h4>
          <ToggleButton enabled={isPrimeEnabled} setEnabled={setIsPrimeEnabled} />

        </div>

        {/* Sorting Filter */}
        <div>
          <h2 className="font-serif font-semibold mt-10 mb-4 text-xl">
            Sort
          </h2>
          {SortData.map((item: filterValues) => (
            <div className="wrap pb-3 cursor-pointer" key={item.id}>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-black focus:ring-black bg-sand
                  w-6 h-6 border-2 border-black"
                  name="sort-radio"
                  value={item.value}
                  onChange={() => handleSort(item.value)}
                  defaultChecked={sortValue === item.value}
                />
                <span className="text-base ml-3 text-left whitespace-nowrap">{item.name}</span>
              </label>
            </div>
          ))}
        </div>
        {/* Categories */}
        <div>
          <h2 className="font-serif font-semibold mt-10 mb-4 text-xl">
            Categories
          </h2>
          {categoryList.map((item:categoryTypes) => (
            <div className="wrap pb-3 pr-2" key={item.id}>
              <label className="inline-flex items-center" htmlFor={item.name}>
                <input
                  id={item.name}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectCategoryId(item.id);
                  }}
                  className="form-radio text-black focus:ring-black w-6 h-6 border-2  border-black bg-sand"
                  name="radio-colors"
                  value={item.name}
                  checked={dataCategoryFilter.includes(item.id)}
                />
                <span className="ml-4 text-base text-left whitespace-nowrap">{item.name}</span>
              </label>
            </div>
          ))}
        </div>
        {/* Style */}
        <div>
          <h2 className="font-serif font-semibold mt-10 mb-4 text-xl">
            Style
          </h2>
          {styleList.map((item:categoryTypes) => (
            <div className="wrap pb-3 pr-2" key={item.id}>
              <label
                className="inline-flex items-center"
                htmlFor={item.name}
              >
                <input
                  id={item.name}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                    hanldeSelectStyleId(item.id);
                  }}
                  className="form-radio text-black focus:ring-black w-6 h-6 border-2  border-black bg-sand"
                  name="radio-colors"
                  checked={dataStyleFilter.includes(item.id)}
                  value={item.name}
                />
                <span className="ml-4 text-base text-left whitespace-nowrap">{item.name}</span>
              </label>
            </div>
          ))}
        </div>
        {/* Manufacturer List */}
        <div>
          <h2 className="font-serif font-semibold mt-10 mb-4 text-xl">
            Manufacturer
          </h2>
          <div className="max-h-472px overflow-scroll border border-black p-4 scroll">
            {manufacturerList && manufacturerList.map((item:any) => (
              <div className="wrap pb-3 pr-2" key={item.id}>
                <label className="inline-flex text-left" htmlFor={item.id}>
                  <input
                    id={item.id}
                    type="checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectManufactureId(item.id);
                    }}
                    className="form-radio text-black focus:ring-black w-6 h-6 border-2  border-black bg-sand"
                    name="radio-colors"
                    value={item.business_name}
                    checked={dataManufactureFilter.includes(item.id)}
                  />
                  <span className="ml-4 text-base truncate max-w-150px text-left whitespace-nowrap">{item.business_name}</span>
                </label>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default FilterBox;
