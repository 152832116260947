const Steps = () => {
  return (
    <nav aria-label="Progress ">
      <ol role="list" className="overflow-hidden font-Inter">
        <li className="relative pb-20 pt-2 text-xl">
          <div className="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-black" aria-hidden="true" />
          {/* Complete Step */ }
          <a className="relative flex items-start group">
            <span className="h-9 flex items-center">
              <span className="relative z-10 w-10 h-10 flex items-center text-white justify-center bg-orangeShade rounded-full">
                <span>1</span>
              </span>
            </span>
            <span className="ml-4 min-w-0 flex flex-col">
              <span className="text-2xl font-semibold tracking-wide ">Sign up</span>
              <span className="font-medium text-base">
                Sign up to get a one-week
                <span className="font-semibold"> free trial </span>

              </span>
            </span>
          </a>
        </li>
        <li className="relative pb-20">
          <div className="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-black" aria-hidden="true" />
          {/* Current Step */ }
          <a className="relative flex items-start group" aria-current="step">
            <span className="h-9 flex items-center" aria-hidden="true">
              <span className="relative z-10 w-10 h-10 flex items-center justify-center bg-white border-2 border-black rounded-full group-hover:border-gray-400">
                <span>2</span>
              </span>
            </span>
            <span className="ml-4 min-w-0 flex flex-col">
              <span className="text-2xl font-semibold tracking-wide text-black">Complete payment info</span>
              <span className="font-medium  ">
                You won’t be charged until a week
                from now
              </span>
            </span>
          </a>
        </li>
        <li className="relative pb-20">
          <div className="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-black" aria-hidden="true" />
          {/* Upcoming Step */ }
          <a className="relative flex items-start group">
            <span className="h-9 flex items-center" aria-hidden="true">
              <span className="relative z-10 w-10 h-10 flex items-center justify-center bg-white border-2 border-black rounded-full group-hover:border-gray-400">
                <span>3</span>
              </span>
            </span>
            <span className="ml-4 min-w-0 flex flex-col">
              <span className="text-2xl font-semibold tracking-wide text-black">Onboarding call</span>
              <span className="font-medium ">Schedule a call with us to get started! </span>
            </span>
          </a>
        </li>
        <li className="relative pb-20">
          {/* Upcoming Step */ }
          <a className="relative flex items-start group">
            <span className="h-9 flex items-center" aria-hidden="true">
              <span className="relative z-10 w-10 h-10 flex items-center justify-center bg-white border-2 border-black rounded-full group-hover:border-gray-400">
                <span>4</span>
              </span>
            </span>
            <span className="ml-4 min-w-0 flex flex-col">
              <span className="text-2xl font-semibold tracking-wide text-black">Start production!</span>
              <span className="font-medium  ">
                You will be able to start uploading styles,
                request fabric and trim searches,
                and message us directly on the dashboard!
              </span>
            </span>
          </a>
        </li>
      </ol>
    </nav>
  );
};

export default Steps;
