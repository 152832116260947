import React from 'react';
import Breadcrumbs from '../Components/BreadCrumbs';
import ImageCarousel from '../Components/ImageCarousel';
import { ManufacturerData, ManufacturerPages } from '../Constants';

// type manufacturerInput = {};

const ManufacturerDetail: React.FC = () => (
  <div>
    <Breadcrumbs pages={ManufacturerPages} />
    <ImageCarousel images={ManufacturerData.images} />
  </div>
);

export default ManufacturerDetail;
