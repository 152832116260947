import React from 'react';

type TabType = {
  currentTab: number;
  // activeTab: number;
  onTabClick: (a: number) => void;
  style?: string;
  comeFromOnboarding?: boolean;
}

const Tab: React.FC<TabType> = (props) => {
  const {
    currentTab, style, onTabClick, comeFromOnboarding,
  } = props;
  return (
    <li
      className={`${style} mr-4 ${comeFromOnboarding ? '' : 'pb-2'}`}
      onClick={() => onTabClick(currentTab)}
    >
      {props.children}
    </li>
  );
};

export default Tab;
