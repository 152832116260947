// Reducer for adding and removing the products in cart
export const cartReducer = (state: any[], action: any) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      return [...state, action.payload];
    case 'REMOVE_FROM_CART':
      return [state.filter(({ id }) => id !== action.payload.id)];
    default:
      return state;
  }
};
