// Add redirect routes here
import Abby from 'assets/images/team/Abby.jpg';
import Dylan from 'assets/images/team/Dylan.jpg';
import Fanhao from 'assets/images/team/Fanhao.jpg';
import Isis from 'assets/images/team/Isis.jpg';
import Julie from 'assets/images/team/Julie.jpg';
import Serena from 'assets/images/team/Serena.jpg';
import Tatiana from 'assets/images/team/Tatiana.jpg';
import Ziqi from 'assets/images/team/Ziqi.jpg';
import Aritzia_Logo from 'assets/icons/Aritzia_Logo.svg';
import company_logo from 'assets/icons/company_logo.svg';
import nike_logo from 'assets/icons/nike_logo.svg';
import Reebok from 'assets/icons/Reebok.svg';

export const APP_REDIRECT_ROUTES = ['/cart'];

export function scheduleCallUrl(year: string, month: string) {
  return window.open(`https://calendly.com/ziqi/re-source-onboarding?back=1&month=${year}-${month}`);
}

export function talkToSales() {
  return window.open('https://calendly.com/ziqi/30min?back=1');
}

export const aboutUs = {
  hero_paragraph: `RE(SOURCE) is a wholesale fashion marketplace that works to honor, support, and connect small and medium fashion brand owners and entrepreneurs. As two members of families’ who have started their own businesses, Ziqi and Tatiana know that no matter the accessibility and connections, it can be difficult to embark on not only building a business but a community.
  <br/>
  <br/>
  RE(SOURCE) is a fashion startup composed of community members dedicated and excited to help you during your journey, whether you are a newcomer to the fashion industry, an entrepreneur of years, or someone simply looking to make a custom t-shirt; our job is to make your dream come to life with every aspect of creativity, time, and belief you put into it.
  <br/>
  <br/>
  Our RE(SOURCE) team guarantees quality from manufacturers by manually checking our products, photographing the quality, and communicating with our clients to attain their approval prior to shipment. This process allows for complete transparency and collaboration with our clients. In addition, we ensure you low or no minimum order quantity which allows you to take no risk when testing out inventory or new materials. Our main purpose is to give small and medium fashion start-ups and entrepreneurs the autonomy and accessibility to choose how they want to create their ideal brand. From our reverse image search function to easy private labeling, we are here to ensure that you have the most qualitative creative process for an affordable price.
  `,
  hero_paragraph_production: `
  <br/>
  RE(SOURCE) provides the first-of-its-kind apparel-supply-chain-as-a-service to honor, support, and connect emergent fashion founders. As daughters of manufacturer families and previous founders of a fashion brand, the co-founders Tatiana and Ziqi understand the challenges of traditional manufacturers and production pain points of today’s fashion founders.
  <br/>
  <br/>
  RE(SOURCE) is a fashion startup with teams dedicated and excited to help you during your journey. No matter you are a newcomer to the fashion industry, a designer dreaming to start a brand for years, or a sizable high-end brand looking to move supply chain overseas to scale up, our job is to make your dream come to life with every creativity, time, and belief you put into it.
  <br/>
  <br/>
  Our RE(SOURCE) team guarantees quality and priority from manufacturers by handpicking top manufacturers that have aligned interests like we do in investing in you. We also conduct due diligence on manufacturers and bring transparency in the production world. The fashion industry is moving to a test-based, small-inventory, fast-time-to-market model and we are creating the infrastructure that enables that.
  <br/>
  <br/>
  Let’s talk.
  `,
  our_team: {
    heading: 'Our Team',
    team: [
      {
        image: Tatiana,
        name: 'Tatiana Tian',
        position: 'Co-founder & CEO',
      },
      {
        image: Ziqi,
        name: 'Ziqi Deng',
        position: 'Co-founder & COO',
      },
      {
        image: Fanhao,
        name: 'Fanhao Yang',
        position: 'Tech Lead',
      },
      {
        image: Dylan,
        name: 'Dylan Chen',
        position: 'Supply Chain Head',
      },
      {
        image: Serena,
        name: 'Serena Tan',
        position: 'Operation Head',
      },
      {
        image: Julie,
        name: 'Julie Chan',
        position: 'UX / UI Designer',
      },
      {
        image: Abby,
        name: 'Abby DeRoche',
        position: 'Marketing Specialist',
      },
      {
        image: Isis,
        name: 'Isis Gaddy',
        position: 'Content Specialist',
      },
    ],
  },
};

export const faqs = [
  {
    id: 0,
    heading: 'Sampling',
    info: [
      {
        question: 'Does the price include international shipping?',
        answer:
          'The price does not include international shipping; the shipping fee will be calculated at the check out. The general rule of thumb is that the bigger the overall order size, the cheaper the unit shipping fee. So we encourage you to order your items altogether to save some money on the unit shipping costs.',
      },
      {
        question: 'Can I return the samples? Is it free?',
        answer: `Returning samples has never been easier. You can return samples at no cost if the products haven't been shipped out of the country of manufacturing. We provide close-up pictures and videos of the products and ask you for confirmation before shipping out.

      After receiving the products, you can return up to 3 products in each order. However, we charge international shipping fees when you return domestically (~$5/item)`,
      },
      {
        question: 'What’s the process of sampling with RE(SOURCE)?',
        answer:
          'Once you place orders on our site, manufacturers will go forward and ship the products to our local office for a quality check  which includes picture/video testimony upon request. You will be able to submit a request to see the quality of the items you ordered once they have arrived at our local office. If you are not satisfied with your order after checking the picture/video testimony, you can return the order at no extra charge. Once you have approved the quality of the items, we will ship them out internationally. Please note that if you do not approve your items after 24 hours, we will assume that the quality meets your standards, and will proceed to ship your items out.',
      },
    ],
  },
  {
    id: 1,
    heading: 'Bulk Ordering',
    info: [
      {
        question: 'Do you conduct quality checks for bulk orders?',
        answer:
          'YES! We have local offices that help conduct quality checks of the order items before they are shipped internationally. With this local accessibility, we are able to ensure the best quality for bulk orders and answer any product questions you may have before shipping products to you.',
      },
      {
        question: 'Is there a MOQ (Minimum order quantity) for placing an order?',
        answer: 'We have NO minimum order quantity requirement.',
      },
      {
        question: 'What’s the MOQ (Minimum order quantity) for customization?',
        answer: `For screen printing / heat transfer we have No minimum order quantity - you can customize items starting from one piece.
        <br/>
        <br/>
        Embroidery comes with an initial mold making fee that varies based on the type and size of embroidery. For more information, please contact <a style=color:blue href='mailto:ziqi@resourcefashion.co'>ziqi@resourcefashion.co</a> with size estimates of your design and a prototype to get an estimation.
        <br/>
        <br/>
      Hang tags (500), clothings labels (10), and packaging materials (100) come with a MOQ that you can verify by reaching out to  <a style=color:blue href='mailto:ziqi@resourcefashion.co'>ziqi@resourcefashion.co</a>`,
      },
      {
        question:
          'Can I customize the product by adding logos, hang tags, and additional materials?',
        answer:
          "Yes! You will be able to get logo customization with no minimum order quantity guaranteed! Logo prints and embroidery is a specialty of ours and it would be our pleasure to RE(SOURCE) with you! If you have any further questions please do not hesitate to contact us at <a style=color:blue href='mailto:ziqi@resourcefashion.co'>ziqi@resourcefashion.co</a>, and we will get back to you in a timely manner.",
      },
      {
        question: ' How long does it take for a bulk order to be processed?',
        answer: `Depending on available stock of the ordered items, as well as the shipping method chosen at checkout processing time can take between 10 and 45 days. Most of our customers choose air shipping which typically takes 2-3 weeks door to door.
      What shipping method do you provide for international shipping?
      RE(SOURCE) provides DHL, FedEx, UPS, and USPS for air shipping, while we also provide sea freight boats for larger orders over 220 lbs.`,
      },
    ],
  },
  {
    id: 2,
    heading: 'Payment and Financing',
    info: [
      {
        question: 'What payment methods do you accept?',
        answer:
          'RE(SOURCE) accepts Apple Pay, PayPal, All credit and Debit cards, as well as ACH Bank transfers!',
      },
      {
        question: 'Will you issue me an invoice for bulk orders?',
        answer:
          'Yes. After your payment is submitted and received, you will receive a commercial invoice for the bulk order.',
      },
      {
        question: 'Is there a monthly payment/financing option available for payment?',
        answer:
          "If your order value is over $500, please contact us at <a style=color:blue href='mailto:ziqi@resourcefashion.co'>ziqi@resourcefashion.co</a> to set up a payment plan that will suit your needs.",
      },
    ],
  },
];

export const faqs_production = [
  {
    id: 0,
    heading: 'Basics',
    info: [
      {
        question: 'Who have you worked with?',
        answer:
          "We take customer confidentiality very seriously, so we can’t disclose the names of any particular companies. However, we can tell you that our clients range from award-winning, socially aware streetwear brands to high-end labels sold in stores like Banana Republic. Our own family business, <a style=color:blue href='https://www.vicgarments.com/'>Vic Garments</a>, has over two decades of experience manufacturing clothing for high-end brands like <a style=color:blue href='https://www.asos.com/'>asos.com</a>, <a style=color:blue href='http://renuar.co.il/'>Renuar</a> and <a style=color:blue href='https://www.twentyfourseven.co.il/'>Twentyfourseven</a>.",
      },
      {
        question: 'Where is your business located?',
        answer:
          'Our company headquarter is in the fashion capital city, New York, with another office in the fashion production hub, Guangdong, China. Our list of manufacturing partners includes dozens of factories in Guangdong, China, which has been the center of the Chinese textile industry since the 1970s. Having a foot on both sides of the ocean allows us to provide our customers with superior local service and expert international manufacturing capabilities.',
      },
      {
        question: 'Why should I produce my garments overseas?',
        answer:
          'On average, garments produced in China are 20 - 80% cheaper compared to the cost in the U.S.. When you consider other factors including factory worker expertise, available local fabrics and the short production time, it’s a no-brainer that most ambitious fashion startups and the fashion giants set up their supply chain in China.',
      },
      {
        question: 'How are you different from a manufacturer?',
        answer:
          'Traditional top clothing manufacturers are hard to reach. Things happen slowly, and when they do, they happen in bulk. In fact, everything about traditional clothing manufacturing is set up for the big brands. We’re built for the small brands. The pioneers. The creative, imaginative people who want to build something new and exciting. We work with you, so you can make your vision a reality.',
      },
    ],
  },
  {
    id: 1,
    heading: 'Sustainability and Ethics',
    info: [
      {
        question: 'What do you do to ensure social compliance?',
        answer:
          'We work with companies from around the world and we are subject to The Business Social Compliance Initiative (BSCI), an industry-driven organization. Many of our customers also require country and industry-specific audits. This usually depends on where they are located, but we are always happy to accommodate these requests case by case. When you work with manufacturers in our network, you will be able to access a member-only dashboard with manufacturers’ information from employee statistics to  company history.',
      },
      {
        question: 'What do you do to contribute to a more sustainable future?',
        answer:
          'Our company takes a proactive approach to environmental compliance. Our commitment to low minimum order quantities means brands don’t need to overstock, reducing the carbon footprint for each style. We also work closely with our factories on water wastage, energy consumption, packaging materials, and other resources. Finally, we ensure that we have a large variety of sustainable fabrics available for our clients to choose from for their designs.',
      },
      {
        question: 'what can I do to be more sustainable if I work with you?',
        answer: `
      We work with our clients every step of the way.
      <br/>
      <br/>
      First, we don’t require brands to order large quantities, and by doing so, we allow brands to test the market carefully. This process reduces overstock and wastage. You produce when you are confident about selling.
      <br/>
      <br/>
      We also offer plant-based and recycled packaging options and focus our attention on sea freight rather than air where possible – thereby emitting 1/25 of the greenhouse gasses during shipping.
      <br/>
      <br/>
      We take pride in making bold green choices, so you can be proud of the sustainable, eco-friendly product you put out into the world!
      `,
      },
    ],
  },
  {
    id: 2,
    heading: 'Agents vs RE(SOURCE)',
    info: [
      {
        question:
          'How are you different from agents from the perspective of access to manufacturers?',
        answer:
          'When you work with agents, your access to manufacturers depend on you bulk order quantity. Agents and top manufacturers prioritize big brands. The factories in our network are not only carefully vetted, but we also have long-standing relationships with them, just like we do with our customers. We see these relationships as partnerships more than anything else, and we’re rooting for your success together. RE(SOURCE) does the heavy lifting to support and incentivize our network of manufacturers and fabric millers, so that we can give you access to the best partners and services, which often, only the most prominent brands do.',
      },
      {
        question: 'How are you different from agents from the perspective of my confidence?',
        answer:
          'Apparel production can be a hit or miss if you do not know who you work with. Agents directly pass on your order to manufacturers and provide no transparency on your production partners. Our in-house team works closely with brands to source fabrics and develop samples so we can strictly control the quality from the sample to bulk orders. We make everything predictable, from the consistent quality to the short lead-time. We are so confident that we back our promises with a money-back guarantee.',
      },
      {
        question: 'How are you different from agents from the perspective of my time commitment?',
        answer:
          'Apparel production is a traditional industry powered by chat groups, emails and phone calls. Each player operates with its own preferred way of working, resulting in industry-wide inefficiency. We build our proprietary SaaS software to streamline the production process, universally store files, and help brands track the production process just a click away. In this way, you can only focus on brand design, product marketing and building your dream while we take care of logistics. Your continued success and growth means our success and growth - we have the aligned interest to ensure you have everything you need when you need it. ',
        answerb:
          "If your order value is over $500, please contact us at <a style=color:blue href='mailto:ziqi@resourcefashion.co'>ziqi@resourcefashion.co</a> to set up a payment plan that will suit your needs.",
      },
      {
        question: 'How do you think of yourself as being different from an agent?',
        answer: `
      There will always be fashion giants and design houses that were forged decades ago, who have legions of devoted fans. We don’t copy those fashion houses or their production chains, because we’re laser focused on helping emerging fashion brands to break into the market. Whether it’s help sourcing fabric or accommodating special requests, our team goes out of their way to lay the foundation for the next generation of giants.
      <br/>
      <br/>
      Every big thing started small. Our unique service, small batch order fulfillment and one of a kind affordable subscription model are the future.
      `,
      },
    ],
  },
];

export const SourceByCategory = [
  {
    id: 1,
    linkName: 'Tops',
    linkURL: '/home',
    additionalClasses: '',
  },
  {
    id: 2,
    linkName: 'Bottoms',
    linkURL: '/home',
    additionalClasses: '',
  },
  {
    id: 3,
    linkName: 'Dresses',
    linkURL: '/home',
    additionalClasses: '',
  },
  {
    id: 4,
    linkName: 'Accessories',
    linkURL: '/home',
    additionalClasses: '',
  },
];
export const TrendingSearches = [
  {
    id: 1,
    linkName: 'Matching set',
    linkURL: '/products',
    additionalClasses: '',
  },
  {
    id: 2,
    linkName: 'Vegan Leather',
    linkURL: '/products',
    additionalClasses: '',
  },
  {
    id: 3,
    linkName: 'Athleticwear',
    linkURL: '/products',
    additionalClasses: '',
  },
  {
    id: 4,
    linkName: 'Oversized Blazer',
    linkURL: '/products',
    additionalClasses: '',
  },
];

export const SourceByStyle = [
  {
    id: 1,
    linkName: 'Athleisure',
    linkURL: '/home',
    additionalClasses: '',
  },
  {
    id: 2,
    linkName: 'Officewear',
    linkURL: '/home',
    additionalClasses: '',
  },
  {
    id: 3,
    linkName: 'Casualwear',
    linkURL: '/home',
    additionalClasses: '',
  },
  {
    id: 4,
    linkName: 'Wedding Guest',
    linkURL: '/home',
    additionalClasses: '',
  },
  {
    id: 5,
    linkName: 'New',
    linkURL: '/home',
    additionalClasses: '',
  },
];

export const MyAccount = [
  // {
  //   id: 1,
  //   linkName: 'Wishlist',
  //   linkURL: '/wishlist',
  //   additionalClasses: 'font-serif text-2xl mb-5',
  // },
  {
    id: 2,
    linkName: 'My Requests',
    linkURL: '/requestlist',
    additionalClasses: 'font-serif text-2xl mb-5',
  },
  {
    id: 3,
    linkName: 'Order History',
    linkURL: '/order-history',
    additionalClasses: 'font-serif text-2xl mb-5',
  },
  {
    id: 4,
    linkName: 'Settings',
    linkURL: '/settings',
    additionalClasses: 'font-serif text-2xl mb-5',
  },
  {
    id: 5,
    linkName: 'Pilot Program',
    linkURL: '/pilotProgram',
    additionalClasses: 'font-serif text-2xl',
  },
];

export const Company = [
  {
    id: 1,
    linkName: 'How It Works',
    linkURL: '/how-it-work',
    additionalClasses: 'font-serif text-2xl mb-5',
  },
  {
    id: 2,
    linkName: 'About Us',
    linkURL: '/about-us',
    additionalClasses: 'font-serif text-2xl mb-5',
  },
  // {
  //   id: 3,
  //   linkName: 'Manufactures',
  //   linkURL: '/manufacturerList',
  //   additionalClasses: 'font-serif text-2xl mb-5',
  // },
  {
    id: 4,
    linkName: 'FAQ',
    linkURL: '/faq',
    additionalClasses: 'font-serif text-2xl',
  },
];
export const ManufacturerData: Manufacturer = {
  images: [
    { id: 1, image: 'https://picsum.photos/390/300', color: 0 },
    { id: 2, image: 'https://picsum.photos/seed/picsum/390/300', color: 1 },
    { id: 3, image: 'https://picsum.photos/seed/picsum/390/300', color: 2 },
  ],
  productName: 'Rockwell',
  location: 'Guangzhou, China',
  productPrice: '$$ - $$$',
  employees: 23,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
};

export const ManufacturerPages: page[] = [
  { name: 'Manufacturers', href: '/product-list', current: false },
  { name: 'Manufacturer A', href: '/manufacturer/:23', current: true },
];

export const ProductPages: page[] = [
  { name: 'Products', href: '/product-list', current: false },
  { name: 'Product A', href: '/product/:23', current: true },
];

export const SortData: filterValues[] = [
  {
    id: 1,
    name: 'Recommended',
    value: '',
    selected: false,
  },
  {
    id: 2,
    name: 'Price:Low to High',
    value: 'ascending',
    selected: false,
  },
  {
    id: 3,
    name: 'Price:High to Low',
    value: 'descending',
    selected: false,
  },
];

export const top: sizeChartType = {
  size: ['XS', 'S', 'M', 'L', 'XL'],
  length1: ['4', '34.5', '35', '35.5', '36'],
  bust: ['78', '82', '86', '90', '94'],
  waist: ['64', '68', '72', '76', '80'],
};

export const bottoms: sizeChartType = {
  size: ['XS', 'S', 'M', 'L', 'XL'],
  length1: ['34', '34.5', '35', '35.5', '36'],
  bust: ['78', '82', '86', '90', '94'],
  waist: ['64', '68', '72', '76', '80'],
};

export const dresses: sizeChartType = {
  size: ['XS', 'S', 'M', 'L', 'XL'],
  length1: ['134', '34.5', '35', '35.5', '36'],
  bust: ['78', '82', '86', '90', '94'],
  waist: ['64', '68', '72', '76', '80'],
};
export const OrderHistoryData: orderData[] = [
  {
    id: 0,
    date: 'Aug21,2021',
    price: '$1,530.60',
    status: '',
  },
  {
    id: 1,
    date: 'Aug21,2021',
    price: '$1,530.60',
    status: '',
  },
];

export const trendSetter = [
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B1%5D.jpg',
    text: 'striped oversized forest green sweater',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B2%5D.jpg',
    text: 'cropped zip-up brown sweater hoodie',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B3%5D.jpg',
    text: 'cropped knit crochet pattern sweater',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B4%5D.jpg',
    text: 'button-up blouse dress academia business-casual',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B7%5D.jpg',
    text: 'vintage aesthetic marble long-skirt 70s',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B8%5D.jpg',
    text: 'pattern denim brown high-waisted flare-jeans',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B11%5D.jpg',
    text: 'zip-up oversized forest-green hoodie y2k',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B14%5D.jpg',
    text: 'vintage colorful hemmed oversize sweater',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B15%5D.jpg',
    text: 'lilac lace 70s vintage mini-dress',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B16%5D.jpg',
    text: 'artistic corset renaissance-design crop-top vintage',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B17%5D.jpg',
    text: 'leather mid-rise pants flare casual',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B18%5D.jpg',
    text: 'collared cream button-up blazer business-casual',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B19%5D.jpg',
    text: 'crochet knit button-up cardigan sweater',
  },
  {
    url: 'https://resourcefashion-web-static.s3.amazonaws.com/media/public/trend_setter/trend_setter_%5B20%5D.jpg',
    text: 'academia brown knit oversized-sweater button-up',
  },
];

export const goodCompany = [
  {
    url: Aritzia_Logo,
  },
  {
    url: nike_logo,
  },
  {
    url: Reebok,
  },
  {
    url: company_logo,
  },
];

// eslint-disable-next-line operator-linebreak
export const manufacturerDefaultImg =
  'https://resourcefashion-web-static.s3.amazonaws.com/media/public/ayo-ogunseinde-0Yz1K0qxmY4-unsplash_1.png';

export const defaultImgArr: imagesData[] = [
  {
    id: 0,
    image:
      'https://resourcefashion-web-static.s3.amazonaws.com/media/public/ayo-ogunseinde-0Yz1K0qxmY4-unsplash_1.png',
    color: 0,
  },
];

export const priceRangeMap: priceRangeMapType = {
  High: '$$$',
  Mid: '$$',
  Low: '$',
};

export const footerSourceByCategory = [
  {
    id: 1,
    linkName: 'Tops',
    linkURL: '/products-list',
    additionalClasses:
      'text-2xl font-semibold text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 2,
    linkName: 'Bottoms',
    linkURL: '/products-list',
    additionalClasses:
      'text-2xl font-semibold text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 3,
    linkName: 'Dresses',
    linkURL: '/products-list',
    additionalClasses:
      'text-2xl font-semibold text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 4,
    linkName: 'Accessories',
    linkURL: '/products-list',
    additionalClasses:
      'text-2xl font-semibold text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
];

export const footerSourceByStyle: links[] = [
  {
    id: 1,
    linkName: 'Wedding Guest',
    linkURL: '/products-list',
    additionalClasses:
      'text-2xl font-semibold text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 2,
    linkName: 'Casual Wear',
    linkURL: '/products-list',
    additionalClasses:
      'text-2xl font-semibold text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 3,
    linkName: 'Office Wear',
    linkURL: '/products-list',
    additionalClasses:
      'text-2xl font-semibold text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
];

export const footerCompany: links[] = [
  {
    id: 2,
    linkName: 'About Us',
    linkURL: '/about-us',
    additionalClasses:
      'text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 3,
    linkName: 'FAQ',
    linkURL: '/faq',
    additionalClasses:
      'text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
];

export const productionFooterUrl: links[] = [
  {
    id: 1,
    linkName: 'Dashboard',
    linkURL: '/production/pilotProgram',
    additionalClasses:
      'text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 2,
    linkName: 'How it Works',
    linkURL: '/production/how-it-works',
    additionalClasses:
      'text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 3,
    linkName: 'About Us',
    linkURL: '/production/about-us',
    additionalClasses:
      'text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
  {
    id: 4,
    linkName: 'FAQ',
    linkURL: '/production/faq',
    additionalClasses:
      'text-2xl font-medium text-gray-900 font-sans tracking-tighter cursor-pointer  hover:opacity-80 pb-4 pt-3',
  },
];

export const requestTabMap: requestTabMapType = {
  0: 'all',
  1: 'pending',
  2: 'complete',
};

export const manageProductionUrl = 'https://studio.airportal.app/login';

export const calendlyLink = 'https://calendly.com/ziqi/30min';

export const typeformLink = 'https://ogjfc9zw5dx.typeform.com/resourcefashion';

export const socialLinks = {
  instagram: 'https://www.instagram.com/resourcefashion/',
  facebook: 'https://www.facebook.com/groups/resourcefashion',
  linkedin: 'https://www.linkedin.com/company/65145336/',
};

export const caseStudy = [

  {
    brandName: 'VAPPI',
    brandLocation: 'Maryland, VA',
    brandDetails: ['VAPPI is a brand-new lingerie company which has yet to launch publicly. The founders lead a digital nomad lifestyle and need the benefit of an online platform where they can manage their samples and production from anywhere, at any time.', 'The brand is dedicated to its mission: to make cotton underwear sexy, which has been an ongoing goal for Karen, who happens to be her business partner’s mother too! When her daughter Lucy first mentioned that she didn’t want to wear the multipack 100% cotton she had always been wearing, Karen realized that while we know that cotton underwear is healthier for women, it’s always been a choice between comfort and health, and looking great. Something needed to change, and Karen and Lucy decided that it was up to them to make it happen.', 'They’ve created exciting and innovative designs that will be made from 100% breathable, soft, and healthy cotton, and it’s critical that they get the right fabrics and finishes.'],
    theChallenges: ['When Karen and Lucy decided that they needed to make their cotton underwear brand a reality, they had the same problem many fashion entrepreneurs face: they’ve never been involved in the fashion world at all.', ' It’s one thing to create a fashion brand when you know where to find fabrics and which manufacturers can offer the products and services you need, but when you’re brand new to the apparel world, the learning curve seems impossible to overcome. ', 'So, like many brand-new fashion brands, VAPPI struggled to find the right partners, and they had a few false starts before they found RE(SOURCE). While there were times that they considered giving up, they are more than committed than ever to building out the brand.'],
    limitation1: ['Minimum Order Quantity', 'Fashion brands tend to face challenges with minimum order requirements from manufacturers because lingerie manufacturers tend to have an even higher minimum requirement compared to other categories of clothing. VAPPI is a new brand and does not have substantial capital for inventory investment.'],
    limitation2: ['Poor Quality', 'The team behind VAPPI had been working with manufacturers in India, but the quality of the samples they received was terrible and did not meet the brand’s requirements. It was this quality problem that prompted VAPPI to look for new manufacturing partners, and that brought them to RE(SOURCE).'],
    limitation3: ['Communication', 'With a team that is often on different continents and manufacturers overseas, it can be quite complex to manage changes, questions, and general communication as the founder of a lingerie brand like VAPPI.  When they worked with the Indian manufacturer, they used WhatsApp, emails and phone calls all the time to communicate designs, which was a disaster.'],
    theSolution1: 'It took the team less than a month to get the reference sample shipped to China from the U.S., get the right fabric and make a sample that hits all the boxes. Whether it’s a change to their lace trim, differentiating between the many types of cotton fabric being used to make samples or the fit and durability of their samples, VAPPI is using the RE(SOURCE) dashboard to iterate every step of the way.',
    theSolution2: 'The flexibility and convenience of RE(SOURCE) are perfect for new brands like VAPPI. There’s no long wait for a response from individual suppliers, as all vendors are using the same system directly working with each other. Karen and Lucy can see their design samples quickly and make any changes they need to ensure it’s exactly what they imagined when they started working on the design process.',
    theSolution3: 'In the meantime, the VAPPI team is free to keep working on their marketing and distribution plans, sharing their story, and getting ready to launch their innovative new sexy cotton underwear line.',
    theSolution4: 'VAPPI’s story is unique, just like any business story is unique. But it’s also one that many new fashion brands share. While you might have great ideas, turning those ideas into real, tangible products can be a daunting task. Without a network of suppliers and manufacturers, many fashion brands never make it from the drawing board to on the shelf in a store. RE(SOURCE) makes it easy, even for people who are not well connected in the fashion world, to create their products, launch a brand, and start getting customer feedback.',
    stat1: {
      value: '7',
      subheading: 'day sample completion ',
    },
    stat2: {
      value: '1',
      subheading: 'month before bulk-production ready',
    },
    stat3: {
      value: '100% ',
      subheading: 'recommend RE(SOURCE) to other brands',
    },
    quote: '“We had thought about quitting before we started working with the RE(SOURCE) team and told ourselves that maybe we were too ambitious and naive about starting a brand. And now we are more confident than ever about the production.” ',
    quoteBy: 'Kareen Bedewi',
    position: 'co-founder of VAPPI',

  },
  {
    brandName: 'Forest Ink',
    brandLocation: 'Frisco, Texas',
    brandDetails: ['Forest Ink is a fashion brand with a difference. They’ve been around since 2009 as a family business. Still, it’s only in the last three years that they started to turn their own designs into clothing that they sell on their website and via a subscription box, and it’s certainly not your ordinary fashion brand!', 'For one thing, they’re committed to producing high-quality and sustainable fashion. For another, they produce quirky, different, one-of-a-kind styles that have a decidedly darker, gothic vibe.', 'But making the transition from printed t-shirts to a collection of sassy, sexy tops, swimwear, loungewear, and more wasn’t that simple, and RE(SOURCE) has helped the brand to make the transition from reseller to one of a kind designer store. Here’s how that happened.'],
    theChallenges: ['When Forest Ink first decided to make the shift from t-shirts to custom apparel, there were several niche retailers that loved their designs and wanted to stock their products.  However, because the designs produced by Forest Ink are so unique and so targeted to a very specific buyer, the order quantities they were looking for were relatively small, which was a problem for the Forest Ink team because most manufacturers required minimum orders of hundreds of units of each design. Long turnaround times further complicated things because Forest Ink couldn’t create samples and take pre-orders.', '', ''],
    limitation1: [
      'Lack of fashion background',
      'The founders of forest-ink don’t come from a fashion background. So, while they have great ideas, it’s very hard to translate that into actual fabric on a hanger in a store. Fabric choice and so many other technical things make it very hard for anyone who doesn’t already know a lot about clothing manufacturing to produce the clothing they want to sell. ',
    ],
    limitation2:
      ['Challenging designs',
        'Because of the unique designs and bold statements that Forest Ink wants to make, Forest Ink has been having challenges finding a supplier that can fulfill their differing needs. Some items, like a line of socks that they’ve been producing for a while, are manufactured by one company, but that company was unwilling to take on their more challenging designs.'],

    limitation3: [
      'Lack of Production Safety Net',
      'As Forest Ink was building its brand, it realized that they were relying very heavily on one or two companies. If those companies were not able to deliver because of capacity issues, they would have a problem filling orders on time. ',
    ],
    theSolution1: 'RE(SOURCE)’s streamlined sampling process, simple dashboard, low minimum, and faster turnaround times checked all the boxes. Forest Ink was now able to produce a lookbook and take pre-orders from retailers. This solved the problem of deciding how much stock to order and helped them keep their investment low while building a following for their brand. ',
    theSolution2: 'The RE(SOURCE) team, along with its 2-decade of family expertise in the industry, was able to fill the knowledge gap that was keeping them from producing their own unique styles, whether it was choosing the right kinds of fabrics to evaluating samples and making changes where needed. ',
    theSolution3: 'Since RE(SOURCE) has multiple different manufacturers for every type of fashion product, there’s a level of confidence in delivering complex designs and fulfilling orders promptly that the brand has never imagined before. ',
    theSolution4: '',
    stat1: {
      value: '4',
      subheading: 'day sample completion ',
    },
    stat2: {
      value: '50',
      subheading: 'production quantity instead of 100',
    },
    stat3: {
      value: '25 ',
      subheading: 'styles currently being developed ',
    },
    quote: '“We have thoroughly enjoyed the process of working with the RE(SOURCE) team. The dashboard is very easy to navigate and helps us keep track of everything. We have also learned a lot about fashion manufacturing from working with the RE(SOURCE) team. I would certainly recommend it to anyone that wants to quickly launch styles and test the market.” ',
    quoteBy: 'Beth Welch',
    position: 'owner of forest ink',

  },
  {
    brandName: 'Intimately',
    brandLocation: '',
    brandDetails: ['Intimately is a female-led brand that specializes in creating unique undergarments for women with disabilities. They strive to connect functionality and fashion through their designs so they can support women all over the globe. Their bras and underwear are built to be inclusive of women with disabilities and have gone through stages of testing to ensure the items are created with their customers best interests in mind.', 'After her mom became chronically ill, Emma eventually founded Intimately because she saw her mom’s struggle to get dressed in the morning due to difficult bra fasteners. There were not any options when it came to accessible yet stylish undergarments, so Emma decided to change that. After working with a few other brands, she decided to launch Intimately with the help of her team of other women. Their goal is not only to design fashionable and functional clothing but to support and amplify the voices of those in the disabled community, which is why their retail presence is focused online.', 'They are currently working to expand their platform by launching an app that will not only assist users in their online shopping experience but create a community where customers can connect with the company and make new friends along the way.'],
    theChallenges: ['Intimately’s designers knew that their products had to incorporate certain aspects for them to be valuable to their customers. They wanted to make an impact on the lives of women with disabilities in a timely manner, because many of them saw the need for these products in their own lives, or the lives of those around them. Not only were they looking for a company to partner with, but they were also looking for people who would understand the reason and the vision behind their products. Because their concept was so new, and they were just getting started, finding a manufacturer who would understand their goals and be able to successfully support their mission along the way was difficult.', '', ''],
    limitation1: [
      'Communication',
      'Broken communication with the manufacturer made it difficult to meet their product launch goals. The inconsistencies with the manufacturing team’s leadership, and unclear deadlines and days off made it difficult to work collaboratively. Communication spread out on multiple different platforms also made responses inconsistent and unclear at times. ',
    ],
    limitation2:
      ['Lead time due to poor process',
        'When working with manufacturers, Intimately experienced difficulties with communicating the unique qualities needed for their product, and why each specific design element was so important. This caused the process to take months longer than intended, spending a lot of time repetitively reworking samples, and struggling to find the correct materials.'],

    limitation3: [
      ' Pricing',
      'Intimately struggled to find fabric and trim that was both comfortable and affordable. The founders knew how important it was to keep their products as low priced as possible to make them accessible, while also delivering high quality.',
    ],
    theSolution1: 'The streamlined communication system on RE(SOURCE)’s completely digital dashboard made it simple for Intimately to communicate their needs to manufacturers through access to RE(SOURCE) team members. RE(SOURCE)’s software made it easy to track product development, make suggestions and changes where they saw necessary, and ensured the efficient and timely production of their design.',
    theSolution2: 'They were able to keep a steady flow of communication and worked with people who understood their needs and supported their mission. This made the process of developing new products run smoothly, without the hassle of communicating across several platforms. Intimately’s team had access to all the information in an organized manner.',
    theSolution3: 'RE(SOURCE)’s manufacturers are based in the fabric capital of China, meaning they have access to multiple different types of fabric options, and are able to find both high quality and affordable fabric. This gives them a competitive edge when it comes to price points, while also ensuring that Intimately was able to find a comfortable fabric to fit their design and functionality goals. RE(SOURCE)’s granular price breakdown is very transparent, allowing customers to understand all costs of their product, and easily identify places where cost reduction changes can be made.',
    theSolution4: '',
    stat1: {
      value: '4',
      subheading: 'day sample completion',
    },
    stat2: {
      value: '3x',
      subheading: 'faster than the previous manufacturer',
    },
    stat3: {
      value: '100%',
      subheading: 'recommend RE(SOURCE) to other brands',
    },
    quote: '“I have worked in the industry for almost 10 years, and this process has just felt more collaborative and organized than anything I’ve experienced before… the process is really innovative.”',
    quoteBy: 'Maddie Highland',
    position: 'Designer, Product Development',

  },

];

export const pricingStyles = [
  {
    styleImage: 'https://resourcefashion.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Ffb2d044f-a70a-4b53-a7d8-0f62b0bcf0f5%2Fstrvnge-films-W_p0lcL6-p0-unsplash.jpg?table=block&id=d7eacd0f-e906-47d0-83ff-42b3854b3996&spaceId=24ac2be1-bb16-4f8d-bfe7-8d8015753cd9&width=2000&userId=&cache=v2',
    styleName: 'Mawuto MVP Hoodie',
  },
  {
    styleImage: 'https://resourcefashion.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F7dadfba9-47fc-4ea0-918a-cc5d2b6256c6%2Fdusan-jovic-B6kh4AxjxnQ-unsplash.jpg?table=block&id=5ed79774-52a9-4458-b1d1-c983422b3de2&spaceId=24ac2be1-bb16-4f8d-bfe7-8d8015753cd9&width=2000&userId=&cache=v2',
    styleName: 'Ruffle Shoulder Smocked Maxi Dress',
  },
  {
    styleImage: 'https://resourcefashion.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F800b9a33-5dc1-4b25-9754-9ed37a23fc8b%2Feye-speak-Ba8JB_A7www-unsplash.jpg?table=block&id=80dee40f-11b0-4910-b3c9-1eebc43a01c9&spaceId=24ac2be1-bb16-4f8d-bfe7-8d8015753cd9&width=2000&userId=&cache=v2',
    styleName: 'Tee',
  },
];


export const selectQuantity: any = [
  {
    name: 'Hoodie',
    quantities: [30, 50, 100],
    techniques: [{
      30: {
        cost_breakdown: { Fabric: '$11.02', Print: '$1.36', cut_sew: '$7.63' },
        unit_price: '$20.00',
        total_production: '$600.00'
      },
      50: {
        cost_breakdown: { Fabric: '$11.02', Print: '$1.36', cut_sew: '$4.24' },
        unit_price: '$16.61',
        total_production: '$830.51'
      },
      100: {
        cost_breakdown: { Fabric: '$11.02', Print: '$1.36', cut_sew: '$9.83' },
        unit_price: '$14.92',
        total_production: '$600.00'
      }
    }
    ]
  },
  {
    name: 'Maxi Dress',
    quantities: [100, 200],
    techniques: [{
      100: {
        cost_breakdown: { Fabric: '$4.75', Print: '$2.54', cut_sew: '$9.49' },
        unit_price: '$20.25',
        total_production: '$2025.42'
      },
      200: {
        cost_breakdown: { Fabric: '$3.73', Print: '$1.69', cut_sew: '$8.47' },
        unit_price: '$15.68',
        total_production: '$3135.59'
      },
    }]
  },
  {
    name: 'Tee',
    quantities: [100, 200],
    techniques: [{
      100: {
        cost_breakdown: { Fabric: '$6.44', Print: '$0.64', cut_sew: '$3.39' },
        unit_price: '$10.47',
        total_production: '$1047.46'
      },
      200: {
        cost_breakdown: { Fabric: '$3.39', Print: '$0.64', cut_sew: '$3.39' },
        unit_price: '$10.47',
        total_production: '$2094.92'
      },
    }]
  }
]
