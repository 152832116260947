import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  password: yup.string().required('*Required.'),
  email: yup.string().email('Please enter valid email').required('Required'),
});

export const ResetPasswordSchema = yup.object().shape({
  email: yup.string().email('Please enter valid email').required('Required'),
});

export const ResetSchema = yup.object().shape({
  new_password1: yup.string().required('*Required.'),
  new_password2: yup.string().required('*Required.'),
});
