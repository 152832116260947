import React from "react";

const StepsInMobile = () => {
  return (
    <div>
      <div className="bg-gradient-to-t from-purple-shade to-seaGreen  border-2 rounded-b-lg border-black ">
        <div className="w-full flex items-center justify-center">
          <nav aria-label="Progress">
            <ol role="list" className="flex items-center mt-6 ">
              <li className="relative pr-16 sm:pr-28 md:pr-40 lg:pr-48 xl:pr-64">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-black">
                    <div className={` step-index text-white`}>1</div>
                  </div>
                </div>
                <span
                  className={`relative w-8 h-8 flex items-center justify-center "border-2 border-black bg-orangeShade rounded-full`}
                >
                </span>
              </li>

              <li className="relative pr-16 sm:pr-28 md:pr-40 lg:pr-48 xl:pr-64">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-black">
                    <div className={` step-index `}>2</div>
                  </div>
                </div>
                <span
                  className={`relative w-8 h-8 flex items-center justify-center ${"border-2 border-black bg-white"} rounded-full`}
                >
                </span>
              </li>

              <li className="relative pr-16 sm:pr-28 md:pr-40 lg:pr-48 xl:pr-64">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-black">
                    <div className={` step-index`}>3</div>
                  </div>
                </div>
                <span
                  className={`relative w-8 h-8 flex items-center justify-center ${"border-2 border-black bg-white"} rounded-full`}
                >
                </span>
              </li>

              <li className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-black">
                    <div className={" step-index"}>4</div>
                  </div>
                </div>
                <span
                  className={`relative w-8 h-8 flex items-center justify-center ${"border-2 border-black bg-white"} rounded-full`}
                >
                </span>
              </li>
            </ol>
          </nav>
        </div>

        <div className="m-10percent md:hidden">
          <h1 className="text-xl font-bold sm:text-3xl md:text-3xl">Sign up</h1>
          <h1>Sign up to get a one-week free trial </h1>
        </div>
      </div>
    </div>
  );
};

export default StepsInMobile;
