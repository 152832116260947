/* eslint-disable no-undef */
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getAPIUrl, getAuthHeaders, Loader } from 'API/APIHelpers';
import { apiRoutes } from 'API/APIRoutes';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

const CARD_NUMBER_ELEMENT_OPTIONS = {
  placeholder: 'card number',
  style: {
    base: {
      border: '1px solid red',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
const CARD_EXPIRY_ELEMENT_OPTIONS = {
  placeholder: 'MM/YY',
  style: {
    base: {
      border: '1px solid red',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
const CARD_CVC_ELEMENT_OPTIONS = {
  placeholder: 'CVC',
  style: {
    base: {
      border: '1px solid red',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

// const bad_payment = {
//   head: 'we could not complete your payment',
//   body: 'please check your payment information',
// };

function PayWithCard(props) {
  const elements = useElements();
  const stripe = useStripe();
  // const [state, setState] = useState({
  //   nameOnCard: '',
  //   cardNumber: '',
  //   exp_date: '',
  //   ccv: '',
  // });

  // const [flag, setFlag] = useState(false);
  const [error, setError] = useState('');
  const [loader_flag, setLoaderFlag] = useState(false);
  // This loading flag is used for disabling the pay with card button
  // until the previous request is completed
  const [loading_flag, setLoadingFlag] = useState(false);

  const amount = JSON.parse(localStorage.getItem('totalAmount') || 'null');
  const order_id = JSON.parse(localStorage.getItem('order_id') || 'null');

  const handleOnChange = (e) => {
    setError('');
  };

  const handleSubmit = async (e) => {
    setLoadingFlag(true);
    setError('');
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const card_element = elements.getElement(CardNumberElement);
    // console.log(card_element,"CARD ELEMENT")
    // return the card token
    const result = await stripe.createToken(card_element);
    setLoaderFlag(true);
    if (result.error || result.status >= 400) {
      console.log(result.error.message);
      setError(result.error.message);
      setLoaderFlag(false);
      setLoadingFlag(false);
    } else {
      console.log('result.token', result.token);
      // if (props.location.state.saveCard) {
      //   saveCard(result.token);
      // } else {
      setLoaderFlag(false);
      setLoadingFlag(false);
      handlePayment(result.token);
      // }
    }
  };

  const saveCard = async (token) => {
    const getCardsURL = 'card/';
    const requestOptions = {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({
        token: token.id,
        is_default: true,
      }),
    };
    const res = await fetch(getCardsURL, requestOptions);
    const json = await res.json();
    if (res.status >= 400) {
      setLoaderFlag(false);
      setLoadingFlag(false);
      setError('There was an error adding your card to stripe.');
      console.log('response-backend_error', json);
    } else {
      props.history.goBack();
      setLoaderFlag(false);
      setLoadingFlag(false);
      console.log(json, 'NEW CARD');
    }
  };

  const handlePayment = async (token) => {
    const payload = {
      id: token,
      order: order_id,
      amount,
      token: token && token.id,
      // "use_saved_card":false,
    };
    console.log('payload', payload);
    try {
      setLoaderFlag(true);
      const headers = getAuthHeaders();
      const res = await fetch(getAPIUrl(apiRoutes.CHARGE), {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      });
      const json = await res.json();
      if (res.status >= 400 || (json && json.status != 'success')) {
        setLoaderFlag(false);
        console.log('response_backend_error', json);
        let error = '';
        if (res.status >= 400 && json && json.id) {
          error = `id : ${json.id.toString()}`;
        }
        if (res.status >= 400 && json && json.order) {
          error = `${error}order : ${json.order.toString()}`;
        }
        if (res.status >= 400 && json && json.amount) {
          error = `${error}amount : ${json.amount.toString()}`;
        }
        if (res.status >= 400 && json && json.token) {
          error = `${error}token : ${json.token.toString()}`;
        }
        if (json && json.status == 'failure') {
          error = json.response;
        }
        setError(error);
      } else {
        setLoaderFlag(false);
        // props.CART_EMPITY();
        localStorage.removeItem('amount');
        localStorage.removeItem('totalAmount');
        localStorage.removeItem('order_id');
        localStorage.removeItem('paymentApp');
        localStorage.removeItem('cartItem');
        localStorage.removeItem('promoUserName');
        localStorage.removeItem('promoCode');
        localStorage.removeItem('shippingCharges');
        props.history.push('/thankyou');
        console.log('response_backend_success', json);
      }
    } catch (e) {
      setLoaderFlag(false);
      setError('error is here');
      console.log('response_backend_error', e);
    }
  };

  const onBackPress = () => {
    props.history.goBack();
    // props.history.replace("/search")
  };

  const setRef = (instance) => {
    if (instance) {
      instance._element.on('ready', () => {
        instance._element.focus();
      });
    }
  };

  return (
    <div>
      {loader_flag ? (
        <Loader />
      ) : (
        <div className="container py-10 px-8 lg:px-96 ">
          <form onSubmit={handleSubmit} className="grid grid-cols-12 gap-4">
            {/* <p
              onClick={() => onBackPress()}
              className="p-back common ItemActive"
            >
              Back
            </p> */}
            <h1 className="font-serif font-semibold text-3xl col-span-12">
              Enter your card information
            </h1>

            <div className="col-span-12" controlId="formBasicCardNumber">
              <div className="border-black border-2 mb-3 rounded-full bg-gray-200 py-3 px-5 min-w-150px w-full">
                <CardNumberElement
                  ref={setRef}
                  onChange={handleOnChange}
                  options={CARD_NUMBER_ELEMENT_OPTIONS}
                />
              </div>
            </div>

            <div controlId="formBasicExpDate" className="col-span-6">
              <div className="border-black border-2 mb-3 rounded-full bg-gray-200 py-3 px-5 min-w-150px ">
                <CardExpiryElement
                  options={CARD_EXPIRY_ELEMENT_OPTIONS}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div controlId="formBasicCCV" className="col-span-6">
              <div className="border-black border-2 mb-3 rounded-full bg-gray-200 py-3 px-5 min-w-150px">
                <CardCvcElement
                  options={CARD_CVC_ELEMENT_OPTIONS}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="mx-auto col-span-12">
              <button
                className="bg-lemon px-10 pb-3 pt-4 font-serif border border-black rounded-full  mt-4 "
                type="submit"
                disabled={loading_flag}
              >
                {`PLACE ORDER $${amount / 100}`}
              </button>
              <p className="mt-5 text-center text-red-600">
                {error}
              </p>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default withRouter(PayWithCard);
